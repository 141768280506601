import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
    DeleteDevice,
    DeleteLocation,
    DeleteMediaFolder,
    DeleteMediaResource,
    DeleteSchedule,
    DeleteTemplate,
    deleteCampaign,
} from '../../../api/api';

import React from 'react';
import { useNotification } from '../../../contexts/ContextProvider';

const DeleteDialog = ({
    triggerUpdate,
    openDialog,
    handleCloseDialog,
    setTriggerUpdate,
    text,
    deleteItem,
    expanded,
    switchUser,
    popUpInfo,
    setPopUpInfo,
    handleClose,
}) => {
    //console.log('switchUser:', switchUser);
    const notify = useNotification();
    //console.log('text:', text);
    //console.log('deleteItem', deleteItem);
    const typeOfDeletion = text.toLowerCase();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // Delete campaign
    // const handleDeleteCampaign = async (item) => {
    // 	const data = await deleteCampaign( switchUser, item.campaign_id);
    // 	 //console.log('delete', data);
    // 	if (data.code === 0) setTriggerUpdate(!triggerUpdate);
    // };

    //Handle delete function
    const handleDelete = async () => {
        //console.log('item', deleteItem);
        let data;
        if (typeOfDeletion === 'device') {
            data = await DeleteDevice(deleteItem.device_id);
        }
        if (typeOfDeletion === 'location') {
            data = await DeleteLocation(deleteItem.group_id);
        }
        if (typeOfDeletion === 'media') {
            data = await DeleteMediaResource(switchUser, expanded, deleteItem.resource_id);
        }
        if (typeOfDeletion === 'folder') {
            data = await DeleteMediaFolder(switchUser, deleteItem.folder_id);
        }
        if (typeOfDeletion === 'schedule') {
            data = await DeleteSchedule(switchUser, deleteItem);
        }

        if (typeOfDeletion === 'campaign') {
            data = await deleteCampaign(switchUser, deleteItem.campaign_id);
        }
        if (typeOfDeletion === 'template') {
            data = await DeleteTemplate(switchUser, deleteItem.template_id);
            //console.log('data', data);
            // if (data.code === 0) {
            // 	setTriggerUpdate(!triggerUpdate);
            // }
        }
        //console.log('data', data);

        if (data.code === 0) {
            if (typeOfDeletion === 'schedule') {
                setTriggerUpdate(!triggerUpdate);
                handleClose();
            } else {
                setTriggerUpdate(!triggerUpdate);
            }
            handleCloseDialog();
            if (popUpInfo) {
                setPopUpInfo();
            }

            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message:
                        typeOfDeletion.charAt(0).toUpperCase() +
                        typeOfDeletion.slice(1) +
                        ' was deleted successfully!',
                },
            });
        } else {
            handleCloseDialog();
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Delete unsuccessfully.',
                },
            });
        }
    };

    return (
        <>
            <Dialog open={openDialog} maxWidth="sm" fullWidth={true}>
                <DialogTitle sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Delete this {typeOfDeletion}?
                </DialogTitle>

                <DialogContent>
                    {/* {typeOfDeletion === 'campaign' && <span className="text-sm">{popUpInfo}</span>} */}
                    {deleteItem?.device_name && (
                        <>
                            <span className="text-sm">{deleteItem?.device_name}.</span>
                            <br />
                            <span className="text-sm font-semibold text-red-600">
                                Delete this device will also remove all the subscription data in your account.
                            </span>
                        </>
                    )}
                    {deleteItem?.group_name && <span className="text-sm ">{deleteItem?.group_name}</span>}
                    {deleteItem?.folder_name && <span className="text-sm ">{deleteItem?.folder_name}</span>}
                    {popUpInfo && <span className="text-sm ">{popUpInfo}</span>}
                    {deleteItem?.template_name && (
                        <span className="text-sm ">{deleteItem?.template_name}</span>
                    )}
                </DialogContent>

                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => handleDelete()} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteDialog;
