import { AddMediaFolder, GetMediaFolderList, GetMediaResourceList } from '../../../api/api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { CampaignAddFolder, EffectSection, RightSideMediaList } from './';
import React, { useEffect, useState } from 'react';
import { RiArrowDownSLine, RiFolderAddLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { UploadDialog } from '../utils';
import { addMedia } from '../../../redux/action';
import { styled } from '@mui/material/styles';
import { useNotification } from '../../../contexts/ContextProvider';

const ITEM_HEIGHT = 48;

const ImageListItemBarCenter = styled(ImageListItemBar)(({ theme }) => ({
    ' .MuiImageListItemBar-titleWrap': {
        padding: '0',
    },
    '& .MuiImageListItemBar-title': {
        textAlign: 'center',
        fontSize: '12px',
        textOverflow: 'clip',
        width: '250px',
    },
}));

const ElementBlockStyle = {
    border: `1px dotted #0444AB`,
    borderRadius: '10px',
    maxHeight: '220px',
    minHeight: '220px',
    width: '250px',
    boxShadow: '0 4px 8px 0 rgba(18, 188, 215, 0.2), 0 6px 20px 0 rgba(18, 188, 215, 0.19)',
};

const AddMediaDialog = ({
    openAddMediaDialog,
    setOpenAddMediaDialog,
    currentUser,
    // setAppliedMedia,
    // currentElement,
    setCurrentElement,
    setNewElement,
    effectList,
    action,
}) => {
    //console.log('action:', action);
    const dispatch = useDispatch();
    const media = useSelector((state) => state.handleMedia);
    const state = useSelector((state) => state);
    //console.log('state', state);
    //console.log('RRRRRRReduxRRRRRRReduxRRRRRRReduxRRRRRRReduxRRRRRRReduxRRRRRRRedux\n', media);
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    // Action to ADD/DEL item to/from cart list
    const addItem = (item) => {
        //console.log('addItem', item);
        dispatch(addMedia(item));
    };
    // const updateItem = (item) => {
    // 	 //console.log('updateItem', item);

    // 	dispatch(updateMedia(item));
    // };
    const currentElement = JSON.parse(localStorage.getItem('current_element'));
    //console.log('=========== currentElement ==================', currentElement);

    //Get media folder list
    const [folderList, setFolderList] = useState([]);
    //console.log('folderList:', folderList);
    const getFolderList = async () => {
        const data = await GetMediaFolderList(switchUser, undefined, 50);
        setFolderList(data?.data?.list);
    };

    //Add Media Folder
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const notify = useNotification();

    const [folderName, setFolderName] = useState();
    const [addedFolderId, setAddedFolderId] = useState();
    //console.log('addedFolderId:', addedFolderId);
    const handleInputFolderName = (e) => {
        const { value } = e.target;
        setFolderName(value);
    };
    const AddMediaFolderByName = async () => {
        const data = await AddMediaFolder(folderName);
        //console.log('file: AddMedia.jsx:108 => AddMediaFolderByName => data:', data);
        if (data.code === 0) {
            setFolderName('');
            setAddedFolderId(data.data.folder_id);
            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Folder was added successfully!',
                },
            });
        } else {
            setFolderName('');
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg,
                },
            });
        }
    };

    // Handle folder menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [chosenFolder, setChosenFolder] = useState();
    //console.log('chosenFolder:', chosenFolder);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChooseFolder = (folderId) => {
        //console.log('choose Folder', folderId);
        const selectedFolder = folderList.find((x) => x.folder_id === folderId);
        setChosenFolder(selectedFolder);
        ChosenFolderMedia(folderId);
        handleClose();
    };

    useEffect(() => {
        getFolderList();
    }, []);

    useEffect(() => {
        getFolderList();
    }, [triggerUpdate]);

    // Add Media Resources to folder
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [triggerUpdateMedia, setTriggerUpdateMedia] = useState(false);

    const handleOpenUploadDialog = () => {
        //console.log('open upload dialog');
        setOpenUploadDialog(true);
    };
    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
    };

    useEffect(() => {
        //console.log('useEffect => addedFolderId:', addedFolderId);
        if (!addedFolderId) {
            //console.log('=================== 进入了');
            setChosenFolder(folderList[1]);
            // ChosenFolderMedia(chosenFolder?.folder_id);
        } else {
            handleChooseFolder(addedFolderId);
        }
        ChosenFolderMedia(addedFolderId ? addedFolderId : chosenFolder?.folder_id);
    }, [folderList, triggerUpdateMedia]);

    const [chosenFolderMedia, setChosenFolderMedia] = useState([]);

    const [chosenMedia, setChosenMedia] = useState(
        media.length > 0
            ? (action === 'new' &&
                  media.find((x) => x?.element_id === currentElement.element_id)?.element_id) ||
              (action === 'edit' &&
                  media.find((x) => x?.element_id === currentElement.element_id)?.element_id)
                ? {
                      ...media.find((x) => x.element_id === currentElement.element_id),
                      resource_list: media
                          .find((x) => x.element_id === currentElement.element_id)
                          .resource_list?.map((x, index) => ({ ...x, count: index + 1 })),
                  }
                : {
                      element_id: currentElement.element_id,
                      transition_effect: 1,
                      effect_duration: 2,
                      resource_list: [],
                  }
            : {
                  element_id: currentElement.element_id,
                  transition_effect: 1,
                  effect_duration: 2,
                  resource_list: [],
              },
    );
    //console.log('chosenMedia:', chosenMedia);
    //console.log('media:', media);

    /**
     *
     * Handle Click method add media to array
     *
     */
    const [count, setCount] = useState(0);

    const handleIncrement = () => {
        setCount((count) => count + 1);
    };
    const handleAddMedia = (item) => {
        //console.log('****************************************************************', item);
        handleIncrement();

        setChosenMedia({
            ...chosenMedia,
            resource_list: [
                ...chosenMedia.resource_list,
                {
                    ...item,
                    resource_duration: item.resource_type === 'video' ? item.resource_duration : 5,
                    count: count + chosenMedia.resource_list.length + 1,
                },
            ],
        });
    };

    /**
     *  Set duration of one pic if there are multiple images
     * @param {item} : current image
     * @param {e} : current value
     */

    const handleChangeDuration = (item, e) => {
        //console.log('e', e);
        item['resource_duration'] = parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 5;
        //console.log('time', item);
        const tempArray = chosenMedia;
        //console.log('handleChangeDuration => tempArray:', tempArray);
        tempArray.resource_list.map((x) => (x.resource_id === item.resource_id ? { ...item } : x));
        //console.log('tempArray', tempArray);

        setChosenMedia({ ...tempArray });
    };

    /**
     * Function to changge transition effect with dropdown box
     * @param {event}: current event
     *
     */
    const handleChangeEffect = (event) => {
        //console.log(event);
        const tempArray = chosenMedia;

        tempArray.transition_effect = parseInt(event.target.value);
        //console.log('tempArray', tempArray);

        setChosenMedia({ ...tempArray });
    };

    /**
     *
     * Function for effect duration
     * @param {event}: current event
     */

    const handleChangeEffectDuration = (event) => {
        //console.log(event);
        const tempArray = chosenMedia;

        tempArray.effect_duration = parseInt(event.target.value);
        //console.log('tempArray', tempArray);

        setChosenMedia({ ...tempArray });
    };

    /**
     *
     * Delete selected media
     *
     */
    const handleDelete = (e, item) => {
        e.stopPropagation();
        //console.log('item', item);
        const tempArray = chosenMedia;
        //console.log('handleDelete => tempArray:', tempArray);
        const filteredArray = tempArray.resource_list.filter((x, i) => x.count !== item.count);
        // setSelectedItem(filteredArray);
        //console.log('handleDelete', { ...chosenMedia, resource_list: filteredArray });
        setChosenMedia({ ...chosenMedia, resource_list: filteredArray });
    };

    /**
     * Select floder and show list of all resources
     * @param {*} folder_id
     */

    const ChosenFolderMedia = async (folder_id) => {
        const data = await GetMediaResourceList(switchUser, folder_id, undefined);
        //console.log('data', data);
        setChosenFolderMedia(data?.data?.list ? data?.data?.list : []);
    };

    /**
     * close dialog with reset state function
     */
    const handleCloseChooseMediaDialog = () => {
        setOpenAddMediaDialog(false);
        setChosenMedia([]);
    };

    const HandleApply = () => {
        addItem(chosenMedia);
        handleCloseChooseMediaDialog();
    };

    const filteredMedia =
        chosenFolderMedia.length > 0 &&
        chosenFolderMedia?.filter(
            (x) =>
                currentElement?.element_type === 'mix' || x?.resource_type === currentElement?.element_type,
        );

    //console.log('filteredMedia', filteredMedia);

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={openAddMediaDialog}
                onClose={handleCloseChooseMediaDialog}
            >
                <DialogTitle>Saved Media</DialogTitle>
                <DialogContent className="flex flex-col p-4 h-full">
                    <Grid container spacing={2}>
                        <div className="w-full h-full grid grid-cols-4 gap-4 p-2 pb-0 mb-4">
                            <div className="col-span-1">
                                <Button
                                    className="h-8"
                                    variant="contained"
                                    fullWidth
                                    aria-label="more"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <div className="flex justify-between w-full">
                                        <div>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    // maxWidth: '120px',
                                                    fontSize: '14px',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    padding: '10px',
                                                }}
                                            >
                                                {
                                                    // addedFolderId
                                                    // ? folderList.find((folder) => folder.folder_id === addedFolderId)
                                                    //       ?.folder_name
                                                    // :
                                                    chosenFolder?.folder_name
                                                }
                                            </Typography>
                                        </div>
                                        <div className="mt-2.5 ">
                                            <RiArrowDownSLine size={18} />
                                        </div>
                                    </div>
                                </Button>

                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            // width: '20ch',
                                        },
                                    }}
                                >
                                    {folderList?.map((option) => (
                                        <MenuItem
                                            key={option.folder_name}
                                            onClick={() => handleChooseFolder(option.folder_id)}
                                        >
                                            <Typography
                                                variant="body1"
                                                style={{ fontSize: '16px', width: '100%' }}
                                            >
                                                {option.folder_name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            <div className="col-span-3">
                                <CampaignAddFolder
                                    folderName={folderName}
                                    // setTriggerUpdate={setTriggerUpdate}
                                    handleInputFolderName={handleInputFolderName}
                                    AddMediaFolderByName={AddMediaFolderByName}
                                />
                            </div>
                        </div>
                        <Grid item xs={8} md={8}>
                            <div className="h-[32rem] w-[810px] mb-8 overflow-x-auto">
                                <ImageList
                                    className="w-full border border-slate-50 rounded"
                                    gap={8}
                                    cols={3}
                                    sx={{
                                        padding: '8px',
                                        minHeight: '100%',
                                        minWidth: '800px',
                                    }}
                                >
                                    {currentUser?.user_level === 0 && (
                                        <div
                                            className="cursor-pointer hover:bg-gray-200 flex flex-col align-middle justify-center w-full h-full"
                                            style={ElementBlockStyle}
                                            onClick={handleOpenUploadDialog}
                                        >
                                            <div className="flex justify-center">
                                                <RiFolderAddLine size={70} />
                                            </div>
                                            <div className="flex justify-center">
                                                <p>Add File</p>
                                            </div>
                                        </div>
                                    )}
                                    {filteredMedia?.length > 0 &&
                                        filteredMedia?.map((item, index) => (
                                            <ImageListItem
                                                key={`${item.resource_url}${item.count}`}
                                                className="cursor-pointer hover:bg-gray-200 "
                                                sx={ElementBlockStyle}
                                                onClick={() => handleAddMedia(item)}
                                            >
                                                <div className="flex h-full w-full justify-center items-center">
                                                    {item.resource_type === 'image' ? (
                                                        <img
                                                            src={item.resource_url}
                                                            loading="lazy"
                                                            className="max-h-full max-w-full items-center"
                                                            style={{
                                                                height: '120px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <video
                                                            loading="lazy"
                                                            className="max-h-full max-w-full items-center"
                                                            style={{
                                                                height: '120px',
                                                            }}
                                                            controls
                                                            src={item.resource_url}
                                                        />
                                                    )}
                                                </div>
                                                <ImageListItemBarCenter
                                                    title={item.resource_name}
                                                    position="below"
                                                />
                                            </ImageListItem>
                                        ))}
                                    {openUploadDialog && (
                                        <UploadDialog
                                            currentUser={currentUser}
                                            chosenFolder={chosenFolder}
                                            openUploadDialog={openUploadDialog}
                                            setOpenUploadDialog={setOpenUploadDialog}
                                            handleCloseUploadDialog={handleCloseUploadDialog}
                                            setTriggerUpdate={setTriggerUpdate}
                                            setTriggerUpdateMedia={setTriggerUpdateMedia}
                                            triggerUpdateMedia={triggerUpdateMedia}
                                        />
                                    )}
                                </ImageList>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'white',
                            }}
                        >
                            {currentElement.element_id === chosenMedia.element_id && (
                                <RightSideMediaList
                                    chosenMedia={chosenMedia}
                                    setChosenMedia={setChosenMedia}
                                    handleDelete={handleDelete}
                                    handleChangeDuration={handleChangeDuration}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {currentElement.element_id === chosenMedia.element_id &&
                            currentElement?.element_type === 'image' &&
                            chosenMedia?.resource_list?.length >= 2 && (
                                <EffectSection
                                    handleChangeEffect={handleChangeEffect}
                                    chosenMedia={chosenMedia}
                                    effectList={effectList}
                                    handleChangeEffectDuration={handleChangeEffectDuration}
                                />
                            )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ color: '#0444AB' }}
                        onClick={handleCloseChooseMediaDialog}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={HandleApply}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddMediaDialog;
