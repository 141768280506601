import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    IconButton,
    LinearProgress,
    Slider,
    Typography,
} from '@mui/material';
import { GenerateVideo, GetVideoResult } from './Api'; // Assuming you have these API functions
import React, { useState } from 'react';

import AWS from 'aws-sdk';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import config from '../../../config/config';
import { useDropzone } from 'react-dropzone';

const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp'];
const allowedResolutions = [
    { width: 1024, height: 576 },
    { width: 576, height: 1024 },
    { width: 768, height: 768 },
];

//const user_id = 17;
const user_id = JSON.parse(localStorage.getItem('userDetails'))?.user_id;

const ImageToVideo = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [s3URL, setS3URL] = useState('');
    const [cfgScale, setCfgScale] = useState(1.8);
    const [motionBucketId, setMotionBucketId] = useState(127);
    const [processing, setProcessing] = useState(false);
    const [resultVideoURL, setResultVideoURL] = useState('');
    const [error, setError] = useState('');
    const [generationId, setGenerationId] = useState('');
    const [imageResolution, setImageResolution] = useState(null);
    const [imageSize, setImageSize] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                const isValidResolution = allowedResolutions.some(
                    (res) => res.width === img.width && res.height === img.height,
                );
                if (!isValidResolution) {
                    setError(
                        'Invalid image resolution. Allowed resolutions are 1024x576, 576x1024, 768x768.',
                    );
                    setSelectedImage(null);
                    return;
                }
                setImageResolution({ width: img.width, height: img.height });
                setImageSize((file.size / 1024 / 1024).toFixed(2)); // Size in MB
                setError('');
                handleFileUpload(file);
            };
            img.src = URL.createObjectURL(file);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: allowedFileTypes.join(','),
        maxFiles: 1,
    });

    const handleFileUpload = async (file) => {
        setUploading(true);
        setUploadProgress(0);
        try {
            const s3url = await uploadToS3(file);
            setS3URL(s3url);
            setSelectedImage(URL.createObjectURL(file));
        } catch (err) {
            console.error('Error uploading file to S3', err);
            setError('Error uploading file. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    const uploadToS3 = async (file) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;
        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;
        const ACCESS_KEY = config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId;
        const SECRET_KEY = config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey;

        AWS.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_KEY,
            region: REGION,
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: S3_BUCKET,
            //Key: `${Date.now()}_${file.name}`,
            Key: user_id + '/image_to_video/' + file.name,
            Body: file,
            ContentType: file.type,
        };

        return new Promise((resolve, reject) => {
            s3.upload(params)
                .on('httpUploadProgress', (evt) => {
                    setUploadProgress(Math.round((evt.loaded * 100) / evt.total));
                })
                .send((err, data) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(data.Location);
                });
        });
    };

    const handleClear = () => {
        setSelectedImage(null);
        setS3URL('');
        setImageResolution(null);
        setImageSize(null);
        setError('');
    };

    const handleGenerateVideo = async () => {
        if (!s3URL) {
            setError('No image uploaded.');
            return;
        }

        setProcessing(true);
        setError('');
        try {
            const body = {
                user_id: user_id,
                s3url: s3URL,
                cfg_scale: cfgScale,
                motion_bucket_id: motionBucketId,
            };

            //console.log('body', body);

            const data = await GenerateVideo(body);
            //console.log('data', data);

            const generationId = data.message;
            setGenerationId(generationId);

            // Polling for result
            const result = await pollForResult(generationId);
            setResultVideoURL(result.message);
        } catch (err) {
            console.error('Error generating video', err);
            setError('Error generating video. Please try again.');
        } finally {
            setProcessing(false);
        }
    };

    const pollForResult = async (generationId) => {
        const maxAttempts = 5; // Maximum number of polling attempts
        const delay = 5000; // Delay between polling attempts (5 seconds)

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            try {
                const result = await GetVideoResult({ job_id: generationId, user_id: user_id });

                //console.log(result);
                if (result.statusCode === 200) {
                    return result;
                } else if (result.statusCode === 202) {
                    // Job is still processing
                    //console.log(`Attempt ${attempt}: Job is still processing...`);
                } else if (result.statusCode === 500) {
                    // Job is still processing
                    //console.log(`Attempt ${attempt}: ${result}`);
                } else {
                    throw new Error(`Unexpected status code: ${result.statusCode}`);
                }
            } catch (err) {
                console.error(`Attempt ${attempt}: Error fetching result - ${err.message}`);
            }

            // Wait before the next attempt
            await new Promise((resolve) => setTimeout(resolve, delay));
        }

        throw new Error('Timeout: Failed to get the video result within the maximum number of attempts.');
    };

    return (
        <Box sx={{ textAlign: 'center', overflowY: 'auto', maxHeight: '80vh' }}>
            <Typography sx={{ textAlign: 'center' }} variant="h4">
                Animate Image
            </Typography>
            <Box
                {...getRootProps()}
                sx={{
                    border: '2px dashed gray',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginBottom: '20px',
                }}
            >
                <input {...getInputProps()} />
                <Typography>
                    Drag and drop your file here, or click to select files (jpeg, png, webp)
                </Typography>
            </Box>
            {uploading && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
            )}
            {selectedImage && (
                <>
                    <Typography variant="h6">Image Preview:</Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <img
                            src={selectedImage}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                            }}
                        />
                    </Box>
                </>
            )}
            {imageResolution && imageSize && (
                <Box sx={{ mt: 2 }}>
                    <Typography>
                        Resolution: {imageResolution.width}x{imageResolution.height}
                    </Typography>
                    <Typography>Size: {imageSize} MB</Typography>
                </Box>
            )}
            {selectedImage && (
                <Box sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClear}
                        startIcon={<ClearIcon />}
                    >
                        Clear
                    </Button>
                </Box>
            )}
            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Optional Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ my: 2 }}>
                        <Typography gutterBottom>Creativity</Typography>
                        <Slider
                            value={cfgScale}
                            min={0}
                            max={10}
                            step={0.1}
                            //valueLabelDisplay="auto"
                            onChange={(e, newValue) => setCfgScale(newValue)}
                        />
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Typography gutterBottom>Motion Rate</Typography>
                        <Slider
                            value={motionBucketId}
                            min={1}
                            max={255}
                            step={1}
                            //valueLabelDisplay="auto"
                            onChange={(e, newValue) => setMotionBucketId(newValue)}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateVideo}
                sx={{ mt: 2 }}
                disabled={processing}
            >
                Generate Video
            </Button>
            {processing && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress />
                </Box>
            )}
            {resultVideoURL && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Generated Video:</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <video
                            src={resultVideoURL}
                            controls
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ImageToVideo;
