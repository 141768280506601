import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { addMedia, updateWeb } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

import { DenseInput } from '../utils';
import { RiDeleteBin6Fill } from 'react-icons/ri';

//

const AddURLDialog = ({ openWebDialog, handleCloseWebDialog, status }) => {
    const currentElement = JSON.parse(localStorage.getItem('current_element'));
    //console.log('=========== currentElement ==================', currentElement);
    const media = useSelector((state) => state.handleMedia);
    const state = useSelector((state) => state);
    //console.log('state', state);
    //console.log('RRRRRRReduxRRRRRRReduxRRRRRRReduxRRRRRRReduxRRRRRRReduxRRRRRRRedux\n', media);
    const [URL, setURL] = useState('');
    useEffect(() => {
        setURL(
            media?.find((x) => x.element_id === currentElement?.element_id)?.resource_list
                ? media?.find((x) => x.element_id === currentElement?.element_id)?.resource_list[0]
                      ?.resource_website
                : media?.find((x) => x.element_id === currentElement?.element_id)?.detail_list
                      .resource_content[0]?.resource_website
                ? media?.find((x) => x.element_id === currentElement?.element_id)?.detail_list
                      .resource_content[0]?.resource_website
                : '',
        );
    }, []);
    //console.log('file: AddURLDialog.jsx:111 => AddURLDialog => URL:', URL);
    const [input, setInput] = useState('');
    //console.log('file: AddURLDialog.jsx:112 => AddURLDialog => input:', input);
    const addProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return `https://${url}`;
        }
        return url;
    };
    // const notify = useNotification();
    const dispatch = useDispatch();

    // Action to ADD/DEL item to/from cart list
    const addItem = (item) => {
        //console.log('addItem', item);
        dispatch(addMedia(item));
    };

    // const removeItem = (item) => {
    // 	 //console.log('file: AddURLDialog.jsx:108 => removeItem => item:', item);
    // 	dispatch(removeWeb(item));
    // };
    const updateItem = (item) => {
        //console.log('file: AddURLDialog.jsx:108 => updateItem => item:', item);
        dispatch(updateWeb(item));
    };
    const handleChange = (e) => {
        setInput(addProtocol(e.target.value));
    };
    const handleAddURL = () => {
        if (isValidUrl(input)) {
            setURL(input);
            setInput('');
        } else {
            //console.log('please enter a valid URL');
        }
    };

    const isValidUrl = (input) => {
        // Regular expression pattern for URL validation
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

        // Test the input against the pattern
        return urlPattern.test(input);
    };

    const handleApply = async () => {
        const currentTextElement = currentElement?.resource_list
            ? currentElement?.resource_list[0].resource_website
            : null;
        //console.log('file: AddTextDialog.jsx:251 => handleApply => currentTextElement:', currentTextElement);
        if (URL) {
            // Replace resource_website with textData
            const dataWithElement = {
                ...currentElement,
                detail_list: {
                    ...currentElement.detail_list,
                    resource_content: [
                        {
                            ...currentElement.detail_list?.resource_content[0],
                            resource_website: URL,
                        },
                    ],
                },
            };

            // Use the updated dataWithElement object as needed
            //console.log(dataWithElement);
            if (status === 'edit') {
                updateItem(dataWithElement);
            } else {
                addItem(dataWithElement);
            }
            handleCloseWebDialog();
        } else {
            alert('Please enter a valid URL!');
            // handleCloseWebDialog();
        }
    };

    // const handleRemoveItem = (element) => {
    // 	 //console.log('file: AddURLDialog.jsx:155 => handleRemoveItem => element:', element);
    // 	// setURL('');
    // 	removeItem(element);
    // };

    return (
        <div>
            <Dialog fullWidth={true} maxWidth="md" open={openWebDialog} onClose={handleCloseWebDialog}>
                <DialogTitle sx={{ paddingTop: 0, paddingLeft: 0 }}>Add/Remove Website</DialogTitle>
                <DialogContent className="flex flex-col p-2 h-96 min-h-full">
                    <div className="w-full grid grid-cols-4 gap-4">
                        <div className="col-span-3">
                            <DenseInput
                                fullWidth
                                value={input}
                                onChange={handleChange}
                                placeholder="Example: https://soniqcast.com.au/"
                            />
                        </div>
                        <div className="col-span-1 flex">
                            <Button
                                sx={{ width: '100%', display: 'flex', alignSelf: 'center' }}
                                variant="contained"
                                onClick={handleAddURL}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                    {URL && (
                        <div className="mt-2 flex flex-row">
                            <Tooltip
                                sx={{ marginRight: '10px' }}
                                title="Remove"
                                onClick={
                                    () => setURL('')
                                    // handleRemoveItem(
                                    // 	media?.find((x) => x.element_id === currentElement?.element_id)
                                    // )
                                }
                            >
                                <IconButton size="small">
                                    <RiDeleteBin6Fill />
                                </IconButton>
                            </Tooltip>

                            <p className="m-0">{URL}</p>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <div className="grid grid-cols-2 gap-3">
                        <Button variant="contained" onClick={handleCloseWebDialog}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleApply}>
                            Add
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddURLDialog;
