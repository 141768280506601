import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material';

import { FaLayerGroup } from 'react-icons/fa';
import { Pulse } from '../utils';
import React from 'react';
import { styled } from '@mui/material/styles';

// import { RiDeleteBin6Fill, RiFolder3Fill, RiSettings2Fill } from 'react-icons/ri';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const LocationCampaignList = ({ campaign }) => {
    //console.log('LocationCampaignList => item:', campaign);
    // const [isHovered, setIsHovered] = useState();
    // const toggleHover = (item) => {
    //     setIsHovered(item);
    // };
    // const toggleLeave = () => {
    //     setIsHovered('');
    // };
    // // Remove device Alert
    // const [openUnassignDeviceDialog, setOpenUnassignDeviceDialog] = useState(false);
    // const [clickedItem, setClickedItem] = useState(undefined);
    // const handleClickOpen = (item) => {
    //     setOpenUnassignDeviceDialog(true);
    //     setClickedItem(item);
    // };

    // const handleClose = () => {
    //     setOpenUnassignDeviceDialog(false);
    // };
    return (
        <Box>
            <List dense={true} sx={{ maxHeight: '240px', overflowY: 'auto' }}>
                {campaign ? (
                    campaign?.map((item, index) => (
                        <ListItem
                            className="hover:bg-blue-100/50 rounded-lg "
                            sx={{ minHeight: '51px' }}
                            key={item + index}
                        >
                            <ListItemIcon>
                                <FaLayerGroup
                                    style={{
                                        color: '#0444AB',
                                        fontSize: '20px',
                                    }}
                                />
                            </ListItemIcon>
                            <Typography>{item.schedule_name}</Typography>
                        </ListItem>
                    ))
                ) : (
                    <Pulse />
                )}
            </List>
        </Box>
    );
};

export default LocationCampaignList;
