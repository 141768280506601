import { Box, Button, Container, IconButton, Typography } from '@mui/material';

import AWS from 'aws-sdk';
import Alert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import { ImageStyling } from './Api';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import config from '../../../config/config';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const user_id = JSON.parse(localStorage.getItem('userDetails'))?.user_id;

const ImageStyle = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(false);

    const [imageResolution, setImageResolution] = useState({
        width: null,
        height: null,
    });

    const [loading, setloading] = useState(false);
    const [processing, setprocessing] = useState(null);

    const [S3URL, setS3URL] = useState(null);

    const [imageSize, setImageSize] = useState(null);
    const [alertPNG, setalertPNG] = useState(false);

    const [repsonseURL, setrepsonseURL] = useState(null);

    const [progress, setProgress] = React.useState(0);
    const [error, setError] = useState(''); // State for error message

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        handleFileChange(file);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (file) => {
        setSelectedFile(file);
        setalertPNG(false);

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 5000000) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setImageSize(file.size);
                //console.log({ width: img.width, height: img.height });
                if (img.width === img.height && img.width <= 1650) {
                    const d = uploadS3(file);
                } else {
                    setalertPNG(true);
                }
            };
            img.src = URL.createObjectURL(file);
        } else {
            setalertPNG(true);
        }
    };

    const uploadS3 = async (selectedFile) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;

        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;

        const filteredFilename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '');

        AWS.config.update({
            accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
            secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            //ACL: "public-read",
            Body: selectedFile,
            Bucket: S3_BUCKET,
            Key: user_id + '/ImageVariation/' + filteredFilename,
        };
        //console.log(params);

        //const data = myBucket.putObject(params);
        // //console.log(data);
        setloading(true);

        var upload = myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt) => {
                //console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
                setProgress(parseInt((evt.loaded * 100) / evt.total));
            })
            .promise();

        await upload.then((err, data) => {
            //console.log(err);
            //console.log(upload);

            setloading(false);
            setProgress(0);
            setImagePreview(true);
        });

        setS3URL(
            'https://' +
                S3_BUCKET +
                '.s3.' +
                REGION +
                '.amazonaws.com/' +
                user_id +
                '/ImageVariation/' +
                filteredFilename,
        );
        //console.log(
        //     'https://' +
        //         S3_BUCKET +
        //         '.s3.' +
        //         REGION +
        //         '.amazonaws.com/' +
        //         user_id +
        //         '/ImageVariation/' +
        //         filteredFilename,
        // );
        //return data;
    };

    const CheckImage = async () => {
        if (imageResolution.width === imageResolution.height) {
            //console.log('pass', imageResolution);

            const body = {
                image_url: S3URL,
                user_id: user_id,
            };

            setprocessing(true);
            setError(false);

            try {
                const data = await ImageStyling(body);
                if (data['statusCode'] == 200) {
                    //console.log('ImageStyling Data:', data);
                    setrepsonseURL(data.message);

                    setprocessing(false);
                }
            } catch (error) {
                console.error('Error while processing:', error);
                setError('System busy, please try again later.');
                setprocessing(false);
            }
        } else {
            setalertPNG(true);
            //console.log('fail', imageResolution);
        }
    };

    const handleDownload = () => {
        if (repsonseURL) {
            const link = document.createElement('a');
            link.href = repsonseURL;
            link.download = 'style.png';

            link.click();
        }
    };

    const handleRefresh = () => {
        setSelectedFile(null);
        setImageSize(null);
        setImagePreview(null);
        setalertPNG(false);
        setrepsonseURL(null);
        setError(false);
    };
    return (
        <div>
            <Box sx={{ textAlign: 'center', overflowY: 'auto', maxHeight: '80vh' }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    Re-Style
                </Typography>
                <img
                    src={
                        'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/Example/re-style.jpg'
                    }
                    alt="Preview"
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: '100%',
                        maxHeight: '500px',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                />
                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed gray',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography>Drag drop your file here, or click to select files</Typography>
                </Box>

                {selectedFile !== null && (
                    <>
                        <Button variant="contained" color="error" onClick={handleRefresh}>
                            Clear
                        </Button>
                    </>
                )}

                <Typography variant="body1" gutterBottom>
                    {selectedFile && `Selected File: ${selectedFile.name}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {selectedFile && `Resolution: ${imageResolution.width}x${imageResolution.height}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {selectedFile && ` Size: ${(imageSize / 1024 / 1024).toFixed(2)} MB`}
                </Typography>

                {alertPNG == true && (
                    <div>
                        <Alert severity="error">
                            Please select a square PNG/JPG file ( Max 1650 x 1650) and size is less than 4MB{' '}
                        </Alert>
                    </div>
                )}
                {imagePreview == true && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 4,
                        }}
                    >
                        <Typography sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={S3URL}
                                    alt="Preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '300px',
                                        marginTop: '10px',
                                    }}
                                />
                            </Box>
                        </Typography>

                        <Typography sx={{ flex: 1, textAlign: 'center' }}>
                            <Button variant="contained" color="primary" onClick={CheckImage}>
                                Change Style
                            </Button>
                        </Typography>

                        <Typography sx={{ flex: 1, textAlign: 'right' }}>
                            {processing ? (
                                <Typography>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                </Typography>
                            ) : (
                                <Typography>
                                    {repsonseURL && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={repsonseURL}
                                                    alt="Preview"
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '300px',
                                                        marginTop: '10px',
                                                    }}
                                                />
                                                <IconButton
                                                    color="secondary"
                                                    onClick={handleDownload}
                                                    sx={{ ml: 2 }}
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Box>
                                        </>
                                    )}
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                )}
            </Box>

            {loading == true && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={progress} />
                </Box>
            )}
            {error && <Alert severity="error">{error}</Alert>}
        </div>
    );
};

export default ImageStyle;
