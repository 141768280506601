import { Button, Stack, Typography } from '@mui/material';

import React from 'react';
import { UploadedFilesTable } from './';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//     height: 10,
//     borderRadius: 5,
//     [`&.${linearProgressClasses.colorPrimary}`]: {
//         backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//     },
//     [`& .${linearProgressClasses.bar}`]: {
//         borderRadius: 5,
//         backgroundColor: theme.palette.mode === 'light' ? '#0444AB' : '#0444AB',
//     },
// }));

const UploadLocalMedia = ({ onImageChange, imageURLs, uploadFailed, uploadFailedMsg }) => {
    return (
        <Stack sx={{ minHeight: '400px', maxHeight: '400px', overflow: 'hidden' }}>
            <div className="flex flex-row w-full justify-center">
                <div>
                    <Button
                        sx={{
                            marginLeft: '8px',
                            maxWidth: '150px',
                            maxHeight: '40px',
                            minWidth: '150px',
                            minHeight: '40px',
                        }}
                        size="large"
                        variant="contained"
                        component="label"
                    >
                        <Typography sx={{ fontWeight: 500 }}>Browse</Typography>
                        <input
                            type="file"
                            multiple
                            hidden
                            accept=".gif, .jpg, .png, .mp4, .pdf, .mp3"
                            onChange={onImageChange}
                        />
                    </Button>
                </div>
                <div className="ml-2 p-1 mt-1.5">
                    <p className="text-sm inline-block align-middle text-gray-400">
                        File Type: .gif, .jpg, .png, .mp4, .pdf, .mp3
                    </p>
                </div>
            </div>
            <br />

            {imageURLs.length > 0 && (
                <div style={{ maxHeight: '240px', overflowY: 'auto' }}>
                    <hr />
                    <UploadedFilesTable
                        imageURLs={imageURLs}
                        uploadFailed={uploadFailed}
                        uploadFailedMsg={uploadFailedMsg}
                    />
                </div>
            )}
        </Stack>
    );
};

export default UploadLocalMedia;
