import { Box, Button, Chip, Tab, Typography } from '@mui/material';
import { FiCalendar, FiMonitor } from 'react-icons/fi';
import React, { useEffect, useRef, useState } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

const LeftLocationList = ({
    tabValue,
    handleChange,
    scheduleRows,
    handleOpenDevicesDialog,
    handleOpenScheduleListDialog,
}) => {
    return (
        <>
            <Typography variant="h5">Locations</Typography>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleChange}
                scrollButtons="off"
                aria-label="Location schedule details"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    [`& .MuiTabs-indicator`]: {
                        backgroundColor: 'transparent !important',
                    },
                    borderRight: 0,
                    height: '79vh',
                    marginTop: '10px',
                }}
            >
                {Array.isArray(scheduleRows) &&
                    scheduleRows.map((row) => (
                        <Tab
                            label={
                                <div className="flex flex-wrap items-center">
                                    <p
                                        title={row.locationName}
                                        className={`mb-0 mr-1 justify-center w-28 truncate ${
                                            tabValue === row.group_id ? 'text-white' : 'text-black'
                                        } font-semibold`}
                                    >
                                        {row.locationName}
                                    </p>
                                    <Chip
                                        label={row.device_count}
                                        sx={{
                                            backgroundColor: '#E5ECF6',
                                            color: '#0444AB',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            marginRight: '5px',
                                            zIndex: '1000',
                                            height: '25px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(229, 236, 246, 0.8)',
                                            },
                                        }}
                                        icon={
                                            <FiMonitor
                                                style={{
                                                    color: '#0444AB',
                                                    marginLeft: '10px',
                                                }}
                                            />
                                        }
                                        onClick={(e) => handleOpenDevicesDialog(e, row.group_id)}
                                    />
                                    <Chip
                                        label={row?.scheduleList?.length ? row?.scheduleList?.length : 0}
                                        sx={{
                                            backgroundColor: '#E5ECF6',
                                            color: '#0444AB',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            marginRight: '10px',
                                            zIndex: '1000',
                                            height: '25px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(229, 236, 246, 0.8)',
                                            },
                                        }}
                                        icon={
                                            <FiCalendar
                                                style={{
                                                    color: '#0444AB',
                                                    marginLeft: '10px',
                                                }}
                                            />
                                        }
                                        onClick={(e) => handleOpenScheduleListDialog(e, row.group_id)}
                                    />
                                </div>
                            }
                            key={row.group_id}
                            value={row.group_id}
                            sx={{
                                backgroundColor: tabValue === row.group_id ? '#0444AB' : '#ffffff',
                                textTransform: 'none',
                                marginBottom: '12px',
                                marginRight: '12px',
                                borderRadius: '16px',
                            }}
                        />
                    ))}
            </Tabs>
        </>
    );
};

export default LeftLocationList;
