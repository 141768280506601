import React from 'react';

import { Header } from '../';
import { StripeSubscription } from './payment';
import { useStateContext } from '../../../contexts/ContextProvider';

const Subscription = () => {
    const { currentUser } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            <Header
                title={`${
                    userDetails.customer_type === 2
                        ? 'Inspirer'
                        : userDetails.customer_type === 1
                        ? 'Creator'
                        : ''
                } Subscription`}
            />
            <div className="pb-10 pl-10 pr-10 pt-0">
                <StripeSubscription
                    currentUser={currentUser}
                    switchUser={switchUser}
                    customerType={userDetails.customer_type}
                    username={userDetails.username}
                />
            </div>
        </div>
    );
};

export default Subscription;
