import React from 'react';

const Pulse = ({ count = 5 }) => {
    const skeletonItems = Array(count).fill(null);
    return (
        <div className="animate-pulse flex space-x-4 mt-6 p-2">
            <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-200 rounded"></div>
                {count > 0 && (
                    <div className="space-y-3">
                        {skeletonItems.map((_, index) => (
                            <div className="h-2 bg-slate-200 rounded"></div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Pulse;
