import {
    Box,
    Button,
    Container,
    FormControlLabel,
    Grid,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import Chip from '@mui/material/Chip';
import config from '../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;

//const login_url = redirect_base_url+"/login"

const PricingPlans = () => {
    const [billingCycle, setBillingCycle] = useState('yearly');
    const [tabIndex, setTabIndex] = useState(0);

    const handleBillingCycleChange = (event) => {
        setBillingCycle(event.target.checked ? 'yearly' : 'monthly');
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const plans = [
        {
            name: 'Creator',
            monthlyPrice: '$14.99/mo',
            yearlyPrice: '$11.99/mo',
            description: 'Discover the fundamentals of digital signage with our introductory lite plan.',
        },
        {
            name: 'Inspirer',
            monthlyPrice: '$19.99/mo',
            yearlyPrice: '$15.99/mo',
            description: 'Unleash your creativity with AI-driven content creation and intuitive controls.',
        },
        {
            name: 'Designer',
            monthlyPrice: '$29.99/mo',
            yearlyPrice: '$288/yr',
            description: 'Elevate your content with enterprise-level design and lock-in lifetime pricing.',
        },
    ];

    const calculatePrice = (monthlyPrice, yearlyPrice) => {
        if (billingCycle === 'yearly') {
            const monthlyEquivalent = parseFloat(yearlyPrice.replace('$', '').replace('/yr', '')) / 12;
            const discountedPrice = (monthlyEquivalent - 0.01).toFixed(2);
            return `$${discountedPrice}/mo`;
        }
        return monthlyPrice;
    };

    const Mainfeatures = [
        // {
        //     feature: 'Price',
        //     creator: calculatePrice('$14.99/mo', '$144/yr'),
        //     inspirer: calculatePrice('$19.99/mo', '$192/yr'),
        //     designer: calculatePrice('$29.99/mo', '$288/yr'),
        // },
        { feature: 'Number of screens', creator: '1', inspirer: '1', designer: 'Minimum 10' },
        { feature: 'Cloud Media Storage', creator: '1GB', inspirer: 'Unlimited', designer: 'Unlimited' },
        { feature: 'Device Auto Start ', creator: true, inspirer: true, designer: true },
        { feature: 'Digital Signage Essentials', creator: true, inspirer: true, designer: true },
        { feature: 'PDF Document Upload', creator: true, inspirer: true, designer: true },
        { feature: 'Display Web Page', creator: false, inspirer: true, designer: true },
        { feature: 'Advance Touch Function', creator: false, inspirer: true, designer: true },
        { feature: 'Location Management', creator: true, inspirer: true, designer: true },
        { feature: 'Pre-defined Template', creator: true, inspirer: true, designer: true },
        { feature: 'Playlist', creator: true, inspirer: true, designer: true },
        { feature: 'Schedule Contents', creator: true, inspirer: true, designer: true },
    ];

    const AccountFeatures = [
        { feature: 'Offline Notification Tracking', creator: true, inspirer: true, designer: true },
        { feature: 'Offline Email Notification ', creator: true, inspirer: true, designer: true },
        { feature: '2-Factor SMS Authentication', creator: true, inspirer: true, designer: true },
        { feature: 'Analytics Report', creator: false, inspirer: true, designer: true },
    ];

    const AIFeatures = [
        { feature: 'Attention Metric', creator: true, inspirer: true, designer: true },
        { feature: 'Ad suggestion', creator: true, inspirer: true, designer: true },
       
        { feature: 'EveryDay Inspiration', creator: true, inspirer: true, designer: true },
        { feature: '4K Image Upscaling', creator: false, inspirer: true, designer: true },
        { feature: 'AI Image Generation', creator: false, inspirer: true, designer: true },
        { feature: 'Text Enhancement', creator: false, inspirer: true, designer: true },
        { feature: 'AI Image Restyling', creator: false, inspirer: true, designer: true },
        { feature: 'Image Resizing & Segmentation', creator: false, inspirer: true, designer: true },
        { feature: 'Image Animation', creator: false, inspirer: true, designer: true },
        { feature: 'Batch Mode', creator: false, inspirer: false, designer: true },
    ];

    const OtherFeatures = [
        { feature: '5/24 Support', creator: true, inspirer: true, designer: true },
        { feature: '7/24 Support', creator: false, inspirer: true, designer: true },
        {
            feature: 'Content Design & Development',
            creator: false,
            inspirer: false,
            designer: 'First 12 hours free',
        },
        { feature: 'Customised Project ', creator: false, inspirer: false, designer: 'First 12 hours free' },
        { feature: 'API access and integration', creator: false, inspirer: false, designer: true },
        { feature: 'Multi-Screen Discount ', creator: false, inspirer: false, designer: true },
        { feature: 'Lifetime Capped Rate', creator: false, inspirer: false, designer: true },
    ];

    return (
        <Container maxWidth="lg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            {/* Page Title */}
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Experience the Power of AI – Always Free for Everyone
            </Typography>

            

            <Box sx={{ mt: 3, mb: 3 }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    centered
                    sx={{
                        '& .MuiTab-root': { fontWeight: 'bold', fontSize: '1rem' },
                        '& .MuiTabs-indicator': { bgcolor: '#0444AB' },
                    }}
                >
                    <Tab label="AI Features" />
                    <Tab label="Deployment Plans" />
                </Tabs>
            </Box>

            {tabIndex === 0 && (
                <Box sx={{ mt: 4 }}>
                    <Typography
                        variant="h5"
                        component="h2"
                        align="center"
                        gutterBottom
                        sx={{ color: 'primary.main' }}
                    >
                        AI Features – Always Free
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                        <Table sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: '1.2rem',
                                            fontWeight: 'bold',
                                            bgcolor: '#f5f5f5',
                                            color: '#444',
                                        }}
                                    >
                                        Feature
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            fontSize: '1.2rem',
                                            fontWeight: 'bold',
                                            bgcolor: '#f5f5f5',
                                            color: '#444',
                                        }}
                                    >
                                        Available
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {AIFeatures.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ fontSize: '1rem', color: '#333' }}>
                                            {row.feature}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.available ? (
                                                <CheckCircleSharpIcon
                                                    sx={{
                                                        color: 'green', // Green tick color
                                                        fontSize: '1.5rem',
                                                    }}
                                                />
                                            ) : (
                                                <Typography sx={{ color: '#999', fontSize: '1rem' }}>
                                                    <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            )}

            {tabIndex === 1 && (
                <Box mt={4}>
                    <Box my={4} textAlign="center">
                    <Typography
                        variant="h5"
                        component="h2"
                        align="center"
                        gutterBottom
                        sx={{ color: 'primary.main' }}
                    >
                        Pay Only for Active Screens – Simple and Flexible Pricing
                    </Typography>
                        <FormControlLabel
                            control={<Switch checked={billingCycle === 'yearly'} onChange={handleBillingCycleChange} />}
                            label={billingCycle === 'monthly' ? 'Monthly' : 'Yearly'}
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Grid container spacing={4} justifyContent="flex-end">
                        
                            {plans.map((plan, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <Box
                                        border={1}
                                        borderRadius={4}
                                        p={2}
                                        textAlign="center"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        height="350px"  // Set the desired height for uniform sizing
                                    >
                                        <Typography variant="h6" component="h3" gutterBottom>
                                            {plan.name}
                                        </Typography>
                                        <Box display="flex" justifyContent="center" alignItems="baseline">
                                            {plan.name === 'Designer' ? (
                                                <Typography variant="h3" component="p" gutterBottom>
                                                    Contact Us
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Typography variant="body2" component="span" style={{ marginRight: '4px' }}>
                                                        From
                                                    </Typography>
                                                    <Typography variant="h3" component="p" gutterBottom>
                                                        {billingCycle === 'monthly'
                                                            ? plan.monthlyPrice.split('/')[0]
                                                            : plan.yearlyPrice.split('/')[0]}
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                        {billingCycle === 'yearly' && plan.name !== 'Designer' && (
                                            <Box display="flex" justifyContent="center" marginTop="8px">
                                                <Chip
                                                    label="20% Off"
                                                    color="secondary"
                                                    size="small"
                                                    style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                                />
                                            </Box>
                                        )}
                                        <Typography variant="body2" component="p">
                                            mo/screen
                                        </Typography>
                                        <Typography variant="body2" paragraph>
                                            {plan.description}
                                        </Typography>
                                        <Button
                                            href="/login"
                                            variant="contained"
                                            color="primary"
                                            style={{ backgroundColor: '#0444AB', color: '#ffffff' }}
                                        >
                                            {plan.name === 'Designer' ? 'Contact Us' : 'Get started'}
                                        </Button>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                Main Features
                            </TableCell>
                            {plans.map((plan, index) => (

                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Mainfeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.feature === 'Price' ? 'Price/mo' : row.feature}</TableCell>
                                {row.feature === 'Price' ? (
                                    <>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.creator.slice(0, -3)}
                                                color="secondary"
                                                size="small"
                                                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.inspirer.slice(0, -3)}
                                                color="secondary"
                                                size="small"
                                                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.designer.slice(0, -3)}
                                                color="secondary"
                                                size="small"
                                                style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center">
                                            {row.creator === true ? (
                                                <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                            ) : (
                                                row.creator
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.inspirer === true ? (
                                                <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                            ) : (
                                                row.inspirer
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.designer === true ? (
                                                <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                            ) : (
                                                row.designer
                                            )}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                Account & Device
                            </TableCell>
                            {plans.map((plan, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {AccountFeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.feature}</TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.creator === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.creator
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.inspirer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.inspirer
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.designer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.designer
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

        

            <Box mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '25%', fontSize: '1.25rem', fontWeight: 'bold' }}>
                                Other Features
                            </TableCell>
                            {plans.map((plan, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ width: 'calc(75% / 3)', minWidth: '45px' }}
                                ></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {OtherFeatures.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ width: '25%' }}>{row.feature}</TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.creator === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.creator
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.inspirer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.inspirer
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ width: 'calc(75% / 3)' }}>
                                    {row.designer === true ? (
                                        <CheckCircleSharpIcon style={{ color: '#0444AB' }} />
                                    ) : (
                                        row.designer
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>



                </Box>
            )}



            
        </Container>
    );
};

export default PricingPlans;
