// import 'axios';

import * as pdfjs from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const readFileData = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(new Uint8Array(e.target.result));
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
    });
};
// const dataURLtoFile = async (dataURL, filename) => {
// 	const arr = dataURL.split(',');
// 	const mime = arr[0].match(/:(.*?);/)[1];
// 	const base64 = arr[1];
// 	// const response = await fetch(dataURL);
// 	// const arrayBuffer = await response.arrayBuffer();
// 	// const blob = new Blob([arrayBuffer], { type: mime });
// 	// Convert base64 to binary
// 	const binaryString = window.atob(base64);
// 	const len = binaryString.length;
// 	const bytes = new Uint8Array(len);

// 	for (let i = 0; i < len; i++) {
// 		bytes[i] = binaryString.charCodeAt(i);
// 	}

// 	const file = new File([bytes], filename, { type: mime });
// 	return file;
// };

const PdfToImages = async (pdfFile) => {
    //console.log('file: PdfToImages.jsx:35 => PdfToImages => pdfFile:', pdfFile);
    const fileData = await readFileData(pdfFile);

    const pdf = await pdfjs.getDocument({ data: fileData }).promise;
    const numPages = pdf.numPages;

    const convertedImages = [];

    for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 1.5 });

        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport,
        };

        await page.render(renderContext).promise;

        const imageDataUrl = await canvas.toDataURL('image/jpeg');
        const arr = imageDataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const convertedFile = await fetch(imageDataUrl)
            .then(function (res) {
                //console.log('file: PdfToImages.jsx:108 => res:', res);
                return res.arrayBuffer();
            })
            .then(function (buf) {
                //console.log('file: PdfToImages.jsx:111 => buf:', buf);
                return new File([buf], `${pdfFile.name.split('.pdf')[0]}[${i}].png`, {
                    type: mime,
                });
            });
        // const fileURL = new File([imageDataUrl], 'filename', { type: mime });
        convertedImages.push(convertedFile);
    }
    //console.log(
    // 	'file: PdfToImages.jsx:92 => //convertPdfToImages => convertedImages:',
    // 	convertedImages
    // );
    return convertedImages;
};

export default PdfToImages;
