import {
    Box,
    Breadcrumbs,
    Button,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    MenuItem,
    Select,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from '@mui/material';
// import FormControl from '@mui/material/FormControl';
import { GetCyberpoint, GetPriceList } from './Api';
import { useEffect, useState } from 'react';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { FaCalendarAlt } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';
import Form from 'react-bootstrap/Form';
import { Getalldevice } from './Api';
import { Header } from '../../';
import { MdOutlinePayment } from 'react-icons/md';
// import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import PaypalPay from './PaypalPay';
import PointsDialog from './PointsDialog';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
// import SelectPlanDialog from './SelectPlanDialog';
// import Select from '@mui/material/Select';
import StripePay from './StripePay';
// import Tab from 'react-bootstrap/Tab';
// import Table from 'react-bootstrap/Table';
// import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment';
import { styled } from '@mui/material/styles';

/// mui select

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '6px',
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div sx={{ p: 3 }}>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const DeviceSummary = ({
    user_id,
    customerType,
    username,
    handleNext,
    handleBack,
    selectedType,
    // selectedPrice,
}) => {
    const body = { userId: user_id };

    /**
     *
     *
     * Handle change tabValue
     *
     */
    const [tabValue, setTabValue] = useState(0);
    // const [selectPlanDialog, setSelectPlanDialog] = useState(true);
    //console.log('file: DeviceSummary.jsx:65 => DeviceSummary => tabValue:', tabValue);

    const handleChangeTab = (newValue) => {
        // if (newValue !== 0) handleNext();
        //console.log('file: DeviceSummary.jsx:67 => handleChangeTab => newValue:', newValue);
        if (newValue === 0) {
            getDeviceList(body);
            handleBack();
        }

        if (newValue === 1) {
            //console.log(selectedrows);
            handleNext();
        }
        setTabValue(newValue);
    };

    // const [activeTab, setActiveTab] = useState('device');

    useEffect(() => {
        //console.log('file: DeviceSummary.jsx:81 => useEffect => tabValue:', tabValue);
        if (tabValue === 0) {
            getDeviceList(body);

            setSelectedrows([]);
        }
    }, [tabValue]);

    const [devices, setdevices] = useState([]);
    const getDeviceList = async () => {
        // //console.log(abc)

        const data = await Getalldevice(body);
        //console.log('GetDeviceList:', data.message);

        const total = data.message;
        total.map((dev) => {
            dev.type = '1 year';
        });

        setdevices(total);
    };

    const getstartdate = (expire_time) => {
        const x = moment.unix(expire_time);
        const y = moment();

        if (x > y) {
            return x.format('DD/MM/YYYY');
        } else {
            return y.format('DD/MM/YYYY');
        }
    };
    const getenddate = (expire_time, type) => {
        const enddate = () => {
            const a = moment.unix(expire_time);
            const b = moment();
            if (a > b) {
                return a;
            }
            return b;
        };
        // return enddate().add(1, 'month').format('DD/MM/YYYY');

        // if (type === '3 months') {
        //     return enddate().add(3, 'month').format('DD/MM/YYYY');
        // }
        if (type === '6 months') {
            return enddate().add(6, 'month').format('DD/MM/YYYY');
        }
        if (type === '1 year') {
            return enddate().add(1, 'year').format('DD/MM/YYYY');
        }
    };
    const unitprice = (type, dev) => {
        if (type === '6 months') {
            if (selectedType == 2) {
                dev['price'] = price_list.BS180;
                return dev['price'];
            } else {
                dev['price'] = price_list.GS180;
                return dev['price'];
            }
        }
        if (type === '1 year') {
            if (selectedType == 2) {
                dev['price'] = price_list.BS360;
                return dev['price'];
            } else {
                dev['price'] = price_list.GS360;
                return dev['price'];
            }
        }
    };

    const [selectedrows, setSelectedrows] = useState([]);
    //console.log('file: DeviceSummary.jsx:185 => DeviceSummary => selectedrows:', selectedrows);
    const handleRenewTypeChange = (id, type) => {
        const updatedRows = [...devices];
        const rowToUpdate = updatedRows.find((row) => row.id === id);
        rowToUpdate.type = type;
        rowToUpdate.new_end = getenddate(rowToUpdate.expire_time, type);

        // Update the selectedrows state based on the updated renew type

        setdevices(updatedRows);
        //console.log(`Row ${id} renew type changed to ${type}`);
        //console.log('row to update', rowToUpdate);
        //console.log('update row', updatedRows);
    };

    // const [totalPrice, setTotalPrice] = useState();
    const totalPrice = selectedrows.reduce((accumulator, row) => accumulator + row.price, 0);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        //console.log('file: DeviceSummary.jsx:206 => handleChange => checked:', checked);

        if (checked) {
            let tempUser = devices.map((dev) => {
                if (dev.subscription === 'true') {
                    dev['new_start'] = getstartdate(dev.expire_time);
                    dev['new_end'] = getenddate(dev.expire_time, dev.type);

                    return { ...dev, selected: !checked };
                } else {
                    dev['new_start'] = getstartdate(dev.expire_time);
                    dev['new_end'] = getenddate(dev.expire_time, dev.type);

                    return { ...dev, selected: checked };
                }
            });
            setdevices(tempUser);
            if (tempUser.filter((x) => x.selected === true)) {
                setSelectedrows(tempUser.filter((x) => x.selected === true));
            } else {
                setSelectedrows([]);
            }

            //console.log('tempUser', tempUser);
        } else {
            let tempUser = devices.map((dev) => ({
                ...dev,
                selected: checked,
            }));
            setdevices(tempUser);
            if (tempUser.filter((x) => x.selected === true)) {
                setSelectedrows(tempUser.filter((x) => x.selected === true));
            } else {
                setSelectedrows([]);
            }

            //console.log('tempUser', tempUser);
        }
    };

    const handleRSelect = (dev, allrow) => {
        const updatedRows = [...devices];
        const selectedRow = updatedRows.find((row) => row.id === dev.id);
        selectedRow.selected = !selectedRow.selected;
        setdevices(updatedRows);

        if (selectedRow.selected === true) {
            dev['new_start'] = getstartdate(dev.expire_time);
            dev['new_end'] = getenddate(dev.expire_time, dev.type);

            setSelectedrows([...selectedrows, dev]);
        } else {
            setSelectedrows(selectedrows.filter((r) => r !== dev));
        }
        // //console.log("rrrrrrrrrrrrrrrrr", updatedRows);
    };

    ///
    const oneYearArray = selectedrows.filter((item) => item.type === '1 year');

    const numberOfOneYearItems = oneYearArray.length;

    const sixMonthsArray = selectedrows.filter((item) => item.type === '6 months');

    const numberOfsixMonthsItems = sixMonthsArray.length;

    const subtotal = (totalPrice / 1.1).toFixed(2);
    const gst = (totalPrice - subtotal).toFixed(2);

    ///
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [ppshow, setppShow] = useState(false);
    const pphandleClose = () => setppShow(false);
    const pphandleShow = () => setppShow(true);

    /// set customer type

    const [isBusinessCustomer, setIsBusinessCustomer] = useState(customerType === 2 ? true : false);
    // const handleSwitchChange = () => {
    //     if (customerType === 2 && isBusinessCustomer) {
    //         const confirmed = window.confirm(
    //             "Are you certain about transitioning from a business customer to a general one? This switch entails forfeiting several exclusive benefits, such as the loss of unlimited storage and warranty coverage. It's crucial to carefully consider these implications before proceeding with the change.",
    //         );
    //         if (confirmed) {
    //             setIsBusinessCustomer(!isBusinessCustomer);
    //         }
    //     } else {
    //         setIsBusinessCustomer(!isBusinessCustomer);
    //     }

    //      //console.log('bbb');
    // };
    const customer_type = isBusinessCustomer ? 'Inspirer' : 'Creator';

    const [price_list, setprice_list] = useState('');

    const [price_id_list, setprice_id_list] = useState({});

    const getPriceList = async () => {
        const data_list = await GetPriceList();
        //console.log('GetPriceList:', data_list.message);

        const extractedData = {};
        const extractedIdData = {}; //get the price id data

        // Iterate through the object and extract the "amount" values
        for (const key in data_list.message) {
            if (data_list.message.hasOwnProperty(key)) {
                extractedData[key] = data_list.message[key].amount;
                extractedIdData[key] = data_list.message[key].price;
            }
        }

        setprice_list(extractedData);
        setprice_id_list(extractedIdData);
    };
    const [cyberPoints, setCyberPoints] = useState(0);
    //console.log('file: DeviceSummary.jsx:328 => DeviceSummary => cyberPoints:', cyberPoints);
    const handleGetPoints = async () => {
        const data = await GetCyberpoint(user_id);
        //console.log('file: DeviceSummary.jsx:333 => handleGetPoints => data:', data);
        setCyberPoints(data.message);
    };
    useEffect(() => {
        getPriceList();
        handleGetPoints();
        //console.log('GetAmountList:', price_list);
        //console.log('GetIdList:', price_id_list);
    }, []);

    // Handle show cyberpoints dialog
    const [openPointsDialog, setOpenPointsDialog] = useState(false);

    const handleOpenPointsDialog = () => {
        setOpenPointsDialog(true);
    };
    const handleClosePointsDialog = () => {
        setOpenPointsDialog(false);
    };
    // useEffect(() => {
    //     setTotalPrice(selectedrows.length * parseFloat(selectedPrice));
    // }, [selectedrows, isBusinessCustomer, devices]);

    // Select Plan section
    // const handleCloseSelectPlanDialog = () => {
    //     setSelectPlanDialog(false);
    // };

    return (
        <div className="p-4">
            {/* <div className="w-full columns-2 flex justify-between">
                <Header title={`Subscription Renew`} />
                <div className="flex align-middle items-center">
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link
                            underline="hover"
                            color={tabValue === 0 ? 'text.primary' : 'inherit'}
                            onClick={() => handleChangeTab(0)}
                            style={{ cursor: 'pointer', display: 'flex' }}
                        >
                            <div className="flex items-center">
                                <FiMonitor />
                            </div>
                            <p className="ml-2 items-center">Select Devices </p>
                        </Link>

                        {tabValue === 0 ? (
                            <div style={{ display: 'flex', color: 'gray' }}>
                                <div className="flex items-center">
                                    <MdOutlinePayment />
                                </div>
                                <p className="ml-2">Payment</p>
                            </div>
                        ) : (
                            <Link
                                underline="hover"
                                color={tabValue === 1 ? 'text.primary' : 'inherit'}
                                onClick={() => handleChangeTab(1)}
                                style={{ cursor: 'pointer', display: 'flex' }}
                            >
                                <div className="flex items-center">
                                    <MdOutlinePayment />
                                </div>
                                <p className="ml-2">Payment</p>
                            </Link>
                        )}
                    </Breadcrumbs>
                </div>
            </div> */}

            {tabValue === 0 && (
                <div>
                    {/* <div className="flex flex-row justify-center mb-4">
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isBusinessCustomer}
                                        onChange={handleSwitchChange}
                                        name="custom-switch"
                                    />
                                }
                                label={customer_type}
                            />
                        </div>
                    </div> */}

                    <div className="bg-white rounded-lg">
                        {devices?.length > 0 && price_list !== '' ? (
                            <div className="overflow-scroll p-2 w-full ">
                                <TableContainer sx={{ maxHeight: 440, width: '100%', padding: '10px' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>
                                                    <Checkbox
                                                        checked={
                                                            devices.filter((dev) => dev?.selected === true)
                                                                .length +
                                                                devices.filter(
                                                                    (dev) => dev.subscription === 'true',
                                                                ).length ===
                                                            devices.length
                                                        }
                                                        onChange={handleChange}
                                                        name="allSelect"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>Device ID</StyledTableCell>
                                                <StyledTableCell sx={{ maxWidth: '150px' }}>
                                                    Device Name
                                                </StyledTableCell>
                                                <StyledTableCell>Group Name</StyledTableCell>
                                                <StyledTableCell>Expire Date</StyledTableCell>
                                                <StyledTableCell sx={{ maxWidth: '130px' }}>
                                                    Renew Type
                                                </StyledTableCell>
                                                <StyledTableCell>New Subscription Dates</StyledTableCell>
                                                <StyledTableCell>Price</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {devices &&
                                                devices.map((dev) => (
                                                    <TableRow
                                                        key={dev.id}
                                                        title={
                                                            dev.subscription === 'true'
                                                                ? 'Subscription is active. This row is disabled. Please manage your subscription on the subscription page.'
                                                                : ''
                                                        }
                                                    >
                                                        <StyledTableCell>
                                                            <Checkbox
                                                                checked={dev?.selected || false}
                                                                onChange={() => handleRSelect(dev)}
                                                                disabled={dev.subscription === 'true'}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>{dev.id}</StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{
                                                                maxWidth: '150px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {dev.device_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell>{dev.group_name}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {moment
                                                                .unix(dev.expire_time)
                                                                .format('DD/MM/YYYY')}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ width: '130px' }}>
                                                            <FormControl size="small">
                                                                <Select
                                                                    labelId="select-row-label"
                                                                    id="select-row"
                                                                    sx={{ width: '130px' }}
                                                                    value={dev.type}
                                                                    disabled={dev.subscription === 'true'}
                                                                    onChange={(e) =>
                                                                        handleRenewTypeChange(
                                                                            dev.id,
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="1 year">1 year</MenuItem>
                                                                    <MenuItem value="6 months">
                                                                        6 months
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ width: '230px' }}>
                                                            <div className="flex bg-blue-100 rounded-full p-2 items-center">
                                                                <FaCalendarAlt
                                                                    style={{ color: 'rgb(8 145 178)' }}
                                                                />
                                                                <p className="ml-2 text-blue-800">
                                                                    {`${getstartdate(
                                                                        dev.expire_time,
                                                                    )} - ${getenddate(
                                                                        dev.expire_time,
                                                                        dev.type,
                                                                    )}`}
                                                                </p>
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            ${unitprice(dev.type, dev)}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        ) : (
                            <div>
                                <p></p>
                            </div>
                        )}
                    </div>
                    <div className="bg-white rounded-lg p-4 mt-4 flex flex-row justify-between">
                        <div className="flex flex-row">
                            <div className="flex items-center mr-3">
                                <FiMonitor size={40} style={{ color: '#0444AB' }} />
                            </div>
                            <div className="flex flex-row divide-x divide-solid ">
                                <div className="px-3">
                                    <Typography variant="h3">{selectedrows.length}</Typography>
                                    <Typography variant="body2">selected Devices</Typography>
                                </div>
                                <div className="px-3">
                                    <Typography variant="h3">{totalPrice}</Typography>
                                    <Typography variant="body2">Total Price</Typography>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                onClick={(e) => handleChangeTab(1)}
                                disabled={selectedrows.length === 0}
                            >
                                Continue to Payment
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {tabValue === 1 && (
                <div>
                    <Card className="text-center">
                        <Card.Header>Confirm Your Order</Card.Header>
                        <Card.Body>
                            {oneYearArray?.length > 0 ? (
                                <div>
                                    SignInspire Subscription {customer_type} Edition - Yearly x{' '}
                                    {numberOfOneYearItems}
                                    <div>
                                        {oneYearArray.map((element) => {
                                            return (
                                                <Badge bg="secondary" key={element.id}>
                                                    {element.id}
                                                </Badge>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            {sixMonthsArray?.length > 0 ? (
                                <div>
                                    SignInspire Subscription {customer_type} Edition - 6 Monthly x{' '}
                                    {numberOfsixMonthsItems}
                                    <div>
                                        {sixMonthsArray.map((element) => {
                                            return (
                                                <Badge bg="secondary" key={element.id}>
                                                    {element.id}
                                                </Badge>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div className="flex flex-col">
                                <span>Subtotal: ${subtotal}</span>
                                <span>GST: ${gst}</span>
                                <span>Total: ${totalPrice}</span>
                            </div>
                        </Card.Body>
                        <Card.Footer className="text-muted">Select Payment Method</Card.Footer>
                    </Card>
                    <div className="d-flex justify-content-center ">
                        <Box display="flex" justifyContent="center" mt={4}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Card sx={{ width: 300, margin: 2 }}>
                                        <CardHeader
                                            avatar={
                                                <CreditScoreIcon
                                                    sx={{ color: 'primary.main', fontSize: 40 }}
                                                />
                                            }
                                            title="Pay With Sign Points"
                                            titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Free Payment Available: {cyberPoints}
                                            </Typography>
                                            <Box display="flex" justifyContent="center" mt={2}>
                                                <Button
                                                    sx={{ padding: '8px 16px' }}
                                                    variant="contained"
                                                    onClick={handleOpenPointsDialog}
                                                >
                                                    Go Checkout
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <PointsDialog
                                        openPointsDialog={openPointsDialog}
                                        handleClosePointsDialog={handleClosePointsDialog}
                                        userId={user_id}
                                        selectedrows={selectedrows}
                                        cyberPoints={cyberPoints}
                                        totalPrice={totalPrice}
                                        customerType={customerType}
                                    />
                                </Grid>

                                <Grid item>
                                    <Card sx={{ width: 300, margin: 2 }}>
                                        <CardHeader
                                            avatar={
                                                <CreditCardIcon
                                                    sx={{ color: 'primary.main', fontSize: 40 }}
                                                />
                                            }
                                            title="Debit/Credit Card"
                                            titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                You will be redirected to Stripe.
                                            </Typography>
                                            <Box display="flex" justifyContent="center" mt={2}>
                                                <Button
                                                    sx={{ padding: '8px 16px' }}
                                                    variant="contained"
                                                    onClick={handleShow}
                                                >
                                                    Go Checkout
                                                </Button>
                                            </Box>
                                        </CardContent>
                                        <Modal show={show} onClose={handleClose}>
                                            <Card
                                                sx={{
                                                    padding: 2,
                                                    maxWidth: 500,
                                                    margin: 'auto',
                                                    marginTop: 4,
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                                                        Debit/Credit Card
                                                    </Typography>
                                                    <Typography>You will be redirected to Stripe.</Typography>
                                                </CardContent>
                                                <CardContent>
                                                    <Box display="flex" justifyContent="flex-end">
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleClose}
                                                            sx={{ marginRight: 2 }}
                                                        >
                                                            Close
                                                        </Button>
                                                        <StripePay
                                                            user_id={user_id}
                                                            username={username}
                                                            selectedrows={selectedrows}
                                                            numberOfsixMonthsItems={numberOfsixMonthsItems}
                                                            numberOfOneYearItems={numberOfOneYearItems}
                                                            isBusinessCustomer={isBusinessCustomer}
                                                            price_id_list={price_id_list}
                                                            customerType={customerType}
                                                            NewcustomerType={selectedType}
                                                        />
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Modal>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="mb-4" style={{ textAlign: 'left' }}>
                        <Button variant="contained" onClick={(e) => handleChangeTab(0)}>
                            back
                        </Button>
                    </div>
                </div>
            )}
            {/* <SelectPlanDialog open={selectPlanDialog} onClose={handleCloseSelectPlanDialog} /> */}
            {/* </TabPanel> */}
        </div>
    );
};

export default DeviceSummary;
