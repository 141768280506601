import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CopyCampaign, CopyTemplate } from '../../../api/api';

import React from 'react';
import { useNotification } from '../../../contexts/ContextProvider';

const CopyDialog = ({
    text,
    copyItem,
    openCopyDialog,
    handleCloseCopyDialog,
    setTriggerUpdate,
    triggerUpdate,
}) => {
    //console.log('file: CopyDialog.jsx:16 => CopyDialog => copyItem:', copyItem);
    const notify = useNotification();
    //console.log('text:', text);
    //  //console.log('deleteItem', deleteItem);
    const typeOfCopy = text.toLowerCase();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    //Handle Copy function
    const handleCopy = async () => {
        // 	 //console.log('item', deleteItem);
        let data;
        if (typeOfCopy === 'campaign') {
            data = await CopyCampaign(switchUser, copyItem.campaign_id);
        }
        if (typeOfCopy === 'template') {
            data = await CopyTemplate(switchUser, copyItem.template_id);
        }
        //console.log(data);

        if (data.code === 0) {
            // if (typeOfCopy === 'campaign') {
            // 	setTriggerUpdate(!triggerUpdate);
            // 	handleClose();
            // }
            //console.log('data.code', data.code);
            setTriggerUpdate(!triggerUpdate);
            //console.log('After update');
            handleCloseCopyDialog();
            // setPopUpInfo();
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message:
                        typeOfCopy.charAt(0).toUpperCase() +
                        typeOfCopy.slice(1) +
                        ' was copied successfully!',
                },
            });
        } else {
            handleCloseCopyDialog();
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Copy was unsuccessfully.',
                },
            });
        }
    };

    return (
        <Dialog open={openCopyDialog} PaperProps={{ sx: { width: '45%' } }}>
            <DialogTitle
                sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    paddingBottom: '0',
                    marginBottom: '10px',
                }}
            >
                Do you wish to copy {copyItem.campaign_name || copyItem.template_name}?
            </DialogTitle>

            {copyItem.template_name && (
                <DialogContent>
                    Template will be copied to custom templates, Please find it in custom templates section
                </DialogContent>
            )}

            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={handleCloseCopyDialog}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => handleCopy()}>
                    Copy
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CopyDialog;
