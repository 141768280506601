import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
} from '@mui/material';

import { EditDeviceDialog } from '../device';
import { FiMonitor } from 'react-icons/fi';
import { GrEdit } from 'react-icons/gr';
import React from 'react';
import { ScheduleList } from './';

const ScheduleListDialog = ({
    openScheduleListDialog,
    handleCloseScheduleListDialog,
    locationDetail,
    handleOpenEditDialog,
    openEditDialog,
    handleCloseEditDialog,
    deviceDetail,
    currentUser,
    locationList,
    triggerUpdate,
    setTriggerUpdate,
    colors,
    handleDragEnd,
    currentScheduleList,
    getValidLastTwoDigits,
    removeMeridian,
}) => {
    // const theme = useTheme();
    const locationInfo = locationDetail?.group_info;
    const deviceList = locationDetail?.assign_device_list;
    const onlineDeviceList = deviceList?.filter((x) => x.device_status === 'ONLINE');
    //console.log('DevicesDialog => onlineDeviceList:', onlineDeviceList);
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={openScheduleListDialog}
            onClose={handleCloseScheduleListDialog}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {locationInfo?.group_name +
                    ' - ' +
                    (locationInfo?.target_layout === 1 ? 'Landscape' : 'Portrait')}
            </DialogTitle>
            <DialogContent>
                <ScheduleList
                    // handleOpenScheduleDialog={handleOpenScheduleDialog}
                    // tab={tab}
                    colors={colors}
                    handleDragEnd={handleDragEnd}
                    currentScheduleList={currentScheduleList}
                    getValidLastTwoDigits={getValidLastTwoDigits}
                    removeMeridian={removeMeridian}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCloseScheduleListDialog} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleListDialog;
