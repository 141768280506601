import React, { useEffect, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import PropTypes from 'prop-types';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const RightCalendarSec = ({
    scheduleRows,
    currentScheduleList,
    handleDateClick,
    handleOpenScheduleDialog,
    colors,
    getValidLastTwoDigits,
    recurringByWeek,
    recurringByMonth,
    recurringByYear,
    tabValue,
}) => {
    return (
        <>
            {Array.isArray(scheduleRows) &&
                scheduleRows.map((tab, index) => {
                    const newList = currentScheduleList
                        ?.map((x, newListIndex) => {
                            const event = {
                                id: x.schedule_id,
                                title: x.schedule_name,
                                start: moment(x.start_date, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'),
                                end: moment(x.end_date, 'DD/MMM/YYYY hh:mmA')
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD'),
                                backgroundColor: colors.find(
                                    (c) => c.number === getValidLastTwoDigits(x.schedule_id),
                                ).color,
                                textColor: 'black',
                                priority: newListIndex,
                            };

                            if (x.schedule_frequency !== 'One Time Event') {
                                event.rrule = {
                                    freq: x.schedule_frequency.toLowerCase(),
                                    dtstart: moment(x.start_date, 'DD/MMM/YYYY hh:mmA').format('YYYY-MM-DD'),
                                    until: moment(x.until_date, 'DD/MMM/YYYY hh:mmA')
                                        .add(1, 'day')
                                        .format('YYYY-MM-DD'),
                                };
                                if (x.schedule_frequency.toLowerCase() === 'weekly') {
                                    event.rrule.byweekday = recurringByWeek(x.start_date, x.end_date);
                                }
                                if (x.schedule_frequency.toLowerCase() === 'monthly') {
                                    event.rrule.bymonthday = recurringByMonth(x.start_date, x.end_date);
                                }
                                if (x.schedule_frequency.toLowerCase() === 'yearly') {
                                    const years = recurringByYear(x.start_date, x.until_date);

                                    return years
                                        .map((year, index) => ({
                                            id: x.schedule_id + year,
                                            title: x.schedule_name,
                                            start: moment(x.start_date, 'DD/MMM/YYYY hh:mmA')
                                                .add(index, 'year')
                                                .format('YYYY-MM-DD'),
                                            end: moment(x.end_date, 'DD/MMM/YYYY hh:mmA')
                                                .add(index, 'year')
                                                .add(1, 'day')
                                                .format('YYYY-MM-DD'),
                                            backgroundColor: colors.find(
                                                (c) => c.number === getValidLastTwoDigits(x.schedule_id),
                                            ).color,
                                            textColor: 'black',
                                        }))
                                        .flat();
                                }
                            }

                            console.log('?.map => event:', event);
                            return event;
                        })
                        .flat();
                    return Array.isArray(tab.scheduleList) && tab.scheduleList.length > 0 ? (
                        <TabPanel value={tabValue} index={tab.group_id} key={tab.group_id}>
                            {/* CALENDAR */}
                            <div className="col-span-5 bg-white p-3 rounded-[20px]">
                                <FullCalendar
                                    height="80vh"
                                    plugins={[
                                        rrulePlugin,
                                        dayGridPlugin,
                                        timeGridPlugin,
                                        interactionPlugin,
                                        listPlugin,
                                    ]}
                                    headerToolbar={{
                                        left: 'prev next today',
                                        center: 'title',
                                        right: 'dayGridMonth,dayGridWeek,dayGridDay',
                                    }}
                                    // initialView="dayGridYear"
                                    selectable={true}
                                    selectMirror={true}
                                    select={handleDateClick}
                                    eventClick={(e) => handleOpenScheduleDialog(tab, e)}
                                    eventOrder="priority"
                                    events={newList}
                                />
                            </div>
                        </TabPanel>
                    ) : (
                        <TabPanel value={tabValue} index={tab.group_id} key={tab.group_id}>
                            {/* CALENDAR */}
                            <div className="col-span-5 bg-white p-3 rounded-[20px]">
                                <FullCalendar
                                    height="80vh"
                                    plugins={[
                                        rrulePlugin,
                                        dayGridPlugin,
                                        timeGridPlugin,
                                        interactionPlugin,
                                        listPlugin,
                                    ]}
                                    headerToolbar={{
                                        left: 'prev next today',
                                        center: 'title',
                                        right: 'dayGridMonth,dayGridWeek,dayGridDay',
                                    }}
                                    // initialView="dayGridYear"
                                    selectable={true}
                                    select={handleDateClick}
                                    eventClick={(e) => handleOpenScheduleDialog(tab, e)}
                                    eventOrder="priority"
                                    events={newList}
                                />
                            </div>
                        </TabPanel>
                    );
                })}
        </>
    );
};

export default RightCalendarSec;
