import { Box, Container } from '@mui/material';

import Error from '../../../data/notFound.svg';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';

const DashboardNotFound = () => {
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            <Container maxWidth="md">
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <img
                            alt="Under development"
                            src={Error}
                            style={{
                                marginTop: 50,
                                display: 'inline-block',
                                maxWidth: '100%',
                                width: 560,
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default DashboardNotFound;
