import axios from 'axios';
import config from '../../../config/config';
// const ImagetURL =
//   "https://jo28hkjks3.execute-api.ap-southeast-2.amazonaws.com/";
// const stage = "dev";

const ImageDevURL = config[process.env.REACT_APP_ENV].REACT_APP_IMAGE_API_URL;
//  //console.log('process.env.REACT_APP_ENV:', process.env.REACT_APP_ENV);
// const ImageDevURL = 'https://53bob3tfb9.execute-api.ap-southeast-2.amazonaws.com/dev';

export var Resize = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/resize',
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
export var SplitImage = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/split_image',
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var ImageStyle = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/image_variation',
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var CreateImages = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/create_image',
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
