import axios from 'axios';
import config from '../../../../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
const paymentURL = config[process.env.REACT_APP_ENV].REACT_APP_PAYMENT_API_URL;
const api_key = config[process.env.REACT_APP_ENV].REACT_APP_PAYMENT_API_KEY;
//const API_URL = ' https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/new/device';
//const body = ["userId""1"]
/**
 *
 * Upload file
 *
 */
//const body={userId:"1"}
//Upload file
export var Getalldevice = async (body) => {
    //const API_URL = ' https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/new/device';

    /**const data = await
        axios.post(API_URL, body)
         .then(res=>{
             //console.log('444');
          //console.log(res.data)

          }).catch(err =>{
             //console.log(err)
          })
          */

    let result = await axios({
        method: 'post',
        url: paymentURL + '/device_summary',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var Createcheckout = async (body) => {
    let result = await axios({
        method: 'post',
        url: paymentURL + '/stripe_create_checkout',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
/**
 *
 *
 *
 * {"user_id": 1,"old_point":0,"new_point":20,"reference":"top up test",
 * "device_summary":[{"id": 209, "new_start": "25/08/2023", "new_end": "25/08/2024"},
 * {"id": 210, "new_start": "25/08/2023", "new_end": "25/08/2024"}]}
 *
 *
 *
 */

// Add user_type
export var Paycyberpoint = async (body) => {
    let result = await axios({
        method: 'post',
        url: paymentURL + '/pay_cyberpoint',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetCyberpoint = async (userId) => {
    let result = await axios({
        method: 'get',
        url: paymentURL + '/pay_cyberpoint',
        params: { user_id: userId },
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            //console.log('file: Api.jsx:78 => response:', response);
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// export var PPcreateOrder = async (body) => {
//     let result = await axios({
//         method: 'post',
//         url: 'https://brou2t9fkk.execute-api.ap-southeast-2.amazonaws.com/dev/paypaltester',
//         data: body,
//     })
//         .then(function (response) {
//             return response.data;
//         })
//         .catch(function (response) {});

//     return result;
// };
export var GetProductPrice = async (body) => {
    let result = await axios({
        method: 'post',
        url: paymentURL + '/getproduct',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
export var GetPriceList = async (body) => {
    let result = await axios({
        method: 'get',
        url: paymentURL + '/pricelist',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetSubPriceList = async () => {
    let result = await axios({
        method: 'get',
        url: paymentURL + '/subscription_products',
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var CreateSubscription = async (body) => {
    let result = await axios({
        method: 'post',
        url: paymentURL + '/stripe_create_subscription',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetBillingPortal = async (body) => {
    let result = await axios({
        method: 'post',
        url: paymentURL + '/stripe_billing_portal',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var CheckPaymentMethod = async (body) => {
    let result = await axios({
        method: 'post',
        url: paymentURL + '/stripe_check_paymentmethod',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
