import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Autocomplete,
    Box,
    Button,
    Chip,
    LinearProgress,
    Slider,
    TextField,
    Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { TextImprove } from './Api';
import { useState } from 'react';

const tags = [
    'Business',
    'Marketing',
    'Technology',
    'Health',
    'Education',
    'Finance',
    'Travel',
    'Food',
    'Entertainment',
    'Sports',
    'Fashion',
    'Science',
    'Politics',
    'Environment',
    'Real Estate',
    'Automotive',
    'Gaming',
    'Music',
    'Lifestyle',
    'Photography',
    'History',
    'Law',
    'Medicine',
    'Art',
];

const TextEnhance = () => {
    const [inputValue, setInputValue] = useState('');
    const [processing, setProcessing] = useState(false);
    const [temperature, setTemperature] = useState(0); // Default temperature is 0
    const [responseList, setResponseList] = useState([]);
    const [warning, setWarning] = useState(false);
    const [error, setError] = useState(''); // State for error message
    const [selectedTags, setSelectedTags] = useState([]); // Default selected tags
    const user_id = JSON.parse(localStorage.getItem('userDetails'))?.user_id;

    const handleButtonClick = async () => {
        if (inputValue) {
            setProcessing(true);
            setWarning(false);
            setError(false);

            const body = {
                user_id: user_id,
                prompt: inputValue,
                temperature: temperature,
                tags: selectedTags,
            };

            try {
                const data = await TextImprove(body);
                //console.log('Input Value:', data['message']);
                setResponseList(data['message']);
            } catch (error) {
                console.error('Error while processing:', error);
                setError('System busy, please try again later.');
            } finally {
                setProcessing(false);
            }
        } else {
            setWarning(true);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                Enchant Your Readers with Beautiful Text
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your text here"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        setWarning(false);
                    }}
                    sx={{ my: 2 }}
                />
                {warning && (
                    <div>
                        <Alert severity="error">Invalid Input</Alert>
                    </div>
                )}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Optional Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Autocomplete
                            multiple
                            options={tags}
                            value={selectedTags}
                            onChange={(event, newValue) => {
                                setSelectedTags(newValue);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Industry"
                                    placeholder="Select tags"
                                />
                            )}
                            sx={{ my: 2 }}
                        />
                        <Box sx={{ my: 2, padding: '100px' }}>
                            <Typography gutterBottom>Character</Typography>
                            <Slider
                                value={temperature}
                                min={-0.5}
                                max={0.5}
                                step={0.1}
                                marks={[
                                    { value: -0.5, label: 'Conservative' },
                                    { value: 0, label: 'Neutral' },
                                    { value: 0.5, label: 'Creative' },
                                ]}
                                //valueLabelDisplay="auto"
                                onChange={(e, newValue) => setTemperature(newValue)}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Button variant="contained" color="primary" onClick={handleButtonClick} sx={{ mt: 2 }}>
                    Rephrase
                </Button>

                {processing ? (
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Box sx={{ mt: 4 }}>
                        {responseList.map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                    p: 2, // Padding inside the Box
                                    borderRadius: '8px', // Rounded corners
                                    boxShadow: 3, // Shadow for depth
                                    border: '1px solid #ddd', // Border color
                                    backgroundColor: '#f9f9f9', // Light background color
                                    maxWidth: '600px', // Limit the width
                                    margin: '0 auto', // Center horizontally
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '1rem', // Font size
                                        fontWeight: 'bold', // Font weight
                                        color: '#333', // Text color
                                        textAlign: 'center', // Center text alignment
                                    }}
                                >
                                    {item}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )}
                {error && <Alert severity="error">{error}</Alert>}
            </Box>
        </Box>
    );
};

export default TextEnhance;
