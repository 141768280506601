import { Box, Button, Grid } from '@mui/material';
import { CreateTemplateDialog, TemplateGrid } from '../components/dashboard/template';
import { GetTemplateElementTypeList, GetTemplateGroupList, GetTemplateList } from '../api/api';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '../components/dashboard/Tabs';

import { CampaignDesign } from '../components/dashboard/campaign';
import { Header } from '../components/dashboard';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../contexts/ContextProvider';

const Template = () => {
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    //console.log('file: Template.jsx:77 => switchUser:', switchUser);

    const { currentUser, templateElement, setTemplateElement } = useStateContext();
    //console.log('file: Template.jsx:65 => Template => templateElement:', templateElement);
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [templateGroup, setTemplateGroup] = useState([]);
    //console.log('file: Template.jsx:82 => Template => templateGroup:', templateGroup);
    const [favourite, setFavourite] = useState([]);
    const [general, setGeneral] = useState([]);
    const [custom, setCustom] = useState([]);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    //console.log('Template => activeTab:', activeTab);
    useEffect(() => {
        getTemplateGroup();
        getTemplateElement();
    }, []);

    const getTemplateGroup = async () => {
        try {
            const data = await GetTemplateGroupList();
            //console.log('getTemplateGroup => data:', data);
            setTemplateGroup(data?.data?.list?.list.reverse());
            setActiveTab(data?.data?.list?.list[0].group_name);
        } catch (error) {
            console.error('Error fetching template group:', error);
        }
    };

    const getTemplateElement = async () => {
        try {
            const data = await GetTemplateElementTypeList();
            setTemplateElement(data?.data?.list);
            localStorage.setItem('element_type', JSON.stringify(data?.data?.list));
        } catch (error) {
            console.error('Error fetching template element:', error);
        }
    };
    const getTemplateList = async (group_type) => {
        const data = await GetTemplateList(switchUser, group_type, undefined, 100);
        //console.log('file: Template.jsx:109 => getTemplateList => data:', data);
        //console.log('GetTemplateList', data.data.list);
        group_type === 0 && data.data.list && setFavourite(data.data.list.reverse());
        group_type === 1 && data.data.list && setGeneral(data.data.list.reverse());
        group_type === 2 && data.data.list && setCustom(data.data.list.reverse());
    };

    // Handle Expand area
    const [expanded, setExpanded] = useState('');

    useEffect(() => {
        switch (activeTab) {
            case 'Favourite Templates':
                getTemplateList(0);
            case 'General Templates':
                getTemplateList(1);
            case 'Custom Templates':
                getTemplateList(2);
        }

        getTemplateList(expanded.group_type);
        //console.log('file: Template.jsx:145 => useEffect => expanded.group_type:', expanded.group_type);
        //console.log('Trigger update ');
    }, [triggerUpdate, activeTab]);

    const [showCampaignDesign, setShowCampaignDesign] = useState();

    //handle Open Create template Dialog
    const [openCreateTemplateDialog, setOpenCreateopenCreateTemplateDialog] = useState(false);
    const [currentItem, setCurrentItem] = useState();
    const handleOpenCreateTemplateDialog = (item) => {
        setCurrentItem(item);
        setOpenCreateopenCreateTemplateDialog(true);
    };
    const handleCloseCreateTemplateDialog = () => {
        setOpenCreateopenCreateTemplateDialog(false);
    };

    const [showEditCampaign, setShowEditCampaign] = useState();

    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            <div>
                <Header title="Choose Template" />
                <Tabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    // count={
                    //     activeTab.toLowerCase() === 'locations'
                    //         ? counts.device_group_num
                    //         : counts.device_num
                    // }
                >
                    {templateGroup?.length >= 1 &&
                        templateGroup.map((item, index) => (
                            <Tab label={item?.group_name}>
                                {((currentUser.user_level === 0 &&
                                    (item.group_type === 1 || item.group_type === 2) &&
                                    !switchUser) ||
                                    (currentUser.user_level !== 0 && item.group_type === 2)) && (
                                    <div className="mb-8">
                                        <div className="flex justify-center">
                                            <Button
                                                variant="contained"
                                                onClick={() => handleOpenCreateTemplateDialog(item)}
                                            >
                                                Create Template
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid
                                        className="w-full m-0"
                                        container
                                        spacing={{ xs: 1, md: 2 }}
                                        columns={{
                                            xs: 4,
                                            sm: 8,
                                            md: 8,
                                            lg: 12,
                                        }}
                                    >
                                        {item.group_type === 0 &&
                                            favourite.length > 0 &&
                                            favourite.map((template, index) => (
                                                <TemplateGrid
                                                    key={template + index}
                                                    item={item}
                                                    template={template}
                                                    currentUser={currentUser}
                                                    triggerUpdate={triggerUpdate}
                                                    setTriggerUpdate={setTriggerUpdate}
                                                    // setExpanded={setExpanded}
                                                    setShowCampaignDesign={setShowCampaignDesign}
                                                />
                                            ))}
                                        {item.group_type === 1 &&
                                            general.length > 0 &&
                                            general.map((template, index) => (
                                                <TemplateGrid
                                                    key={template + index}
                                                    item={item}
                                                    template={template}
                                                    currentUser={currentUser}
                                                    triggerUpdate={triggerUpdate}
                                                    setTriggerUpdate={setTriggerUpdate}
                                                    // setExpanded={setExpanded}
                                                    setShowCampaignDesign={setShowCampaignDesign}
                                                />
                                            ))}
                                        {item.group_type === 2 &&
                                            custom.length > 0 &&
                                            custom.map((template, index) => (
                                                <TemplateGrid
                                                    key={template + index}
                                                    item={item}
                                                    template={template}
                                                    triggerUpdate={triggerUpdate}
                                                    setTriggerUpdate={setTriggerUpdate}
                                                    currentUser={currentUser}
                                                    // setExpanded={setExpanded}
                                                    setShowCampaignDesign={setShowCampaignDesign}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            </Tab>
                        ))}
                </Tabs>
            </div>
            {/* {(showCampaignDesign || showEditCampaign) && (
                <CampaignDesign showCampaignDesign={showCampaignDesign} currentUser={currentUser} />
            )} */}
            {openCreateTemplateDialog && (
                <CreateTemplateDialog
                    item={currentItem}
                    openCreateTemplateDialog={openCreateTemplateDialog}
                    handleCloseCreateTemplateDialog={handleCloseCreateTemplateDialog}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                    currentUser={currentUser}
                />
            )}
        </div>
    );
};

export default Template;
