import React, { useEffect, useState } from 'react';

import Frame2 from '../../images/frame2.png';
import Frame4 from '../../images/frame4.png';
import Overlay1 from '../../images/featureOverlay1.png';
import Overlay2 from '../../images/featureOverlay2.png';
import Overlay3 from '../../images/featureOverlay3.png';
import Overlay4 from '../../images/featureOverlay4.png';
import loginBg1 from '../../images/loginBg1.png';
import loginBg3 from '../../images/loginBg3.png';

const bg1 = {
    image: loginBg3,
    title: 'Digital signage made easy',
    description:
        'The Cybercast platform supplies businesses with both the hardware and software solutions',
};
const bg2 = {
    image: Frame2,
    title: 'Centralised Control',
    description:
        'Broadcast content to multiple screens across numerous locations, all from a single device. ',
};
const bg3 = {
    image: loginBg1,
    title: 'Schedule Ahead',
    description:
        'Update and schedule your content to a single or group of screens anywhere, anytime.',
};
const bg4 = {
    image: Frame4,
    title: 'Simple Media Manager',
    description: 'Upload and manage all of your media within a streamlined library',
};

const bgList = [bg1, bg2, bg3, bg4];

// Dot Component
const Dot = ({ isActive }) => {
    return (
        <div
            style={{
                width: '7px',
                height: '7px',
                borderRadius: '100%',
                backgroundColor: isActive ? '#01B5E2' : 'lightblue',
                marginRight: '4px', // For spacing between dots
            }}
        />
    );
};
// Parent Component
class DotsContainer extends React.Component {
    render() {
        // Array representing the five dots
        const dots = [0, 1, 2, 3];
        const { activeIndex } = this.props; // Accessing activeIndex from props

        return (
            <div className="flex z-60">
                {dots.map((_, index) => (
                    <div key={index}>
                        <Dot isActive={activeIndex === index} />
                    </div>
                ))}
            </div>
        );
    }
}

function FeaturesSlider() {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Define the function to go to the next testimonial
        const nextBg = () => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % bgList.length);
        };

        // Set up an interval to change the testimonial every few seconds
        const intervalId = setInterval(nextBg, 4000); // Change every 4 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const activeBg = bgList[activeIndex];

    return (
        <div className="relative">
            <div className="max-w-5xl">
                <div className="w-full mx-auto pt-25 pb-25">
                    <h3 className="font-display font-bold text-4xl mb-4 w-1/2">{activeBg.title}</h3>
                    <h6 className="w-1/2 mb-4">{activeBg.description}</h6>
                    <DotsContainer activeIndex={activeIndex} />
                </div>
                <div className="max-w-6xl mx-auto">
                    <img src={activeBg.image} />
                </div>
            </div>

            {activeIndex === 1 ? (
                <img
                    src={Overlay1}
                    alt="overlay image"
                    className="absolute z-50"
                    style={{
                        width: '243px',
                        maxWidth: 'none', // Override max-width
                        height: '243px',
                        top: '55%', // Adjust as needed
                        left: '2%', // Center the image horizontally
                        transform: 'translate(-50%, -50%)',
                        rounded: '10px',
                        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                    }}
                />
            ) : null}
            {activeIndex === 2 ? (
                <img
                    src={Overlay2}
                    alt="overlay image"
                    className="absolute z-50"
                    style={{
                        width: '217px',
                        maxWidth: 'none', // Override max-width
                        height: '217px',
                        top: '40%', // Adjust as needed
                        left: '75%', // Center the image horizontally
                        transform: 'translate(-50%, -50%)',
                        rounded: '10px',
                        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                    }}
                />
            ) : null}

            {activeIndex === 2 ? (
                <img
                    src={Overlay3}
                    alt="overlay image"
                    className="absolute z-50"
                    style={{
                        width: '326px',
                        maxWidth: 'none', // Override max-width
                        height: '217px',
                        top: '70%', // Adjust as needed
                        left: '90%', // Center the image horizontally
                        transform: 'translate(-50%, -50%)',
                        rounded: '10px',
                        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                    }}
                />
            ) : null}
            {activeIndex === 3 ? (
                <img
                    src={Overlay4}
                    alt="overlay image"
                    className="absolute z-50"
                    style={{
                        width: '438px',
                        maxWidth: 'none', // Override max-width
                        height: '243px',
                        top: '40%', // Adjust as needed
                        left: '85%', // Center the image horizontally
                        transform: 'translate(-50%, -50%)',
                        rounded: '10px',
                        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                    }}
                />
            ) : null}
        </div>
    );
}

export default FeaturesSlider;
