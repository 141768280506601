import React, { useEffect, useState } from 'react';

import LoginBg1 from '../../images/loginBg1.png';
import LoginBg2 from '../../images/loginBg2.png';
import LoginBg3 from '../../images/loginBg3.png';
import LoginBg4 from '../../images/loginBg4.png';
import LoginBg5 from '../../images/loginBg5.png';
import Vector1 from '../../images/Vector1.png';
import Vector2 from '../../images/Vector2.png';
import Vector3 from '../../images/Vector3.png';
import Vector4 from '../../images/Vector4.png';
import Vector5 from '../../images/Vector5.png';
import logo2 from '../../images/logo2.png';
import { useNavigate } from 'react-router-dom';

const bg1 = {
    image: LoginBg1,
    title: 'Digital signage made easy',
    description:
        'The Cybercast platform supplies businesses with both the hardware and software solutions',
    vector: Vector1,
    scale: 0.9,
};
// const bg2 =
// {
//     image: LoginBg2,
//     title: 'Centralised Control',
//     description: 'Broadcast content to multiple screens across numerous locations, all from a single device. ',
//     vector: Vector2,
//     scale: 1,

// }
const bg3 = {
    image: LoginBg3,
    title: 'Schedule Ahead',
    description:
        'Update and schedule your content to a single or group of screens anywhere, anytime.',
    vector: Vector3,
    scale: 0.9,
};
const bg4 = {
    image: LoginBg4,
    title: 'Simple Media Manager',
    description: 'Upload and manage all of your media within a streamlined library',
    vector: Vector4,
    scale: 0.96,
};
const bg5 = {
    image: LoginBg5,
    title: 'Create Content Your Way',
    description:
        'Showcase your content exactly how you want to, with templates for all screens and dimensions.',
    vector: Vector5,
    scale: 0.91,
};

const bgList = [bg1, bg3, bg4, bg5];

// Dot Component
const Dot = ({ isActive }) => {
    return (
        <div
            style={{
                width: '7px',
                height: '7px',
                borderRadius: '100%',
                backgroundColor: isActive ? 'white' : 'lightblue',
                margin: '2px', // For spacing between dots
            }}
        />
    );
};
// Parent Component
class DotsContainer extends React.Component {
    render() {
        // Array representing the five dots
        const dots = [0, 1, 2, 3];
        const { activeIndex } = this.props; // Accessing activeIndex from props

        return (
            <div className="flex z-60">
                {dots.map((_, index) => (
                    <div key={index}>
                        <Dot isActive={activeIndex === index} />
                    </div>
                ))}
            </div>
        );
    }
}

function LoginComponent() {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Define the function to go to the next testimonial
        const nextBg = () => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % bgList.length);
        };

        // Set up an interval to change the testimonial every few seconds
        const intervalId = setInterval(nextBg, 5000); // Change every 5 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const activeBg = bgList[activeIndex];

    let navigate = useNavigate();
    function handleClick() {
        navigate('/');
    }
    return (
        <div className="hidden md:block md:w-1/2 md:h-full bg-customPrimary relative overflow-hidden">
            <div className="w-full mr-auto pl-6 pt-2 ml-4">
                <button type="button" onClick={handleClick}>
                    <img src={logo2} className="mr-auto w-40" />
                </button>

                <h3 className="font-display font-bold text-white text-4xl mb-3 w-2/3">
                    {activeBg.title}
                </h3>
                <div className="h-16 flex items-center ">
                    <h6 className="text-white w-3/4 mb-4">{activeBg.description}</h6>
                </div>
                <DotsContainer activeIndex={activeIndex} />
            </div>
            {/* <img src={activeBg.vector} className='absolute Z-10' style={{
                bottom: -30, 
                left: 0, 
                maxWidth: '85%',
            }} /> */}
            <img
                src={Vector3}
                className="absolute Z-10 animate-spin-slow"
                style={{
                    bottom: -50,
                    left: 0,
                    maxWidth: '80%',
                }}
            />
            <img
                src={activeBg.image}
                className=" absolute z-50"
                style={{
                    bottom: 0,
                    right: 0,
                    transform: `scale(${activeBg.scale})`,
                    transformOrigin: 'right bottom', // scale from the bottom right corner
                }}
            />
        </div>
    );
}

export default LoginComponent;
