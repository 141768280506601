import React from 'react';

function Test4Icon() {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <g clipPath="url(#clip0_893_773)">
    <path d="M4 1.8335L2 4.50016V13.8335C2 14.1871 2.14048 14.5263 2.39052 14.7763C2.64057 15.0264 2.97971 15.1668 3.33333 15.1668H12.6667C13.0203 15.1668 13.3594 15.0264 13.6095 14.7763C13.8595 14.5263 14 14.1871 14 13.8335V4.50016L12 1.8335H4Z" stroke="white" strokeWidthh="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 4.5H14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6663 7.1665C10.6663 7.87375 10.3854 8.55203 9.88529 9.05212C9.3852 9.55222 8.70692 9.83317 7.99967 9.83317C7.29243 9.83317 6.61415 9.55222 6.11406 9.05212C5.61396 8.55203 5.33301 7.87375 5.33301 7.1665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_893_773">
      <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>
  )}

export default Test4Icon;