import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';

import { UpdateMediaFolder } from '../../../api/api';

const EditFolderNameDialog = ({
    currentUser,
    switchUser,
    openEditDialog,
    handleCloseEditDialog,
    triggerUpdate,
    setTriggerUpdate,
    currentFolder,
}) => {
    const [changedFolderName, setChangedFolderName] = useState(currentFolder.folder_name);
    const handleSaveFolderName = async () => {
        const data = await UpdateMediaFolder(switchUser, currentFolder.folder_id, changedFolderName);
        //console.log('data:', data);
        if (data.code === 0) {
            handleCloseEditDialog();
            setTriggerUpdate(!triggerUpdate);
        }
    };

    return (
        <Dialog open={openEditDialog} PaperProps={{ sx: { width: '45%' } }}>
            <DialogTitle sx={{ fontSize: '16px', fontWeight: 'bold' }}>Edit folder Name</DialogTitle>

            <DialogContent>
                <div className="flex w-full justify-center pt-4">
                    <TextField
                        sx={{
                            margin: '2px',
                            padding: '0',
                            marginRight: '8px',
                        }}
                        fullWidth
                        placeholder="Folder Name"
                        margin="normal"
                        name="folder_name"
                        onChange={(e) => setChangedFolderName(e.target.value)}
                        value={changedFolderName}
                        variant="outlined"
                    />
                </div>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={handleCloseEditDialog}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => handleSaveFolderName()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFolderNameDialog;
