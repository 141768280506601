import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import MenuBlog1 from '../../images/menuBlog1.png';
import MenuBlog2 from '../../images/menuBlog2.png';
import MenuBlog3 from '../../images/menuBlog3.png';
import MenuBlog4 from '../../images/menuBlog4.png';
import React from 'react';
import RoundArrowButton from '../../images/RoundArrowButton.svg';
import Shop from '../../images/submenushop.png';
import menuIcon1 from '../../images/menuIcon1.png';
import menuIcon2 from '../../images/menuIcon2.png';
import menuIcon3 from '../../images/menuIcon3.png';
import menuIcon4 from '../../images/menuIcon4.png';

const SubMenu = ({ show, onMouseEnter, onMouseLeave }) => {
    return show === 'service' ? (
        <div
            className="absolute left-0 w-full border shadow justify-center items-center z-50"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="flex w-full">
                {/* left column */}
                <div className="w-full bg-white flex justify-start px-12 py-3 flex-col">
                    <p className="text-sm text-slate-500 font-light pl-4">SERVICE</p>
                    <div className="w-full grid grid-cols-4 ">
                        <Link
                            to="/"
                            className="flex w-full p-2 text-black  items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon1} />
                            <div className="ml-2 ">
                                <h5 className=" font-display font-bold capitalize text-black ">service 1</h5>
                                {/* <p className="text-xs font-light">See all features</p> */}
                            </div>
                        </Link>

                        <Link
                            to="/"
                            className="flex  w-full p-2 text-black items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon2} />
                            <div className="ml-2 ">
                                <h5 className="font-display font-bold capitalize ">service 2</h5>
                                {/* <p className="text-xs font-light">Explore our range </p> */}
                            </div>
                        </Link>

                        <Link
                            to="/"
                            className="flex  w-full p-2 text-black  items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12 " src={menuIcon3} />
                            <div className="ml-2 ">
                                <h5 className=" font-display text-black font-bold capitalize ">service 3</h5>
                                {/* <p className=" text-xs font-light">User Guides & FAQs </p> */}
                            </div>
                        </Link>
                        <Link
                            to="/"
                            className="flex  w-full p-2 text-black  items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon1} />
                            <div className="ml-2 text-black ">
                                <h5 className="font-display font-bold capitalize ">service 4</h5>
                                {/* <p className="text-xs font-light">Signage Made easy</p> */}
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div
            className="absolute left-0 w-full border shadow justify-center items-center z-50"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="flex w-full">
                {/* left column */}
                <div className="w-full bg-white px-12 py-3 flex flex-col">
                    <p className="text-sm text-slate-500 font-light pl-4">CASE STUDIES</p>
                    <div className="mb-3  grid grid-cols-4 ">
                        {/* <div className="flex flex-row"> */}
                        <Link
                            to="/resources/top1"
                            className="flex items-center text-black"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon1} />
                            <div className="ml-2">
                                <h5 className=" font-display font-bold capitalize">case 1</h5>
                                {/* <p className="text-xs font-light">Independent Boys School</p> */}
                            </div>
                        </Link>
                        <Link
                            to="/resources/top2"
                            className="flex items-center text-black"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon2} />
                            <div className="ml-2">
                                <h5 className="font-display font-bold capitalize">case 2</h5>
                                {/* <p className="text-xs font-light">Private Hospital Group </p> */}
                            </div>
                        </Link>
                        <Link
                            to="/resources/top3"
                            className="flex items-center text-black"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12 " src={menuIcon3} />
                            <div className="ml-2">
                                <h5 className=" font-display font-bold capitalize">case 3</h5>
                                {/* <p className=" text-xs font-light">Japanese Restaurant Chain </p> */}
                            </div>
                        </Link>
                        <Link
                            to="/resources/top4"
                            className="flex items-center text-black"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12 " src={menuIcon4} />
                            <div className="ml-2">
                                <h5 className=" font-display font-bold capitalize">case 4</h5>
                                {/* <p className=" text-xs font-light">Hardware Retail Store Chain</p> */}
                            </div>
                        </Link>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubMenu;
