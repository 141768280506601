import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

import { DenseInput } from '../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';

const style = {
    labelText: {
        minWidth: '200px',
        display: 'flex',
        // textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
    },
    label: {
        minWidth: '150px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    button: {
        textTransform: 'none',
        minWidth: '100px',
    },
};

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        '&:before': {
            display: 'none',
        },

        // backgroundColor: '#0444AB',
    }),
);

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    marginTop: '10px',
    marginBottom: '10px',
    padding: '0px',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    // '& .MuiAccordionSummary-content': {
    //     marginLeft: theme.spacing(1),
    // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0px',
}));

const DenseSelect = styled(Select)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        // border: 'none',
        position: 'relative',
        height: '20px',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 14,
        padding: '4px 8px',
        minWidth: '70px',
    },
    '& fieldset': { border: 'none' },
}));

const EditElement = ({
    widgetArray,
    editElementDialog,
    handleCloseEdit,
    currentElement,
    targetLayout,
    editedElement,
    setEditedElement,
    handleModify,
    elementTagList,
}) => {
    //console.log('file: EditElement.jsx:136 => currentElement:', currentElement);
    //console.log('file: EditElement.jsx:136 => widgetArray:', widgetArray);
    //templateElement,
    const templateElement = JSON.parse(localStorage.getItem('element_type'));

    const userDetail = JSON.parse(localStorage.getItem('userDetails'));
    //console.log('file: EditElement.jsx:141 => templateElement:', templateElement);
    const formik = useFormik({
        initialValues: {
            element_type: currentElement?.element_type ? currentElement?.element_type : 'image',
            position_x: currentElement?.element_x,
            position_y: currentElement?.element_y,
            width: currentElement?.element_width,
            height: currentElement?.element_height,
            layer: currentElement?.element_layer,
            tagName: currentElement?.element_layer.toString(),
            element_effect: currentElement?.element_effect ? currentElement?.element_effect : 'show',
            actions: currentElement?.element_tag_event_list
                ? typeof currentElement?.element_tag_event_list === 'string'
                    ? JSON.parse(currentElement?.element_tag_event_list).map((x) => ({
                          tagEffect: x.tag_effect,
                          controlTags: JSON.parse(x.control_tags),
                          tagUrl: x.jump_url,
                      }))
                    : [
                          {
                              tagEffect: '',
                              tagUrl: '',
                              controlTags: [],
                          },
                      ]
                : [
                      {
                          tagEffect: '',
                          tagUrl: '',
                          controlTags: [],
                      },
                  ],
        },
        enableReinitialize: true,
        // validationSchema: Yup.object({}),
        onSubmit: (values) => {
            const betweenX = (position) => {
                if (position < 0) return 0;
                if (targetLayout === 0) {
                    if (position > 1920 - currentElement?.element_width) {
                        return 1920 - currentElement?.element_width;
                    } else {
                        return position;
                    }
                }
                if (targetLayout === 1) {
                    if (position > 1080 - currentElement?.element_width) {
                        return 1080 - currentElement?.element_width;
                    } else {
                        return position;
                    }
                }
            };
            const betweenY = (position) => {
                if (position < 0) return 0;
                if (targetLayout === 0) {
                    if (position > 1080 - currentElement?.element_height) {
                        return 1080 - currentElement?.element_height;
                    } else {
                        return position;
                    }
                }
                if (targetLayout === 1) {
                    if (position > 1920 - currentElement?.element_height) {
                        return 1920 - currentElement?.element_height;
                    } else {
                        return position;
                    }
                }
            };
            const {
                // element_alias,
                element_type,
                position_x,
                position_y,
                width,
                height,
                layer,
                actions,
                tagName,
                element_effect,
            } = values;
            //console.log('actions:', actions);
            const data = {
                element_alias: currentElement.element_alias,
                element_type: element_type,
                element_x: betweenX(parseInt(position_x)),
                element_y: betweenY(parseInt(position_y)),
                element_width: maxWidth(parseInt(width)),
                element_height: maxHeight(parseInt(height)),
                element_layer: parseInt(layer),
                element_effect: element_effect,
                element_tag: element_type === 'text' || element_type === 'image' ? tagName.toString() : '',
                element_tag_event_list:
                    // actions[0].controlTags.length > 0 ?
                    JSON.stringify(
                        actions.map((x) => ({
                            tag_effect: x.tagEffect,
                            control_tags: JSON.stringify(x.controlTags),
                            jump_url: x.tagUrl,
                        })),
                    ),
                // : null,
            };
            //console.log('data:', data);
            setEditedElement(data);
            // handleModify(undefined, 'edit');
            handleCloseEdit();
        },
    });
    const maxWidth = (width) => {
        if (width < 0) return 0;
        if (targetLayout === 0) {
            if (width > 1920) {
                return 1920;
            } else {
                return width;
            }
        } else {
            if (width > 1080) {
                return 1080;
            } else {
                return width;
            }
        }
    };
    const maxHeight = (height) => {
        if (height < 0) return 0;
        if (targetLayout !== 0) {
            if (height > 1920) {
                return 1920;
            } else {
                return height;
            }
        } else {
            if (height > 1080) {
                return 1080;
            } else {
                return height;
            }
        }
    };
    // Action field
    // const handleAddAction = () => {
    //     formik.setFieldValue('actions', [
    //         ...formik.values.actions,
    //         { tagName: '', controlTags: '', tagEffect: '', tagUrl: '' },
    //     ]);
    // };
    const handleRemoveAction = (indexToRemove) => {
        if (formik.values.actions.length === 1) {
            // If only one action is present, don't remove it
            formik.setFieldValue('actions', [{ tagName: '', controlTags: '', tagEffect: '', tagUrl: '' }]);
            return;
        }

        const updatedActions = formik.values.actions.filter((_, index) => index !== indexToRemove);
        formik.setFieldValue('actions', updatedActions);
    };
    // formik.values.element_type;
    //console.log('file: EditElement.jsx:232 => formik.values.controlTags:', formik.values);

    const filteredElement = templateElement.filter((x) => {
        const baseCondition =
            x.element_type !== 'audio' && x.element_type !== 'document' && x.element_type !== 'text';
        if (userDetail.customer_type === 1) {
            return baseCondition && x.element_type !== 'web';
        }
        return baseCondition;
    });
    //console.log('filteredElement:', filteredElement);
    return (
        <Dialog
            open={editElementDialog}
            onClose={handleCloseEdit}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">Edit element</DialogTitle>
            <DialogContent>
                {filteredElement && (
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="element_type" sx={style.labelText}>
                                Select element type
                            </FormLabel>
                            <DenseInput
                                error={Boolean(formik.touched.element_type && formik.errors.element_type)}
                                fullWidth
                                select
                                helperText={formik.touched.element_type && formik.errors.element_type}
                                margin="normal"
                                aria-labelledby="element_type"
                                name="element_type"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.element_type}
                                variant="outlined"
                            >
                                {filteredElement?.map((x) => (
                                    <MenuItem value={x.element_type} key={x + x.element_type}>
                                        {x.element_type === 'mix' ? 'Image & video' : x.element_type_name}
                                    </MenuItem>
                                ))}
                            </DenseInput>
                        </FormControl>
                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="layer" sx={style.labelText}>
                                Layer
                            </FormLabel>
                            <DenseInput
                                error={Boolean(formik.touched.layer && formik.errors.layer)}
                                fullWidth
                                type="number"
                                helperText={formik.touched.layer && formik.errors.layer}
                                margin="normal"
                                aria-labelledby="layer"
                                name="layer"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.layer}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            error={Boolean(formik.touched.element_effect && formik.errors.element_effect)}
                        >
                            <FormLabel id="element-effect-label" sx={style.labelText}>
                                Element Effect
                            </FormLabel>
                            <DenseInput
                                labelId="element-effect-label"
                                error={Boolean(formik.touched.layer && formik.errors.layer)}
                                fullWidth
                                select
                                helperText={formik.touched.layer && formik.errors.layer}
                                margin="normal"
                                aria-labelledby="element_effect"
                                name="element_effect"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.element_effect}
                                variant="outlined"
                            >
                                <MenuItem value="show">Show</MenuItem>
                                <MenuItem value="hide">Hide</MenuItem>
                            </DenseInput>
                        </FormControl>
                        {(formik.values.element_type === 'image' || formik.values.element_type === 'text') &&
                            widgetArray.length > 1 && (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Action settings</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="h-[200px] overflow-y-scroll mb-2 px-2">
                                            <div className="px-4 py-2 rounded">
                                                <FormControl
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <FormLabel id="tag" sx={style.labelText}>
                                                        Tag name
                                                    </FormLabel>
                                                    <DenseInput
                                                        error={Boolean(
                                                            formik.touched.tagName && formik.errors.tagName,
                                                        )}
                                                        fullWidth
                                                        type="text"
                                                        helperText={
                                                            formik.touched.tagName && formik.errors.tagName
                                                        }
                                                        margin="normal"
                                                        aria-labelledby="tagName"
                                                        name="tagName"
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tagName}
                                                        variant="outlined"
                                                        disabled
                                                    />
                                                </FormControl>
                                                {formik.values.actions.map((action, index) => (
                                                    <div
                                                        key={`actions-${index}`}
                                                        // className="bg-gray-200 px-4 py-2 rounded mb-2"
                                                    >
                                                        {/* <div className="flex justify-between">
                                                        <Typography>Action</Typography>
                                                        {index > 0 && (
                                                        <Tooltip>
                                                            <IconButton
                                                                onClick={() => handleRemoveAction(index)}
                                                            >
                                                                <GrClose size={14} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        )}
                                                    </div> */}
                                                        <FormControl
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <FormLabel
                                                                id={`controlTag-${index}`}
                                                                sx={style.labelText}
                                                            >
                                                                Control tag(s)
                                                            </FormLabel>
                                                            <DenseSelect
                                                                // select
                                                                error={Boolean(
                                                                    formik.touched.actions?.[index]
                                                                        ?.controlTags &&
                                                                        formik.errors.actions?.[index]
                                                                            ?.controlTags,
                                                                )}
                                                                fullWidth
                                                                multiple
                                                                // type="text"
                                                                helperText={
                                                                    formik.touched.actions?.[index]
                                                                        ?.controlTags &&
                                                                    formik.errors.actions?.[index]
                                                                        ?.controlTags
                                                                }
                                                                margin="normal"
                                                                aria-labelledby={`controlTags-${index}`}
                                                                name={`actions[${index}].controlTags`}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                value={
                                                                    formik.values.actions?.[index]
                                                                        ?.controlTags
                                                                }
                                                                variant="outlined"
                                                            >
                                                                {widgetArray.map((x) => (
                                                                    <MenuItem
                                                                        value={x.element_layer}
                                                                        key={x.element_alias}
                                                                    >
                                                                        <em>{x.element_layer}</em>
                                                                    </MenuItem>
                                                                ))}
                                                            </DenseSelect>
                                                        </FormControl>

                                                        <FormControl
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {/* tagEffect */}
                                                            <FormLabel
                                                                id={`tagEffect-${index}`}
                                                                sx={style.labelText}
                                                            >
                                                                Action effect
                                                            </FormLabel>
                                                            <DenseInput
                                                                select
                                                                error={Boolean(
                                                                    formik.touched.actions?.[index]
                                                                        ?.tagEffect &&
                                                                        formik.errors.actions?.[index]
                                                                            ?.tagEffect,
                                                                )}
                                                                fullWidth
                                                                type="text"
                                                                helperText={
                                                                    formik.touched.actions?.[index]
                                                                        ?.tagEffect &&
                                                                    formik.errors.actions?.[index]?.tagEffect
                                                                }
                                                                margin="normal"
                                                                aria-labelledby={`tagEffect-${index}`}
                                                                name={`actions[${index}].tagEffect`}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                value={
                                                                    formik.values.actions?.[index]?.tagEffect
                                                                }
                                                                variant="outlined"
                                                            >
                                                                {elementTagList.map((x) => (
                                                                    <MenuItem
                                                                        value={x.tag_effect}
                                                                        key={x.tag_effect}
                                                                    >
                                                                        <em>{x.tag_effect_name}</em>
                                                                    </MenuItem>
                                                                ))}
                                                            </DenseInput>
                                                        </FormControl>
                                                        {formik.values.actions?.[index]?.tagEffect ===
                                                            'url' && (
                                                            <FormControl
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <FormLabel
                                                                    id={`tagUrl-${index}`}
                                                                    sx={style.labelText}
                                                                >
                                                                    Url
                                                                </FormLabel>
                                                                <DenseInput
                                                                    error={Boolean(
                                                                        formik.touched.actions?.[index]
                                                                            ?.tagUrl &&
                                                                            formik.errors.actions?.[index]
                                                                                ?.tagUrl,
                                                                    )}
                                                                    fullWidth
                                                                    type="text"
                                                                    helperText={
                                                                        formik.touched.actions?.[index]
                                                                            ?.tagUrl &&
                                                                        formik.errors.actions?.[index]?.tagUrl
                                                                    }
                                                                    margin="normal"
                                                                    aria-labelledby={`tagUrl-${index}`}
                                                                    name={`actions[${index}].tagUrl`}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    value={
                                                                        formik.values.actions?.[index]?.tagUrl
                                                                    }
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        )}
                                                    </div>
                                                ))}
                                                {/* {((formik.values.actions?.[0]?.controlTags &&
                                            formik.values.actions?.[0]?.tagEffect) ||
                                            formik.values.actions?.length > 1) && (
                                            <div className="w-full flex justify-center ">
                                                <Button variant="contained" onClick={() => handleAddAction()}>
                                                    <span className="text-lg">Add a new action</span>
                                                </Button>
                                            </div>
                                        )} */}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='position_x' sx={style.labelText}>
								Position X
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.position_x && formik.errors.position_x)}
								fullWidth
								type='number'
								helperText={formik.touched.position_x && formik.errors.position_x}
								margin='normal'
								aria-labelledby='position_x'
								name='position_x'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.position_x}
								variant='outlined'
							/>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='position_y' sx={style.labelText}>
								Position Y
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.position_y && formik.errors.position_y)}
								fullWidth
								type='number'
								helperText={formik.touched.position_y && formik.errors.position_y}
								margin='normal'
								aria-labelledby='position_y'
								name='position_y'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.position_y}
								variant='outlined'
							/>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='width' sx={style.labelText}>
								Width
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.width && formik.errors.width)}
								fullWidth
								type='number'
								helperText={formik.touched.width && formik.errors.width}
								margin='normal'
								aria-labelledby='width'
								name='width'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={maxWidth(parseInt(formik.values.width))}
								variant='outlined'
							/>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='height' sx={style.labelText}>
								Height
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.height && formik.errors.height)}
								fullWidth
								type='number'
								helperText={formik.touched.height && formik.errors.height}
								margin='normal'
								aria-labelledby='height'
								name='height'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={maxHeight(parseInt(formik.values.height))}
								variant='outlined'
							/>
						</FormControl> */}

                        <DialogActions>
                            <Button sx={style.button} onClick={handleCloseEdit} variant="contained">
                                Cancel
                            </Button>
                            <Button
                                sx={style.button}
                                // onClick={handleCloseEditDialog}
                                autoFocus
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditElement;
