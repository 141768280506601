import * as Yup from 'yup';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    Typography,
    Grid,
    Divider,
} from '@mui/material';
import { GetCountryCodeList, RequestRegister } from '../api/api';
import { Iconify, LoginComponent, RedditTextField } from '../components/dashboard';
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
// import SignUp from '../images/SignUp0.png';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../contexts/ContextProvider';

const Register = () => {
    const notify = useNotification();
    const navigate = useNavigate();
    const [isSignedUp, setIsSignedUp] = useState(false);

    const [countryCode, setCountryCode] = useState([]);

    const handleLogOut = () => {
        const clientId = '7rlmh76qejio3q1mmh4livh75t';
        const cognitoDomain = 'https://signinspiregoogle.auth.ap-southeast-2.amazoncognito.com';
        const redirectUri = 'http://localhost:3000/login'; // Redirect back here to initiate login

        // Construct the Cognito logout URL
        const cognitoLogoutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;

        // Navigate to the logout URL to clear the session
        window.location.href = cognitoLogoutUrl;
    };

    const handleGoogleSignup = () => {
        const clientId = '7rlmh76qejio3q1mmh4livh75t';
        const redirectUri = 'http://localhost:3000/redirect.html'; // Local redirect URI for testing
        const cognitoDomain = 'https://signinspiregoogle.auth.ap-southeast-2.amazoncognito.com';
        const responseType = 'token';
        const identityProvider = 'Google';
        const scope = 'openid profile email';

        // Construct the Google signup URL
        const googleSignupUrl = `${cognitoDomain}/oauth2/authorize?identity_provider=${identityProvider}&response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
            scope,
        )}&prompt=select_account`;

        // Construct the Cognito logout URL to clear the session
        const cognitoLogoutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;

        // Open a popup window
        const width = 500;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        const newWindow = window.open(
            googleSignupUrl,
            'GoogleLogin',
            `width=${width},height=${height},top=${top},left=${left}`,
        );

        // Event listener for the message from the popup window
        window.addEventListener('message', (event) => {
            if (event.origin !== window.location.origin) return; // Ensure message is from the correct origin

            const { id_token } = event.data;
            if (id_token) {
                console.log('ID Token received from popup:', id_token);
                localStorage.setItem('id_token', id_token);
                // Additional processing as needed
            }
        });
    };

    const handleFacebookSignup = () => {
        // Your existing Facebook signup logic
    };

    const handleGetCountryCode = async () => {
        const { code, data, msg } = await GetCountryCodeList();
        if (code === 0) {
            const filteredData = data.list.filter((item) => item.country_code === '61');

            //console.log('filteredData', filteredData);
            setCountryCode(
                filteredData.sort((a, b) => a.country_code_name.localeCompare(b.country_code_name)),
            );
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Country code was fetched unsuccessfully!',
                },
            });
        }
    };
    useEffect(() => {
        handleGetCountryCode();
    }, []);

    const registerFormik = useFormik({
        initialValues: {
            username: '',
            password: '',
            confirmPassword: '',
            email: '',
            country_code_name: 'Australia +61',
            phone: '',
            isSelected: false,
        },
        validationSchema: Yup.object({
            username: Yup.string().min(6).max(30).required('Username is required'),
            password: Yup.string().max(255).required('Password is required'),
            confirmPassword: Yup.string()
                .max(255)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
            email: Yup.string().max(255).required('email is required'),
            country_code_name: Yup.string().max(255).required('Country code is required'),
            // phone: Yup.string().max(255).required('Phone  number is required'),
            phone: Yup.number().when('country_code_name', {
                is: 'Australia +61',
                then: Yup.number().test('is-valid-phone', 'Invalid phone number length', (value) => {
                    const trimmedNumber = value.toString().trim();
                    if (trimmedNumber.startsWith('0')) {
                        // start with '0' check length equals 10
                        return trimmedNumber.length === 10;
                    } else {
                        return trimmedNumber.length === 9;
                    }
                }),
            }),
            isSelected: Yup.boolean().oneOf([true], 'You must accept the Terms of Use and Privacy Policy'),
        }),
        onSubmit: (values) => {
            const { username, password, confirmPassword, email, country_code_name, phone } = values;
            const countryCode = country_code_name.split('+')[1];
            //console.log('file: Register.jsx:87 => Register => countryCode:', countryCode);
            handleRegister(username, password, confirmPassword, email, countryCode, phone);
            // if (countryCode === '61') {
            //     let number = phone.trim();
            //      //console.log('file: Register.jsx:93 => Register => number:', number);
            //     // Remove the leading '0' if present
            //     if (number.startsWith('0')) {
            //         number = number.slice(1);
            //     }
            //     // Define the regular expression pattern for valid phone numbers
            //     const pattern = /^(4\d{8})$/;
            //     if (pattern.test(number)) {
            //          //console.log('Correct phone number.');
            //         handleRegister(username, password, confirmPassword, email, countryCode, number);
            //     } else {
            //     }
            // }
        },
    });

    const handleLogin = () => {
        navigate('/login', { replace: true });
    };

    const handleRegister = async (username, password, confirm_password, email, country_code, phone) => {
        //console.log('phone:', phone);
        //console.log('email:', email);
        //console.log('confirm_password:', confirm_password);
        //console.log('password:', password);
        //console.log('username:', username);

        const { code, msg } = await RequestRegister(
            username,
            password,
            confirm_password,
            email,
            country_code,
            phone,
        );
        if (code === 0) {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Register successfully! Please Login.',
                },
            });
            handleLogin();
            setIsSignedUp(true);
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Register info was fetched unsuccessfully!',
                },
            });
        }
    };

    // const [isSelected, setIsSelected] = React.useState(false); // Add remember me state
    // const handleChange = (event) => {
    //     setIsSelected(event.target.checked);
    // };
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);

    return (
        <div className="h-screen w-full flex">
            <LoginComponent />
            <div className="w-full md:w-1/2 h-screen z-50">
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        minHeight: '100%',
                    }}
                >
                    {!isSignedUp ? (
                        <Container maxWidth="sm">
                            <div className="p-16 pb-0">
                                <form onSubmit={registerFormik.handleSubmit}>
                                    <Typography
                                        className="text-center "
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontWeight: 800,
                                            fontSize: '36px',
                                            marginBottom: 4, // Adjust this value to increase/decrease space
                                        }}
                                    >
                                        Sign Up{' '}
                                    </Typography>

                                    {/* <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        
                                        <Button
                                            onClick={handleGoogleSignup}
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#4285F4', // Google Blue
                                                color: '#ffffff',
                                                textTransform: 'none',
                                                padding: 0,
                                                width: '100%',
                                                maxWidth: 300,
                                                borderRadius: 4,
                                                marginBottom: 10,
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                            }}
                                        >
                                            <Grid container alignItems="center">
                                                
                                                <Grid
                                                    item
                                                    style={{
                                                        backgroundColor: '#3367D6', // Darker Google blue for the icon section
                                                        padding: '10px 15px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderTopLeftRadius: 4,
                                                        borderBottomLeftRadius: 4,
                                                    }}
                                                >
                                                    <GoogleIcon />
                                                </Grid>
                                               
                                                <Grid item xs style={{ padding: '10px 15px' }}>
                                                    <Typography
                                                        variant="button"
                                                        style={{ fontWeight: 'bold', textTransform: 'none' }} // Adding textTransform to prevent capitalization
                                                    >
                                                        Sign in with Google
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Button>

                                       
                                        <Button
                                            onClick={handleLogOut}
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#3b5998', // Facebook Blue
                                                color: '#ffffff',
                                                textTransform: 'none',
                                                padding: 0,
                                                width: '100%',
                                                maxWidth: 300,
                                                borderRadius: 4,
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                            }}
                                        >
                                            <Grid container alignItems="center">
                                                
                                                <Grid
                                                    item
                                                    style={{
                                                        backgroundColor: '#2d4373', // Darker Facebook blue for the icon section
                                                        padding: '10px 15px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderTopLeftRadius: 4,
                                                        borderBottomLeftRadius: 4,
                                                    }}
                                                >
                                                    <FacebookIcon />
                                                </Grid>
                                               
                                                <Grid item xs style={{ padding: '10px 15px' }}>
                                                    <Typography
                                                        variant="button"
                                                        style={{ fontWeight: 'bold', textTransform: 'none' }} // Adding textTransform to prevent capitalization
                                                    >
                                                        Sign in with Facebook
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Button>

                                        
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={300}
                                            mt={2}
                                            mb={2}
                                        >
                                            <Divider sx={{ flex: 1 }} />
                                            <Typography
                                                variant="body1"
                                                sx={{ margin: '0 10px', color: '#666' }}
                                            >
                                                Or
                                            </Typography>
                                            <Divider sx={{ flex: 1 }} />
                                        </Box>
                                    </Box> */}

                                    <RedditTextField
                                        error={Boolean(
                                            registerFormik.touched.username && registerFormik.errors.username,
                                        )}
                                        fullWidth
                                        required
                                        helperText={
                                            registerFormik.touched.username && registerFormik.errors.username
                                        }
                                        label="Username"
                                        margin="normal"
                                        name="username"
                                        onBlur={registerFormik.handleBlur}
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.username}
                                        variant="filled"
                                    />
                                    <RedditTextField
                                        error={Boolean(
                                            registerFormik.touched.email && registerFormik.errors.email,
                                        )}
                                        fullWidth
                                        required
                                        helperText={
                                            registerFormik.touched.email && registerFormik.errors.email
                                        }
                                        label="Email Address"
                                        margin="normal"
                                        name="email"
                                        onBlur={registerFormik.handleBlur}
                                        onChange={registerFormik.handleChange}
                                        type="email"
                                        value={registerFormik.values.email}
                                        variant="filled"
                                    />

                                    <RedditTextField
                                        error={Boolean(
                                            registerFormik.touched.password && registerFormik.errors.password,
                                        )}
                                        fullWidth
                                        required
                                        helperText={
                                            registerFormik.touched.password && registerFormik.errors.password
                                        }
                                        label="Password"
                                        margin="normal"
                                        name="password"
                                        onBlur={registerFormik.handleBlur}
                                        onChange={registerFormik.handleChange}
                                        type={showPassword1 ? 'text' : 'password'}
                                        value={registerFormik.values.password}
                                        variant="filled"
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword1(!showPassword1)}
                                                        edge="end"
                                                    >
                                                        <Iconify
                                                            icon={
                                                                showPassword1
                                                                    ? 'eva:eye-fill'
                                                                    : 'eva:eye-off-fill'
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <RedditTextField
                                        error={Boolean(
                                            registerFormik.touched.confirmPassword &&
                                                registerFormik.errors.confirmPassword,
                                        )}
                                        fullWidth
                                        required
                                        helperText={
                                            registerFormik.touched.confirmPassword &&
                                            registerFormik.errors.confirmPassword
                                        }
                                        label="Confirm Password"
                                        margin="normal"
                                        name="confirmPassword"
                                        onBlur={registerFormik.handleBlur}
                                        onChange={registerFormik.handleChange}
                                        type={showPassword2 ? 'text' : 'password'}
                                        value={registerFormik.values.confirmPassword}
                                        variant="filled"
                                        // InputLabelProps={{
                                        // 	shrink: true,
                                        // }}
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword2(!showPassword2)}
                                                        edge="end"
                                                    >
                                                        <Iconify
                                                            icon={
                                                                showPassword2
                                                                    ? 'eva:eye-fill'
                                                                    : 'eva:eye-off-fill'
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div className="w-full grid grid-cols-4 gap-3">
                                        <div className="col-span-2">
                                            <RedditTextField
                                                select
                                                required
                                                fullWidth
                                                margin="normal"
                                                name="country_code_name"
                                                onBlur={registerFormik.handleBlur}
                                                onChange={registerFormik.handleChange}
                                                type="text"
                                                value={registerFormik.values.country_code_name}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {countryCode.map((code) => (
                                                    <MenuItem
                                                        key={code.country_code_name}
                                                        value={code.country_code_name}
                                                    >
                                                        {code.country_code_name}
                                                    </MenuItem>
                                                ))}
                                            </RedditTextField>
                                        </div>
                                        <div className="col-span-2">
                                            <RedditTextField
                                                error={Boolean(
                                                    registerFormik.touched.phone &&
                                                        registerFormik.errors.phone,
                                                )}
                                                required
                                                fullWidth
                                                helperText={
                                                    registerFormik.touched.phone &&
                                                    registerFormik.errors.phone
                                                }
                                                placeholder="Enter phone number"
                                                margin="normal"
                                                name="phone"
                                                onBlur={registerFormik.handleBlur}
                                                onChange={registerFormik.handleChange}
                                                type="text"
                                                value={registerFormik.values.phone}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="my-4 ">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={registerFormik.values.isSelected}
                                                    onChange={registerFormik.handleChange}
                                                    name="isSelected"
                                                />
                                            }
                                            label={
                                                <span>
                                                    I have read and agree to Sign Inspire’s{' '}
                                                    <a
                                                        href="terms.html"
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        Terms of Use
                                                    </a>{' '}
                                                    and{' '}
                                                    <a
                                                        href="policy.html"
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        Privacy Policy
                                                    </a>
                                                </span>
                                            }
                                        />
                                        <FormHelperText error>
                                            {registerFormik.touched.isSelected &&
                                                registerFormik.errors.isSelected}
                                        </FormHelperText>
                                    </div>
                                    <div className="flex my-4 w-full justify-center">
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            style={{
                                                color: '#ffffff',
                                                fontSize: '18px',
                                                fontWeight: 600,
                                                padding: '24px 32px',
                                            }}
                                        >
                                            Sign Up
                                        </Button>
                                    </div>
                                </form>
                                <div className="flex my-4 w-full justify-center">
                                    <span>
                                        Already have a Sign Inspire account?{' '}
                                        <span
                                            className="hover:underline cursor-pointer font-semibold"
                                            onClick={handleLogin}
                                        >
                                            Log in
                                        </span>{' '}
                                    </span>
                                </div>
                            </div>
                        </Container>
                    ) : (
                        <Container maxWidth="sm">
                            <div className="p-16 ">
                                <Stack spacing={3}>
                                    <img
                                        src={
                                            'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Home+images/images/SignUp0.png'
                                        }
                                        alt="logo"
                                        className="w-25 h-25 mx-auto"
                                    />
                                    <Typography
                                        className="text-center "
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontWeight: 800,
                                            fontSize: '42px',
                                        }}
                                    >
                                        You’re all sign up!
                                    </Typography>
                                    <Typography className="text-center " variant="body1">
                                        Please check your email for the confirmation message we just sent you.
                                    </Typography>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            padding: '24px 32px',
                                        }}
                                    >
                                        Resend Activation Email
                                    </Button>
                                </Stack>
                            </div>
                        </Container>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default Register;
