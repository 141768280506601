import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import ClientLogo1 from '../../images/clientLogo1.png';
import ClientLogo2 from '../../images/clientLogo2.png';
import ClientLogo3 from '../../images/clientLogo3.png';
import ClientLogo4 from '../../images/clientLogo4.png';
import Icon from '@mui/material/Icon';
import { ReactComponent as Next } from '../../images/next.svg';
import { ReactComponent as Prev } from '../../images/prev.svg';
import { Rating } from '@mui/material';
import Test1Icon from '../../images/Test1.jsx';
import Test2Icon from '../../images/Test2.jsx';
import Test3Icon from '../../images/Test3.jsx';
import Test4Icon from '../../images/Test4.jsx';
import Testi1 from '../../images/testi1.png';
import Testi2 from '../../images/testi2.png';
import Testi3 from '../../images/testi3.png';
import Testi4 from '../../images/testi4.png';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';

// Define each testimonial as an individual const variable
const testimonial1 = {
    text1: 'Cybercast is such a power tool that facilitates the communication within the hospital. It is easy to operate and the feedback from the staff has been extremely positive. It has exceeded expectations in every aspect, from the products and solutions to the support and services.',
    text2: 'Guy Chapple',
    text3: 'Director, Golf Business Forum',
    image1: ClientLogo1,
    clientInfo1: 'Epworth',
    // clientInfo2: 'Client Info 1B',
    backgroundImg: Testi1,
    buttonName: 'Healthcare',
    icon: Test1Icon,
};

const testimonial2 = {
    text1: "Cybercast is an outstanding platform, easy to use and powerful. Allowing us to achieve our sale's goals and customer engagement goals. It has exceeded all our expectations.",
    text2: 'Guy Chapple',
    text3: 'Director, Golf Business Forum',
    image1: ClientLogo2,
    clientInfo1: 'SuShi Hub',
    // clientInfo2: 'Client Info 2B',
    backgroundImg: Testi2,
    buttonName: 'Hospitality',
    icon: Test2Icon,
};
const testimonial3 = {
    text1: 'Cybercast has enhanced the learning experience for our students and helped our institution redefine communication within the campus. Uploading an image to the TV can be done with only a few clicks, much easier and user friendly compare to the other digital signage system!',
    text2: 'Guy Chapple',
    text3: 'Director, Golf Business Forum',
    image1: ClientLogo3,
    clientInfo1: 'Brighton Grammar School',
    // clientInfo2: 'Client Info 1B',
    backgroundImg: Testi3,
    buttonName: 'Education',
    icon: Test3Icon,
};

const testimonial4 = {
    text1: 'The Cybercast platform has allowed us to effectively capture exactly what products customers are looking for, and has introduced a much more immersive customer experience. It attracts people walking into the store and enquire about the offer being displayed on the Signage.',
    text2: 'Guy Chapple',
    text3: 'Director, Golf Business Forum',
    image1: ClientLogo4,
    clientInfo1: 'Mitre 10',
    // clientInfo2: 'Client Info 2B',
    backgroundImg: Testi4,
    buttonName: 'Retail',
    icon: Test4Icon,
};
// Store the testimonial const variables in an array for mapping
const testimonials = [testimonial1, testimonial2, testimonial3, testimonial4];

// Define the styles
const useStyles = makeStyles({
    iconFilled: {
        color: 'white',
    },
});

function CustomRating() {
    const classes = useStyles();

    return (
        <Rating
            name="read-only"
            value={4.5}
            precision={0.5}
            readOnly
            classes={{
                iconFilled: classes.iconFilled,
            }}
        />
    );
}

function TestimonialBlock() {
    const theme = useTheme();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Define the function to go to the next testimonial
        const nextTestimonial = () => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        };

        // Set up an interval to change the testimonial every few seconds
        const intervalId = setInterval(nextTestimonial, 5000); // Change every 5 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    const nextTestimonial = () =>
        setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    const prevTestimonial = () =>
        setActiveIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);

    const activeTestimonial = testimonials[activeIndex];

    return (
        <div className="w-full flex justify-center">
            <button
                onClick={prevTestimonial}
                className="mx-auto mt-64 w-16 h-16 rounded-full hover:border-white hover:border"
            >
                <Prev className="mx-auto" />
            </button>

            <div className="max-w-6xl sm:p-2">
                <div className="flex flex-col items-center justify-center md:flex-row md:space-x-6 ">
                    {/* First Column*/}
                    <div className="sm:w-full md:w-1/2 mb-6 md:mb-0 h-156">
                        <div className="mb-4 mr-8">
                            <h6 className="font-display text-lg font-light text-white text-customPrimary">
                                CASE STUDIES
                            </h6>
                            <h3 className="font-display font-bold text-4xl md:text-6xl text-white">
                                Learn from Our Success Stories
                            </h3>
                            <h3
                                className="font-bold text-7xl text-white mt-4"
                                style={{ fontFamily: 'Nunito' }}
                            >
                                “
                            </h3>
                            <p className="text-white pb-12">{activeTestimonial.text1}</p>
                        </div>
                        <div className=" mr-auto">
                            <img
                                src={activeTestimonial.image1}
                                alt="Image 1"
                                className="mt-2 mb-4"
                                style={{ width: '100px', height: '100px' }}
                            />
                            {/* <p className="font-bold font-display text-white text-lg">{activeTestimonial.text2}</p>
                            <p className="font-display text-white text-sm mb-2">{activeTestimonial.text3}</p> */}
                            <CustomRating name="read-only" value={4.5} precision={0.5} readOnly />
                        </div>
                    </div>
                    {/* Second Column */}
                    <div className="sm:w-full md:w-1/2  bg-white p-6 items-center text-center rounded-2xl h-156">
                        <div
                            className="bg-cover bg-center rounded-2xl flex justify-end p-12"
                            style={{
                                height: '419px',
                                backgroundImage: `url(${activeTestimonial.backgroundImg})`,
                            }}
                        >
                            <Button
                                endIcon={<Icon component={activeTestimonial.icon} />}
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: '#ffffff',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    marginTop: 'auto',
                                    padding: '24px 32px',
                                    cursor: 'default',
                                }}
                            >
                                {activeTestimonial.buttonName}{' '}
                            </Button>
                        </div>
                        <div className="p-6">
                            <p className="mb-2 font-bold font-display text-xl">
                                {activeTestimonial.clientInfo1}
                            </p>
                            <p className="mb-4 font-display text-slate-500 text-sm">
                                {activeTestimonial.clientInfo2}
                            </p>
                            {/* <a
                                href="./resources/all-blogs"
                                className="font-bold font-display text-xl text-customPrimary"
                            >
                                Learn More →
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <button
                onClick={nextTestimonial}
                className="mx-auto mt-64 w-16 h-16 rounded-full hover:border-white hover:border"
            >
                <Next className="mx-auto" />
            </button>
        </div>
    );
}

export default TestimonialBlock;
