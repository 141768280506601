import React from 'react';
import { IconButton } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Box } from '@mui/material';
function LoginComponent() {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Function to handle navigation
    const handleClick = () => {
        navigate('/'); // Navigate to the main page
    };

    return (
        <Box sx={{
            position: 'relative',
            width: '50%',
            height: '100vh',
            display: { xs: 'none', sm: 'block' } // Hide on xs and show on sm and above
        }}>
            {/* Background Image */}
            < img
                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A0.png" // Replace with your image URL
                alt="Background"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            />
            {/* Video */}

            {/* Icon Button */}
            <IconButton
                onClick={handleClick} // Set the onClick handler
                style={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    color: 'white',
                    zIndex: 2, // Ensure the icon button is above the video
                }}
            >
                <HomeIcon />
            </IconButton>
        </Box >
    );
}

export default LoginComponent;
