

const Header = ({ category, title, badgeText }) => (
    <div className="p-4">
        <p className="text-lg text-gray-400">{category}</p>
        <div className="flex flex-row">
            <div>
                <p className="text-xl font-bold tracking-tight text-slate-900">{title}</p>
            </div>
            <div>
                {badgeText && (
                    <span
                        className={`ml-3 mt-1.5 inline-block items-center px-1 text-[11px] uppercase rounded-[3px]  ${
                            badgeText.toLowerCase() === 'disabled'
                                ? 'bg-rose-200 text-rose-600 font-bold'
                                : 'bg-green-200 text-green-600 font-bold'
                        }`}
                    >
                        {badgeText}
                    </span>
                )}
            </div>
        </div>
    </div>
);

export default Header;
