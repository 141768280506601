import * as Yup from 'yup';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    TextField,
} from '@mui/material';
import React, { useRef } from 'react';

import { EditDevice } from '../../../api/api';
import { useFormik } from 'formik';
import { useNotification } from '../../../contexts/ContextProvider';

const rotations = [
    {
        value: 0,
        label: 'Default',
    },
    {
        value: 1,
        label: 'Rotate [Clockwise]',
    },
    {
        value: 2,
        label: 'Rotate [Anticlockwise]',
    },
];

const booleans = [
    {
        value: 1,
        label: 'YES',
    },
    {
        value: 0,
        label: 'NO',
    },
];

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const minutes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
    29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
    55, 56, 57, 58, 59,
];

const timeZone = [
    'Asia/Shanghai',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Brisbane',
    'Australia/Hobart',
    'Australia/Perth',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Pacific/Auckland',
    'Europe/Amsterdam',
    'Europe/Berlin',
    'Europe/London',
    'Europe/Paris',
    'Europe/Rome',
];

const watermarkPositions = [
    {
        value: 1,
        label: 'TL',
    },
    {
        value: 2,
        label: 'TR',
    },
    {
        value: 3,
        label: 'BL',
    },
    {
        value: 4,
        label: 'BR',
    },
];

const style = {
    labelText: {
        minWidth: '150px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
    },
    label: {
        minWidth: '150px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
};
const EditDeviceDialog = ({
    currentUser,
    locationList,
    openEditDialog,
    handleCloseEditDialog,
    setTriggerUpdate,
    triggerUpdate,
    deviceDetail,
}) => {
    //console.log('file: EditDeviceDialog.jsx:159 => locationList:', locationList);
    const notify = useNotification();
    //console.log('currentUser', currentUser);
    var deviceDetail;
    // if (localStorage.getItem('currentDevice') != 'undefined') {
    // 	 //console.log("localStorage.getItem('currentDevice') != undefined:", localStorage.getItem('currentDevice'));
    // 	deviceDetail = JSON.parse(localStorage.getItem('currentDevice'));
    // }
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    //console.log('deviceDetail', deviceDetail);
    const contentRef = useRef(null);
    const formik = useFormik({
        initialValues: {
            device_id: deviceDetail ? deviceDetail.device_id : 0,
            device_name: deviceDetail ? deviceDetail.device_name : 0,
            mac_address: deviceDetail ? deviceDetail.mac_address : 0,
            group_id: deviceDetail ? deviceDetail.group_id : 0,
            time_zone: deviceDetail ? deviceDetail.time_zone : 0,
            rotation: deviceDetail ? deviceDetail.rotation : 0,
            tv_channel: deviceDetail ? deviceDetail.tv_channel : 0,
            lock_campaign: deviceDetail ? deviceDetail.lock_campaign : 0,
            is_auto_trun: deviceDetail ? deviceDetail.is_auto_trun : 0,
            is_auto_off: deviceDetail ? deviceDetail.is_auto_off : 0,
            auto_trun_hour: deviceDetail ? deviceDetail.auto_trun_hour : 0,
            auto_trun_min: deviceDetail ? deviceDetail.auto_trun_min : 0,
            auto_off_hour: deviceDetail ? deviceDetail.auto_off_hour : 0,
            auto_off_min: deviceDetail ? deviceDetail.auto_off_min : 0,
            auto_start_cybercast: deviceDetail ? deviceDetail.auto_start_cybercast : 0,
            enable_pocket_edition: deviceDetail ? deviceDetail.enable_pocket_edition : 0,
            use_external_storage: deviceDetail ? deviceDetail.use_external_storage : 0,
            enable_4K_video: deviceDetail ? deviceDetail.enable_4K_video : 0,
            capture_audience: deviceDetail ? deviceDetail.capture_audience : 0,
            soniq_campaign: deviceDetail ? deviceDetail.soniq_campaign : 0,
            display_watermark: deviceDetail ? deviceDetail.display_watermark : 0,
            watermark_position: deviceDetail ? deviceDetail.watermark_position : 0,
            restart_device: deviceDetail ? deviceDetail.restart_device : 0,
            settings: deviceDetail ? deviceDetail.settings : 0,
            note: deviceDetail ? (deviceDetail.note === null ? '' : deviceDetail.note) : 0,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            // code: Yup.string().max(255).required('code is required'),
        }),
        onSubmit: (values) => {
            //console.log('values', values);
            const { group_id, device_id } = values;
            //console.log('values.is_auto_trun:', values.is_auto_trun);

            handleEditDevice(
                values.device_id,
                values.device_name,
                values.mac_address,
                values.group_id,
                values.time_zone,
                values.rotation,
                values.tv_channel.length > 0 ? 1 : 0,
                values.lock_campaign.length > 0 ? 1 : 0,
                values.is_auto_trun.length > 0 ? 1 : 0,
                values.is_auto_off.length > 0 ? 1 : 0,
                values.auto_trun_hour,
                values.auto_trun_min,
                values.auto_off_hour,
                values.auto_off_min,
                parseInt(values.auto_start_cybercast),
                parseInt(values.enable_pocket_edition),
                parseInt(values.use_external_storage),
                parseInt(values.enable_4K_video),
                parseInt(values.capture_audience),
                parseInt(values.soniq_campaign),
                parseInt(values.display_watermark),
                parseInt(values.display_watermark) === 1 ? parseInt(values.watermark_position) : 0,
                parseInt(values.restart_device),
                values.settings,
                values.note,
            );

            //  //console.log(values.code, values.expire_month);
        },
    });

    const handleEditDevice = async (
        device_id,
        device_name,
        mac_address,
        group_id,
        time_zone,
        rotation,
        tv_channel,
        lock_campaign,
        is_auto_trun,
        is_auto_off,
        auto_trun_hour,
        auto_trun_min,
        auto_off_hour,
        auto_off_min,
        auto_start_cybercast,
        enable_pocket_edition,
        use_external_storage,
        enable_4K_video,
        capture_audience,
        soniq_campaign,
        display_watermark,
        watermark_position,
        restart_device,
        settings,
        note,
    ) => {
        const data = await EditDevice(
            device_id,
            device_name,
            mac_address,
            group_id,
            time_zone,
            rotation,
            tv_channel,
            lock_campaign,
            is_auto_trun,
            is_auto_off,
            auto_trun_hour,
            auto_trun_min,
            auto_off_hour,
            auto_off_min,
            auto_start_cybercast,
            enable_pocket_edition,
            use_external_storage,
            enable_4K_video,
            capture_audience,
            soniq_campaign,
            display_watermark,
            watermark_position,
            restart_device,
            settings,
            note,
        );
        //console.log('data', data);
        if (data.code === 0) {
            handleCloseEditDialog();
            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Device was updated successfully!',
                },
            });
        } else {
            handleCloseEditDialog();

            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Device was updated unsuccessfully.',
                },
            });
        }
    };
    //console.log('formik:', formik);

    return (
        <Dialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            fullWidth
            maxWidth="sm"
            scroll="paper"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{'Device Setting'}</DialogTitle>
            <DialogContent>
                {deviceDetail && (
                    <form onSubmit={formik.handleSubmit}>
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="device_name" sx={style.labelText}>
                                Device
                            </FormLabel> */}
                        <TextField
                            error={Boolean(formik.touched.device_name && formik.errors.device_name)}
                            fullWidth
                            helperText={formik.touched.device_name && formik.errors.device_name}
                            margin="normal"
                            aria-labelledby="device_name"
                            label="Device"
                            name="device_name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.device_name}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        />
                        {/* </FormControl> */}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="mac_address" sx={style.labelText}>
                                MAC address
                            </FormLabel> */}
                        <TextField
                            error={Boolean(formik.touched.mac_address && formik.errors.mac_address)}
                            fullWidth
                            disabled
                            helperText={formik.touched.mac_address && formik.errors.mac_address}
                            margin="normal"
                            aria-labelledby="mac_address"
                            label="MAC address"
                            name="mac_address"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.mac_address}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        />
                        {/* </FormControl> */}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="group_id" sx={style.labelText}>
                                Location
                            </FormLabel> */}
                        <TextField
                            sx={{ marginTop: '8px' }}
                            error={Boolean(formik.touched.group_id && formik.errors.group_id)}
                            select
                            fullWidth
                            helperText={formik.touched.group_id && formik.errors.group_id}
                            margin="normal"
                            label="Location"
                            name="group_id"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.group_id}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        >
                            {formik.values.group_id === 0 && (
                                <MenuItem value={0} key={0}>
                                    Unassigned
                                </MenuItem>
                            )}
                            {locationList?.map((x) => (
                                <MenuItem value={x.group_id} key={x.group_id}>
                                    {x.group_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* </FormControl> */}

                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="time_zone" sx={style.labelText}>
                                Time Zone
                            </FormLabel> */}
                        <TextField
                            sx={{ marginTop: '8px' }}
                            error={Boolean(formik.touched.time_zone && formik.errors.time_zone)}
                            select
                            fullWidth
                            helperText={formik.touched.time_zone && formik.errors.time_zone}
                            margin="normal"
                            label="Time Zone"
                            name="time_zone"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.time_zone}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        >
                            {timeZone?.map((x) => (
                                <MenuItem value={x} key={x}>
                                    {x}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* </FormControl> */}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="rotation" sx={style.labelText}>
                                Rotation
                            </FormLabel> */}
                        <TextField
                            sx={{ marginTop: '8px' }}
                            error={Boolean(formik.touched.rotation && formik.errors.rotation)}
                            select
                            fullWidth
                            helperText={formik.touched.rotation && formik.errors.rotation}
                            margin="normal"
                            label="Rotation"
                            name="rotation"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.rotation}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        >
                            {rotations?.map((x) => (
                                <MenuItem value={x.value} key={x + x.value}>
                                    {x.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* </FormControl> */}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="note" sx={style.labelText}>
                                Note
                            </FormLabel> */}
                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel id="is_auto_trun" sx={style.label}>
                                Turn Device
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_auto_trun"
                                        // value={1}
                                        checked={
                                            formik.values.is_auto_trun === 1 ||
                                            formik.values.is_auto_trun.length > 0
                                        }
                                        defaultChecked={formik.values.is_auto_trun === 1}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="On"
                            />
                        </FormControl>

                        <TextField
                            error={Boolean(formik.touched.note && formik.errors.note)}
                            fullWidth
                            multiline
                            rows={4}
                            helperText={formik.touched.note && formik.errors.note}
                            margin="normal"
                            name="note"
                            label="Note"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.note}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true, // 这里禁用下边框
                            }}
                        />
                        {/* </FormControl> */}
                        {/* TODO: */}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='tv_channel' sx={style.label}>
								Lock TV channel
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby='tv_channel'
								name='tv_channel'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.tv_channel}>
								{booleans.map((option, index) => (
									<FormControlLabel
										key={option.value}
										value={option.value}
										control={<Radio />}
										label={option.label}
									/>
								))}
							</RadioGroup>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='lock_campaign' sx={style.label}>
								Lock Campaign
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby='lock_campaign'
								name='lock_campaign'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.lock_campaign}>
								{booleans.map((option, index) => (
									<FormControlLabel
										key={option.value}
										value={option.value}
										control={<Radio />}
										label={option.label}
									/>
								))}
							</RadioGroup>
						</FormControl>

						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='lock_campaign' sx={style.label}>
								Turn Device
							</FormLabel>

							<FormControlLabel
								control={
									<Checkbox
										name='is_auto_off'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										// value={0}
										checked={formik.is_auto_off}
										defaultChecked={formik.is_auto_off === 1}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
								label='Off'
							/>
							<div className='flex flex-row w-full justify-around'>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='auto_off_hour' sx={style.labelSelect}>
										Hours
									</FormLabel>
									<TextField
										sx={{ marginTop: '8px' }}
										error={Boolean(formik.touched.auto_off_hour && formik.errors.auto_off_hour)}
										select
										helperText={formik.touched.auto_off_hour && formik.errors.auto_off_hour}
										margin='normal'
										name='auto_off_hour'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.auto_off_hour}
										variant='outlined'>
										{hours.map((x) => (
											<MenuItem value={x} key={x}>
												<em>{x}</em>
											</MenuItem>
										))}
									</TextField>
								</FormControl>
								<div>
									<FormControl
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
										<FormLabel
											id='auto_off_min'
											sx={{
												display: 'flex',
												textAlign: 'end',
												paddingRight: '12px',
												// marginTop: '8px',
											}}>
											Minutes
										</FormLabel>
										<TextField
											select
											sx={{ marginTop: '8px' }}
											helperText={formik.touched.auto_off_min && formik.errors.auto_off_min}
											margin='normal'
											name='auto_off_min'
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.auto_off_min}
											variant='outlined'>
											{minutes.map((x) => (
												<MenuItem value={x} key={x}>
													<em>{x}</em>
												</MenuItem>
											))}
										</TextField>
									</FormControl>
								</div>
							</div>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='lock_campaign' sx={style.label}>
								Turn Device
							</FormLabel>
							<FormControlLabel
								control={
									<Checkbox
										name='is_auto_trun'
										// value={1}
										checked={formik.is_auto_trun}
										defaultChecked={formik.is_auto_trun === 1}
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
								label='On'
							/>
							<div className='flex flex-row w-full justify-around'>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='auto_off_hour' sx={style.labelSelect}>
										Hours
									</FormLabel>
									<TextField
										sx={{ marginTop: '8px' }}
										error={Boolean(formik.touched.auto_trun_hour && formik.errors.auto_trun_hour)}
										select
										helperText={formik.touched.auto_trun_hour && formik.errors.auto_trun_hour}
										margin='normal'
										name='auto_trun_hour'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.auto_trun_hour}
										variant='outlined'>
										{hours.map((x) => (
											<MenuItem value={x} key={x}>
												<em>{x}</em>
											</MenuItem>
										))}
									</TextField>
								</FormControl>
								<div>
									<FormControl
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
										<FormLabel
											id='auto_off_min'
											sx={{
												display: 'flex',
												textAlign: 'end',
												paddingRight: '12px',
												// marginTop: '8px',
											}}>
											Minutes
										</FormLabel>
										<TextField
											select
											sx={{ marginTop: '8px' }}
											helperText={formik.touched.auto_trun_min && formik.errors.auto_trun_min}
											margin='normal'
											name='auto_trun_min'
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.auto_trun_min}
											variant='outlined'>
											{minutes.map((x) => (
												<MenuItem value={x} key={x}>
													<em>{x}</em>
												</MenuItem>
											))}
										</TextField>
									</FormControl>
								</div>
							</div>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='auto_start_cybercast' sx={style.label}>
								Auto start Cybercast
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby='auto_start_cybercast'
								name='auto_start_cybercast'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.auto_start_cybercast}>
								{booleans.map((option, index) => (
									<FormControlLabel
										key={option.value}
										value={option.value}
										control={<Radio />}
										label={option.label}
									/>
								))}
							</RadioGroup>
						</FormControl>
						{currentUser.user_level === 0 && (
							<>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='enable_pocket_edition' sx={style.label}>
										Enable pocket edition
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='enable_pocket_edition'
										name='enable_pocket_edition'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.enable_pocket_edition}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='use_external_storage' sx={style.label}>
										Use external storage
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='use_external_storage'
										name='use_external_storage'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.use_external_storage}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='enable_4K_video' sx={style.label}>
										Enable 4K video
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='enable_4K_video'
										name='enable_4K_video'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.enable_4K_video}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='capture_audience' sx={style.label}>
										Capture audience
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='capture_audience'
										name='capture_audience'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.capture_audience}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='soniq_campaign' sx={style.label}>
										SONIQ campaign
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='soniq_campaign'
										name='soniq_campaign'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.soniq_campaign}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='display_watermark' sx={style.label}>
										Display watermark
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='display_watermark'
										name='display_watermark'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.display_watermark}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
									<div className=''>
										<RadioGroup
											row
											aria-labelledby='watermark_position'
											name='watermark_position'
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.watermark_position}>
											{watermarkPositions.map((option, index) => (
												<FormControlLabel
													key={option.value}
													value={option.value}
													control={<Radio />}
													label={option.label}
												/>
											))}
										</RadioGroup>
									</div>
								</FormControl>
								<FormControl
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<FormLabel id='restart_device' sx={style.label}>
										Restart device
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby='restart_device'
										name='restart_device'
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.restart_device}>
										{booleans.map((option, index) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								</FormControl>
							</>
						)}

						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='settings' sx={style.labelText}>
								Setting
							</FormLabel>

							<TextField
								error={Boolean(formik.touched.settings && formik.errors.settings)}
								fullWidth
								multiline
								rows={4}
								helperText={formik.touched.settings && formik.errors.settings}
								margin='normal'
								name='settings'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.settings}
								variant='outlined'
							/>
						</FormControl>

						 */}

                        <DialogActions>
                            <Button onClick={handleCloseEditDialog} variant="contained">
                                Cancel
                            </Button>
                            <Button
                                // onClick={handleCloseEditDialog}
                                autoFocus
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditDeviceDialog;
