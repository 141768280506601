import { Button, TextField } from '@mui/material';
import React from 'react';
import { alpha, styled } from '@mui/material/styles';


const FolderNameInput = styled(TextField)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        height: '20px',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '5px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
const CampaignAddFolder = ({
    // setTriggerUpdate,
    // triggerUpdate,
    folderName,
    handleInputFolderName,
    AddMediaFolderByName,
}) => {
    return (
        <div>
            <FolderNameInput
                sx={{
                    margin: '2px',
                    padding: '0',
                    marginRight: '4px',
                }}
                placeholder="Folder Name"
                margin="normal"
                name="name"
                onChange={(e) => handleInputFolderName(e)}
                value={folderName}
                variant="outlined"
            />
            <Button
                sx={{
                    marginLeft: '10px',
                }}
                variant="contained"
                onClick={() => AddMediaFolderByName()}
            >
                Add Folder
            </Button>
        </div>
    );
};

export default CampaignAddFolder;
