import React from 'react';

const WebWidget = ({ item, media }) => {
    return (
        <div className="w-full h-full">
            {media?.length > 0 &&
                media
                    ?.filter((r) => r?.element_type === 'web')
                    ?.map(
                        (x) =>
                            x.element_id === item.element_id && (
                                <div className="w-full h-full" key={x.element_id}>
                                    <iframe
                                        src={x?.detail_list?.resource_content[0]?.resource_website}
                                        title="Embedded Page"
                                        width="100%"
                                        height="100%"
                                    ></iframe>
                                </div>
                            ),
                    )}
        </div>
    );
};

export default WebWidget;
