import moment from 'moment';
// lastApiCallManager.js
let lastApiCallTime = Date.now();

export const setLastApiCallTime = (time) => {
    lastApiCallTime = time;
    //  //console.log(
    //     'file: App.js:104 => App => getLastApiCallTime:',
    //     moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'),
    // );
};

export const getLastApiCallTime = () => {
    return lastApiCallTime;
};
