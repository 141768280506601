import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { MdOutlineImportantDevices } from 'react-icons/md';
import React from 'react';
import { RemoveDeviceAssigned } from '../../../api/api';
import { useNotification } from '../../../contexts/ContextProvider';

const UnassignDeviceDialog = ({
    openUnassignDeviceDialog,
    handleClose,
    clickedItem,
    currentUser,
    locationDetail,
    triggerUpdate,
    setTriggerUpdate,
}) => {
    const notify = useNotification();

    const group_id = locationDetail?.group_info?.id;
    //console.log('group_id:', group_id);
    const RemoveDevice = async () => {
        const data = await RemoveDeviceAssigned(group_id, clickedItem.device_id);
        //console.log('data', data);
        if (data.code === 0) {
            handleClose();
            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Device was Unassigned successfully!!',
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg ? data.msg : 'Device was Unassigned unsuccessfully!',
                },
            });
        }
    };
    return (
        <Dialog
            open={openUnassignDeviceDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth="true"
        >
            <DialogTitle id="alert-dialog-title">{'Removing this device?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="flex flex-row">
                        <div className="flex items-center mr-2">
                            <MdOutlineImportantDevices />
                        </div>
                        <div>
                            {/*clickedItem  */}
                            <p className="text-center m-0 ">
                                {clickedItem?.device_name}{' '}
                                <span className="text-[10px] text-black-500  px-1 rounded pb-1 ">
                                    <span
                                        className={`${
                                            clickedItem?.device_status === 'OFFLINE'
                                                ? 'text-red-500'
                                                : 'text-green-500'
                                        } text-sx font-bold`}
                                    >
                                        {clickedItem?.device_status}
                                    </span>
                                    {clickedItem?.device_status === 'OFFLINE' &&
                                        ` Last online: ${clickedItem?.last_online}`}
                                </span>
                            </p>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={RemoveDevice} autoFocus variant="contained" color="error">
                    Unassign
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnassignDeviceDialog;
