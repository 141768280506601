import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button, Container, Grid, Paper, IconButton, LinearProgress, TextField, CircularProgress, Alert } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import AWS from 'aws-sdk';
import config from '../config/config';

const AdPerformanceTest = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // State for preview
  const [uploading, setUploading] = useState(false);
  const [analyzing, setAnalyzing] = useState(false);
  const [analyze_error, setanalyze_error] = useState(false);
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(''); // State to store the uploaded image URL
  const [heatmapUrl, setHeatmapUrl] = useState(''); // State to store heatmap URL
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', email: '' });
  const [emailSent, setEmailSent] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control the alert visibility

  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFilePreview(URL.createObjectURL(file)); // Set file preview
    
    // Reset states when a new file is selected
    setAnalyzing(false);
    setAnalysisComplete(false);
    setProgress(0);
    setUploadedImageUrl('');
    setHeatmapUrl('');
  };

  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Manually trigger the file input click
    }
  };

  const uploadToS3 = async (file) => {
    const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;
    const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;
    const ACCESS_KEY = config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId;
    const SECRET_KEY = config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey;

    // Update AWS configuration
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_KEY,
      region: REGION,
    });

    const s3 = new AWS.S3();
    const timestamp = Date.now();
    const fileExtension = file.name.split('.').pop();
    const fileName = 'AdPerformanceTest/' + `${timestamp}.${fileExtension}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err); // Handle error
        }
        resolve(data.Location); // Return the URL of the uploaded file
      });
    });
  };

  const handleAnalyze = async () => {
    if (!selectedFile) {
      alert('Please select an image first.');
      return;
    }

    setUploading(true);

    try {
      const imageUrl = await uploadToS3(selectedFile);
      setUploadedImageUrl(imageUrl);

      const response = await fetch('https://2khcmhlrhk.execute-api.ap-southeast-2.amazonaws.com/v100/ad_performance_test', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: imageUrl }),
      });

      const result = await response.json();
      if (result.statusCode === 200 && result.message.s3_url) {
        setHeatmapUrl(result.message.s3_url); // Store the heatmap URL
      }

    } catch (error) {
      console.error('Error:', error);
      alert('Error uploading image or analyzing ad performance. Please try again.');
      setanalyze_error(true);
    } finally {
      setUploading(false);
      setAnalyzing(false);
      setAnalysisComplete(true);
      
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSendEmail = async () => {
    try {
      const response = await fetch('https://2khcmhlrhk.execute-api.ap-southeast-2.amazonaws.com/v100/ad_performance_test_send_email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          original_image_url: uploadedImageUrl,
          heatmap_url: heatmapUrl,
          to_email: userInfo.email,
        }),
      });

      if (response.ok) {
        setEmailSent(true); // Set email sent confirmation
      } else {
        console.error('Error sending email');
        alert('Failed to send the email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending email. Please try again.');
    }
  };

  useEffect(() => {
    if (emailSent) {
      setShowAlert(true);
      // Hide alert after 3 seconds
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [emailSent]);

  // Check if all required fields are filled
  const isFormValid = userInfo.firstName && userInfo.lastName && userInfo.email;

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
      Test Your Ad's Attention Performance for <span style={{ color: '#00A651' }}>Free.</span>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Upload your ad image and discover how well it captures attention.
        <br />
        Find out where your audience are and are not paying attention.
      </Typography>

      <Paper elevation={1} sx={{ mt: 5, p: 5, border: '1px dashed #ccc', textAlign: 'center' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IconButton size="large" sx={{ color: '#4267B2' }}>
                  <ImageIcon sx={{ fontSize: 80 }} />
                </IconButton>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Upload Image
                </Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleChooseFileClick}>
                  Choose File
                </Button>
                {/* Hidden file input */}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
                <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                  {selectedFile ? selectedFile.name : 'No file chosen'}
                </Typography>

                {filePreview && (
                  <Box mt={2}>
                    <Typography variant="body1" gutterBottom>
                      Image Preview:
                    </Typography>
                    <img src={filePreview} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box textAlign="center" mt={3}>
        {uploading ? (
          <CircularProgress />
        ) : selectedFile && !analysisComplete ? (
          // Show "Analyze" button only after a file is selected and upload is complete
          <Button variant="contained" color="primary" onClick={handleAnalyze}>
            Analyze
          </Button>
        ) : analyzing ? (
          <LinearProgress variant="determinate" value={progress} />
        ) : analyze_error && analysisComplete ? (
          <Typography variant="h6" color="primary">
            System busy, please try again later
          </Typography>
        ) :analysisComplete ? (
          <Typography variant="h6" color="primary">
            Analysis complete!
          </Typography>
        ) : null}
      </Box>

      {!analyze_error && analysisComplete && !emailSent && (
        <Box mt={3}>
          <Typography variant="h6">Enter your details to receive the results:</Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="First Name"
                name="firstName"
                value={userInfo.firstName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Last Name"
                name="lastName"
                value={userInfo.lastName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Email"
                name="email"
                value={userInfo.email}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {/* Disable the Confirm button if form is incomplete */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendEmail}
                disabled={!isFormValid} // Disable if form is not valid
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {showAlert && (
        <Alert severity="success" onClose={() => setShowAlert(false)} sx={{ mt: 3 }}>
          Your analysis results have been sent to your email!
        </Alert>
      )}
    </Container>
  );
};

export default AdPerformanceTest;
