import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import React, { useState } from 'react';

import { CopySchedule } from '../../../api/api';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const CopyScheduleDialog = ({
    triggerUpdate,
    openCopyDialog,
    handleCloseCopyDialog,
    setTriggerUpdate,
    currentUser,
    locationList,
    scheduleDetail,
}) => {
    const [checkedList, setCheckedList] = useState([scheduleDetail.group_id]);
    const [itemsChecked, setItemsChecked] = useState(false);
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const handleCheckboxClick = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            setCheckedList([...checkedList, value * 1]); //type-compatible
        } else {
            setCheckedList(checkedList.filter((item) => item != value));
        }
    };
    const filteredList = locationList.filter((item) => ![scheduleDetail.group_id]?.includes(item.group_id));
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];

        if (checked) {
            for (const item of filteredList) {
                collection.push(item.group_id);
            }
        }

        setCheckedList(collection);
        setItemsChecked(checked);
    };

    //Handle Copy to other Location
    const handleCopy = async () => {
        const data = await CopySchedule(
            switchUser,
            scheduleDetail.schedule_id,
            checkedList.filter((item) => item !== scheduleDetail.group_id),
        );
        if (data.code === 0) {
            setTriggerUpdate(!triggerUpdate);
            handleCloseCopyDialog();
        }
    };

    return (
        <Dialog open={openCopyDialog} fullWidth={true} maxWidth="md">
            <DialogTitle sx={{ fontSize: '14px', fontWeight: 'bold' }}>Group Location</DialogTitle>

            <DialogContent>
                <div className="m-2">
                    <div className="flex flex-row  bg-blue-300 p-1 pl-2 rounded-lg mb-2">
                        <div>
                            <Checkbox color="success" checked={itemsChecked} onClick={selectItem} />
                            Select All
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4 ">
                        {locationList?.map((item, index) => (
                            <div key={item.group_id} className="text-sm shadow-md rounded-lg p-3 ">
                                <Checkbox
                                    item={item}
                                    value={item.group_id}
                                    checked={
                                        checkedList.includes(item.group_id) ||
                                        [scheduleDetail.group_id].includes(item.group_id)
                                    }
                                    onChange={handleCheckboxClick}
                                    disabled={item.group_id === scheduleDetail.group_id}
                                />
                                {item.group_name}
                            </div>
                        ))}
                    </div>
                </div>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={handleCloseCopyDialog}>
                    Close
                </Button>
                <Button variant="contained" onClick={handleCopy}>
                    Copy
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CopyScheduleDialog;
