import React, { useState, useEffect } from 'react';
import Sphoto1 from '../../images/sphoto6.png';
import Sphoto2 from '../../images/sphoto5.png';
import Sphoto3 from '../../images/sphoto4.png';
import Sphoto4 from '../../images/sphoto1.png';
import Sphoto5 from '../../images/sphoto2.png';
import Sphoto6 from '../../images/sphoto7.png';
import Sphoto7 from '../../images/sphoto3.png';

import HardwarePhoto1 from '../../images/hardware1.png';
import HardwarePhoto2 from '../../images/hardware2.png';
import HardwarePhoto3 from '../../images/hardware3.png';

import { ReactComponent as Prev } from '../../images/prevCopy.svg';
import { ReactComponent as Next } from '../../images/nextCopy.svg';

const hardware1 = {
    text1: "Capacitive Touch Display",
    text2: "Model: 32BFE60G",
    text3: "Cybercast Capacitive Touch Displays feature fast and responsive 10-point multitouch technology, allowing your audience to engage with your content at an intimate level.",
    size: ["32”", "43”", "53”"],
    size_spec: [{
        specificationName1: "Contrast ratio",
        specificationValue1: "3000:1",
        specificationName2: "Response time",
        specificationValue2: "9.5ms (Typ)",
        specificationName3: "Memory",
        specificationValue3: "DDR4 3G",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC 16GB',
        specificationName5: 'Brightness',
        specificationValue5: '300nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_32+Capacitive+Touch.pdf',
        image: HardwarePhoto1,
    },
    {
        specificationName1: "Contrast ratio",
        specificationValue1: "4000:1",
        specificationName2: "Response time",
        specificationValue2: "9.5ms (Typ)",
        specificationName3: "Memory",
        specificationValue3: "DDR4 3G",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC 16GB',
        specificationName5: 'Brightness',
        specificationValue5: '300nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_43+Capacitive+Touch.pdf',
        image: HardwarePhoto2,

    },
    {
        specificationName1: "Contrast ratio",
        specificationValue1: "5000:1",
        specificationName2: "Response time",
        specificationValue2: "9.5ms (Typ)",
        specificationName3: "Memory",
        specificationValue3: "DDR4 3G'",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC 16GB',
        specificationName5: 'Brightness',
        specificationValue5: '300nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_43+Capacitive+Touch.pdf',
        image: HardwarePhoto3,

    },]
};

const hardware2 = {
    text1: "Koisk",
    text2: "Model: ASF5515A-TA",
    text3: "Bring more customers to your business with eye catching and impactful digital Kiosks,that entirely front serviceable, with a magnetic module design saving your time and money by making maintenance easy.",
    size: ["55”"],
    size_spec: [{
        specificationName1: "With stand footbase",
        specificationValue1: "790*463*1935.8mm",
        specificationName2: "Weight",
        specificationValue2: "67kg",
        specificationName3: "Memory",
        specificationValue3: "DDR3 2GB",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC Flash 8GB',
        specificationName5: 'Brightness',
        specificationValue5: '320nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_Kiosk.pdf',
        image: HardwarePhoto2,

    },]
};
const hardware3 = {
    text1: "Slim Koisk",
    text2: "Model: 55KUE30G",
    text3: "Bring more customers to your business with eye catching and impactful digital Kiosks,that entirely front serviceable, with a magnetic module design saving your time and money by making maintenance easy.",
    size: ["55”", "65”"],
    size_spec: [{
        specificationName1: "Contrast ratio",
        specificationValue1: "1300:1",
        specificationName2: "Response time",
        specificationValue2: "9ms (Typ)",
        specificationName3: "Memory",
        specificationValue3: "DDR3 2G",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC 16GB',
        specificationName5: 'Brightness',
        specificationValue5: '300nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_55+Slim+Kiosk.pdf',
        image: HardwarePhoto2,

    },
    {
        specificationName1: "Contrast ratio",
        specificationValue1: "1100:1",
        specificationName2: "Response time",
        specificationValue2: "9ms (Typ)",
        specificationName3: "Memory",
        specificationValue3: "DDR4 2G'",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC 16GB',
        specificationName5: 'Brightness',
        specificationValue5: '300nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_65+Slim+Kiosk.pdf',
        image: HardwarePhoto3,
    },]
};

const hardware4 = {
    text1: "Flexible Aframe",
    text2: "Model: 65KUE30G",
    text3: "These flexible signs are ideal for retail applications, showrooms, foyers, reception areas and waiting rooms. In fact, any high pedestrian thoroughfare will suit these mobile messaging boards.",
    size: ["42”", "43”"],
    size_spec: [{
        specificationName1: "With stand folded",
        specificationValue1: "587*36.3*1522mm",
        specificationName2: "Weight",
        specificationValue2: "23kg",
        specificationName3: "Memory",
        specificationValue3: "2G'",
        specificationName4: 'Storage',
        specificationValue4: '16GB',
        specificationName5: 'Brightness',
        specificationValue5: '350 cd/m2',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_Aframe.pdf',
        image: HardwarePhoto1,
    },
    {
        specificationName1: "With stand folded",
        specificationValue1: "608*390*1200mm",
        specificationName2: "Weight",
        specificationValue2: "28kg",
        specificationName3: "Memory",
        specificationValue3: "DDR3 2G'",
        specificationName4: 'Storage',
        specificationValue4: 'EMMC 8GB',
        specificationName5: 'Brightness',
        specificationValue5: '450nit',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/43+inch+Indoor+A-Frame_0323.pdf',
        image: HardwarePhoto2,

    },]
};
const hardware5 = {
    text1: "LCD Video Wall Display",
    text2: "Model: 46WFD35B",
    text3: " ",
    size: ["46”"],
    size_spec: [{
        specificationName1: "Contrast ratio",
        specificationValue1: "1200:1",
        specificationName2: "Response time",
        specificationValue2: "8ms (Typ)",
        specificationName3: "Panel resolution",
        specificationValue3: "DDR4 3G'",
        specificationName4: 'Unit L*W*D',
        specificationValue4: '1021.98*576.57*124.5mm',
        specificationName5: 'Brightness',
        specificationValue5: '500cd/m2 (Typ)',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_Video+Wall.pdf',
        image: HardwarePhoto2,

    },]
};
const hardware6 = {
    text1: "4K Interactive Table",
    text2: "Model: 55SUE80A",
    text3: " ",
    size: ["55”"],
    size_spec: [{
        specificationName1: "Screen Resolution",
        specificationValue1: "3840*2160",
        specificationName2: "Multitouch",
        specificationValue2: "10 points",
        specificationName3: "Memory",
        specificationValue3: "DDR 4G'",
        specificationName4: 'Storage',
        specificationValue4: 'Flash 32GB',
        specificationName5: 'Brightness',
        specificationValue5: '300cd/m2',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_0419_20.pdf',
        image: HardwarePhoto3,
    },]
};

const hardware7 = {
    text1: "Cybercast Digital Signage Box",
    text2: "Model: TCA201",
    text3: "Turn any screen into a digital signage display.",
    size: ["117*117*25mm"],
    size_spec: [{
        specificationName1: "Operating System",
        specificationValue1: "Android 7.1",
        specificationName2: "Weight",
        specificationValue2: "360g",
        specificationName3: "Memory",
        specificationValue3: "2G'",
        specificationName4: 'Storage',
        specificationValue4: '16GB',
        specificationName5: 'Expand Memory',
        specificationValue5: 'SD Card (support4- 32GB)',
        link: 'https://cybercast-documents.s3.ap-southeast-2.amazonaws.com/Product+Flyers+%26+Catalogues/2023/Single+PDF/Cybercast+Catalogue_Cybercast+Box.pdf',
        image: HardwarePhoto1,
    },]
};
// Store the hardware const variables in an array for mapping
const hardwares = [hardware1, hardware2, hardware3, hardware4, hardware5, hardware6, hardware7];

const Dot = ({ isActive, name }) => {
    return (
        <div style={{
            height: '40px',
            borderBottom: isActive ? '5px solid #01B5E2' : 'none', // Conditional bottom border
            margin: '2px', // For spacing between dots
            fontSize: '14px',
            fontWeight: isActive ? 'bold' : 'normal',
        }} >{name}</div>
    );
};
// Parent Component
class DotsContainer extends React.Component {

    render() {
        // Array representing the five dots
        const dots = ["Screens", "Kiosk", "Slim Display", "Aframe", "Video walls", "Interactive", "Devices"];
        const smallPhotos = [Sphoto1, Sphoto2, Sphoto3, Sphoto4, Sphoto5, Sphoto6, Sphoto7]
        const { hoveredIndex, activeIndex } = this.props; // Accessing activeIndex from props

        return (
            <div className='flex z-60 mx-auto justify-around items-center'>
                {dots.map((_, index) => (
                    <div className='flex flex-col w-24  justify-center items-center' key={index} onClick={() => this.props.onChangeActiveIndex(index)} onMouseEnter={() => this.props.onChangeHoveredIndex(index)} onMouseLeave={() => this.props.onChangeHoveredIndex(activeIndex)}>
                        <img src={smallPhotos[index]} className='mx-auto'
                        />
                        <div className='flex justify-center items-center mx-auto'>
                            <Dot isActive={hoveredIndex === index} name={dots[index]} />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

function HardwareList() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const [selectedSwitchIndex, setSelectedSwitchIndex] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setLoaded(true);
        if (loaded) {
            setKey(prevKey => prevKey + 1); // Change the key to trigger a re-render
        }
        const timer = setTimeout(() => {
            setLoaded(false);
        }, 2000);

        // Clean up the timer if the component is unmounted
        return () => clearTimeout(timer);
    }, [hoveredIndex]);

    function resetHoveredIndex(index) {
        setSelectedSwitchIndex(0);
        setHoveredIndex(index);
    }
    console.log("animationloaded:", loaded);
    console.log("HoveredIndex:", hoveredIndex);
    const nextHardware = () => setActiveIndex((prevIndex) => (prevIndex + 1) % hardwares.length);
    const prevHardware = () => setActiveIndex((prevIndex) => (prevIndex - 1 + hardwares.length) % hardwares.length);

    const activeHardware = hardwares[activeIndex];
    const hoveredHardware = hardwares[hoveredIndex];

    return (
        <div className='w-full overflow-visible'>
            <div className="max-w-3xl mx-auto justify-center text-center space-x-2 items-center ">
                <DotsContainer hoveredIndex={hoveredIndex} activeIndex={activeIndex} onChangeActiveIndex={setActiveIndex} onChangeHoveredIndex={resetHoveredIndex} />
            </div>
            <div className='w-full flex justify-center bg-white py-20 overflow-visible'>
                <button onClick={prevHardware} className="mx-auto mt-40 w-16 h-16 rounded-full hover:border-gray hover:border"><Prev className='mx-auto' /></button>

                {hoveredIndex !== null &&
                    <div className="max-w-6xl sm:p-2 overflow-visible">
                        <div className="flex flex-col md:flex-row justify-between overflow-visible">
                            {/* First Column*/}
                            <div className="md:w-2/5 md:pr-16 flex flex-col md:mb-0 justify-between h-106 pb-16">
                                <p className="mr-auto font-bold font-display text-4xl ">{hoveredHardware.text1}</p>
                                <h6 className='mr-auto font-display text-xl font-bold text-customPrimary mt-4'>{hoveredHardware.text2}</h6>
                                <p className="mr-auto font-display mt-4">{hoveredHardware.text3}</p>
                                <div className="mr-auto font-display mt-auto">
                                    <h3 className='font-bold text-xl'>Panel size</h3>
                                    <div className='bg-white rounded-xl p-2 mr-auto shadow-md flex justify-center space-x-4'>
                                        {hoveredHardware && hoveredHardware.size.map((size, idx) => (
                                            <div
                                                className={`py-2 px-8 rounded-md text-center hover:text-black text-semibold flex items-center justify-center cursor-pointer
                                                ${idx === selectedSwitchIndex ? 'bg-customPrimary text-white' : 'bg-white text-customPrimary '}`}
                                                key={idx}
                                                onClick={() => setSelectedSwitchIndex(idx)}>
                                                {size}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* Second Column */}
                            <div className="md:w-2/5 h-106 overflow-visible pb-16">
                                <div className="mx-16 my-auto h-96 flex items-center justify-center bg-customBlue rounded-2xl overflow-visible">
                                    <img key={key}
                                        src={hoveredHardware.size_spec[selectedSwitchIndex].image} alt="Image 1" className={`w-full h-auto object-cover ${loaded ? 'animate-shrink-and-expand2' : 'scale-110'}`} />
                                </div>
                            </div>

                            {/* Third Column */}
                            <div className='md:w-1/5 md:pl-16  items-center h-106 pb-16'>
                                <h6 className='mr-auto font-display text-base mb-4 font-bold text-lg' >Specifications</h6>

                                <p className="mr-auto font-display font-light text-slate-500 ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationName1}</p>
                                <p className="mr-auto mb-3 font-semibold font-display ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationValue1}</p>
                                <p className="mr-auto font-display font-light text-slate-500 ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationName2}</p>
                                <p className="mr-auto mb-3 font-semibold font-display ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationValue2}</p>
                                <p className="mr-auto font-display font-light text-slate-500 ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationName3}</p>
                                <p className="mr-auto mb-3 font-semibold font-display ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationValue3}</p>
                                <p className="mr-auto font-display font-light text-slate-500 ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationName4}</p>
                                <p className="mr-auto mb-3 font-semibold font-display ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationValue4}</p>
                                <p className="mr-auto font-display font-light text-slate-500 ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationName5}</p>
                                <p className="mr-auto mb-3 font-semibold font-display ">{hoveredHardware.size_spec[selectedSwitchIndex].specificationValue5}</p>
                                <div className="flex mr-auto py-4 font-display items-center font-light text-slate-500 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M14 10.9805V13.6471C14 14.0008 13.8595 14.3399 13.6095 14.5899C13.3594 14.84 13.0203 14.9805 12.6667 14.9805H3.33333C2.97971 14.9805 2.64057 14.84 2.39052 14.5899C2.14048 14.3399 2 14.0008 2 13.6471V10.9805" stroke="#01B5E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.66675 7.64746L8.00008 10.9808L11.3334 7.64746" stroke="#01B5E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 10.9805V2.98047" stroke="#01B5E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <a href={`${hoveredHardware.size_spec[selectedSwitchIndex].link}`} className="ml-4 font-bold font-display text-base text-customPrimary hover:text-cybercast-blue">
                                        Download Specs
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>}
                <button onClick={nextHardware} className="mx-auto mt-40 w-16 h-16 rounded-full hover:border-gray hover:border"><Next className='mx-auto' /></button>
            </div>
        </div>
    );
}

export default HardwareList;
