import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

import { Paycyberpoint } from './Api';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// import { useNotification } from '../../../contexts/ContextProvider';

const PointsDialog = ({
    openPointsDialog,
    handleClosePointsDialog,
    userId,
    selectedrows,
    cyberPoints,
    totalPrice,
    customerType,
}) => {
    const navigate = useNavigate();
    const pointsLeft = cyberPoints - totalPrice;
    const formattedObject = {
        user_id: userId,
        old_point: cyberPoints,
        new_point: pointsLeft,
        reference: 'Pay By Points',
        ...(customerType !== 0 && { customer_type: customerType }),
        device_summary: selectedrows.map((row) => ({
            id: row.id,
            new_start: row.new_start,
            new_end: row.new_end,
        })),
    };
    //console.log('file: PointsDialog.jsx:35 => formattedObject:', formattedObject);

    const handlePayCyberpoints = async () => {
        if (pointsLeft > 0) {
            const data = await Paycyberpoint(formattedObject);
            //console.log('file: PointsDialog.jsx:49 => handlePayCyberpoints => data:', data);
            if (data.statusCode === 200) {
                window.location.reload();
                // handleClosePointsDialog();
                // navigate('/payment', { replace: true });
            }
        } else {
            alert('Ensure your Cyberpoints balance exceeds the total price to proceed.');
        }
    };
    //  //console.log('file: PointsDialog.jsx:24 => oneYearArray:', oneYearArray);
    return (
        <>
            <Dialog open={openPointsDialog} maxWidth="md">
                <DialogTitle
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        paddingBottom: '0',
                    }}
                >
                    For the {customerType === 1 ? 'creator' : 'inspirer'} subscription
                </DialogTitle>
                <DialogContent sx={{ maxHeight: '640px' }}>
                    <div className="w-full flex flex-row justify-evenly sticky">
                        <div className="bg-blue-300 rounded-xl w-56 p-8 pt-9 m-3">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="font-bold text-gray-400">Current Points:</p>
                                    <p className="text-2xl">{cyberPoints}</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-blue-300 rounded-xl w-56 p-8 pt-9 m-3">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="font-bold text-gray-400">Points needed:</p>
                                    <p className="text-2xl">{totalPrice}</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-blue-300 rounded-xl w-56 p-8 pt-9 m-3">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="font-bold text-gray-400">Points Left:</p>
                                    <p className="text-2xl">{pointsLeft}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table sx={{ overflowY: 'scroll' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Device ID</TableCell>
                                <TableCell>Device name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>New start</TableCell>
                                <TableCell>New End</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedrows.map((row) => (
                                <TableRow>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.device_name}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.new_start}</TableCell>
                                    <TableCell>{row.new_end}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={handleClosePointsDialog}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handlePayCyberpoints}>
                        Pay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PointsDialog;
