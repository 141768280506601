import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        borderRadius: '14px',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            marginBottom: '16px',
        },
    }),
);

export const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0.5),
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0.5),
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        // Prevent the icon from rotating
        transform: 'none',
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
