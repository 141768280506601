import axios from 'axios';
import config from '../../../config/config';
// const ImagetURL =
//   "https://jo28hkjks3.execute-api.ap-southeast-2.amazonaws.com/";
// const stage = "dev";
const heatmap_url = config[process.env.REACT_APP_ENV].REACT_APP_HEATMAP_API_URL;

const ImageDevURL = config[process.env.REACT_APP_ENV].REACT_APP_IMAGE_API_URL;
const api_key = config[process.env.REACT_APP_ENV].REACT_APP_IMAGE_API_KEY;

const runway_url = config[process.env.REACT_APP_ENV].REACT_APP_RUNWAY_API_URL;

const salt_url = config[process.env.REACT_APP_ENV].REACT_APP_SALT_API_URL;

export var Resize = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/resize',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
export var SplitImage = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/split_image',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var ImageStyling = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/image_variation',
        data: body,
        headers: {
            'x-api-key': api_key,
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var CreateImages = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/create_image',
        headers: {
            'x-api-key': api_key,
        },

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var TextImprove = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/text_enhance',
        headers: {
            'x-api-key': api_key,
        },

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var UpScale_image = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/upscale_image',
        headers: {
            'x-api-key': api_key,
        },

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var UpScale_Result = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/upscale_result',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GenerateVideo = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/image_to_video',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetVideoResult = async (body) => {
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/video_result',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var PostDescribeImage = async (body) => {
    console.log('api_key:', api_key);
    console.log('ImageDevURL:', ImageDevURL);
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/describe_image',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var GetDescribeImage = async (body) => {
    console.log('GetDescribeImage => body:', body);
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/describe_image_result',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
        /// {"image_url":"https://signinspire-media.s3.ap-southeast-2.amazonaws.com/local-user-17-media_resource/2024-09-05-a1fec992225548f2c067cd6b4e431a81.png","user_id":"17"}
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var FetchAIRecommend = async (body) => {
    console.log('FetchAIRecommend => body:', body);
    let result = await axios({
        method: 'post',
        url: ImageDevURL + '/evaluate_image',
        headers: {
            'x-api-key': api_key,
        },
        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var FetchHeatmapURL = async (body) => {
    console.log('FetchHeatmapURL => body:', body);
    let result = await axios({
        method: 'post',
        url: heatmap_url,

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var RunwayVideo = async (body) => {
    console.log('RunwayVideo => body:', body);
    let result = await axios({
        method: 'post',
        url: runway_url + '/runway_image_video',

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var RunwayGetTask = async (body) => {
    console.log('RunwayVideo => body:', body);
    let result = await axios({
        method: 'get',
        url: runway_url + '/runway_task',
        params: body, // Use 'params' for query parameters in GET requests
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.error('Error fetching runway task:', error);
            throw error; // Optional: re-throw the error for caller to handle
        });

    return result;
};

export var SaltUpscaleImage = async (body) => {
    console.log('SaltUpscaleImage => body:', body);
    let result = await axios({
        method: 'post',
        url: salt_url + '/salt_upscale_create',

        data: body,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

export var SaltUpscaleResult = async (body) => {
    console.log('SaltUpscaleResult => body:', body);
    let result = await axios({
        method: 'get',
        url: salt_url + '/salt_upscale_result',
        params: body, // Use 'params' for query parameters in GET requests
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.error('Error fetching runway task:', error);
            throw error; // Optional: re-throw the error for caller to handle
        });

    return result;
};
