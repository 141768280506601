import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { GetContractList, GetContractListByDeviceId } from '../../../api/api';
import React, { useEffect, useState } from 'react';

import { DeviceDetailDialog } from './';
import { Header } from '../';
import { Pulse } from '../utils';
import moment from 'moment';
import { useStateContext } from '../../../contexts/ContextProvider';

const Contract = ({ rows }) => {
    const { currentUser } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const headers = [
        'ID',
        'Device',
        // 'Model',
        'Location',
        'Expired Date',
        'Subscription',
        // 'Recharge',
        // 'Next Expired Date',
    ];
    const [deviceId, setDeviceId] = useState();
    // const [rows, setRows] = useState([]);
    // const rows = [];
    // useEffect(() => {
    //     handleGetContractList();
    // }, []);

    // // Get User's device
    // const handleGetContractList = async () => {
    //     const data = await GetContractList(switchUser);
    //      //console.log('file: Contract.jsx:29 => handleGetContractList => data:', data);
    //     setRows(
    //         data.data.sort(function (a, b) {
    //             return a.device_id - b.device_id;
    //         }),
    //     );
    // };

    const [openDeviceDetailDialog, setOpenDeviceDetailDialog] = useState(false);
    const [deviceDetail, setDeviceDetail] = useState();
    // Get Device detial
    const handleGetDeviceDetail = async (id) => {
        setOpenDeviceDetailDialog(true);

        const { data, code, msg } = await GetContractListByDeviceId(id);
        //console.log('file: Contract.jsx:35 => handleGetDeviceDetail => data:', data);
        if (code === 0) setDeviceDetail(data);
    };

    const handleCloseDeviceDetailDialog = () => {
        setOpenDeviceDetailDialog(false);
        setDeviceDetail([]);
    };

    return (
        <div className=" ">
            {/* <Header title="Contract" /> */}
            <div className="p-10">
                {rows.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((item) => (
                                        <TableCell key={item} align="center">
                                            {item}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((row) => (
                                    <TableRow
                                        key={row.device_id}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                            },
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={() => handleGetDeviceDetail(row.device_id)}
                                    >
                                        <TableCell align="center">{row.device_id}</TableCell>
                                        <TableCell align="center">{row.device_name}</TableCell>
                                        {/* <TableCell align='center'>
										{row?.device_model}
									</TableCell> */}
                                        <TableCell align="center">{row?.location}</TableCell>
                                        <TableCell
                                            align="center"
                                            // 01/Jan/2025
                                            sx={
                                                moment(row?.expire_time).isBefore(
                                                    moment().format('DD/MMM/YYYY'),
                                                )
                                                    ? { color: 'red' }
                                                    : {}
                                            }
                                        >
                                            {row?.expire_time}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={
                                                    row?.subscription === 'true'
                                                        ? 'Subscribed'
                                                        : 'Unsubscribed'
                                                }
                                                color={row?.subscription === 'true' ? 'success' : 'primary'}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <DeviceDetailDialog
                            openDeviceDetailDialog={openDeviceDetailDialog}
                            handleCloseDeviceDetailDialog={handleCloseDeviceDetailDialog}
                            deviceDetail={deviceDetail}
                        />
                    </TableContainer>
                ) : (
                    <Pulse />
                )}
            </div>
        </div>
    );
};

export default Contract;
