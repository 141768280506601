import React, { createContext, useContext, useReducer, useState } from 'react';

import reducer from './reducer';

const StateContext = createContext({
    alert: { open: false, severity: 'info', message: '' },
});
export const useNotification = () => {
    return useContext(StateContext);
};
export const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        alert: { open: false, severity: 'info', message: '' },
    });
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState('#0444AB');
    const [currentUser, setCurrentUser] = useState();
    const [currentMode, setCurrentMode] = useState('Light');
    const [activeMenu, setActiveMenu] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [currentDevice, setCurrentDevice] = useState({});
    const [templateElement, setTemplateElement] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    // const [lastApiCall, setLastApiCall] = useState();
    const setMode = (e) => {
        setCurrentMode(e.target.value);
        localStorage.setItem('themeMode', e.target.value);
    };

    const setColor = (color) => {
        setCurrentColor(color);
        localStorage.setItem('colorMode', color);
    };
    const setUser = (user) => {
        setCurrentUser(user);
        localStorage.setItem('currentUser', user);
    };

    // const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <StateContext.Provider
            value={{
                currentColor,
                currentMode,
                activeMenu,
                screenSize,
                setScreenSize,
                setCurrentUser,
                currentUser,
                setCurrentDevice,
                currentDevice,
                setUser,
                setAllUsers,
                allUsers,
                setActiveMenu,
                setCurrentColor,
                setCurrentMode,
                setMode,
                setColor,
                setShowSidebar,
                showSidebar,
                templateElement,
                setTemplateElement,
                state,
                dispatch,
                // setLastApiCall,
                // lastApiCall,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
