import React, { useState } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    Typography,
    Box,
    Rating,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import PropTypes from 'prop-types';

const ImageDescriptionDialog = ({
    ImageDescription,
    originalImage,
    handleCloseImageDescriptionDialog,
    openImageDescriptionDialog,
}) => {
    return (
        <Dialog
            open={openImageDescriptionDialog}
            onClose={handleCloseImageDescriptionDialog}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    boxShadow: 3,
                    paddingBottom: '16px',
                },
            }}
        >
            <DialogTitle sx={{ position: 'relative', padding: '16px' }}>
                <Typography variant="h6" align="center">
                    Image Evaluation
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseImageDescriptionDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '16px', height: 'auto' }}>
                {/* Loading Section Centered */}
                {!ImageDescription ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: '50vh', flexDirection: 'column' }}
                    >
                        <CircularProgress />
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Generating...
                        </Typography>
                    </Box>
                ) : (
                    <Grid
                        container
                        spacing={4}
                        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                    >
                        {/* Left Image Section */}
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    src={originalImage}
                                    alt="Original"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        maxHeight: '70vh', // Ensuring the image doesn't overflow
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Right Content Section */}
                        <Grid item xs={12} md={6}>
                            <Box sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
                                {/* Evaluation Criteria Section */}
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        Evaluation Criteria
                                    </Typography>
                                    {[
                                        'Visual Appeal',
                                        'Clarity of Message',
                                        'Relevance to Target Audience',
                                        'Focus on Product or Service',
                                        'Effectiveness in Capturing Attention',
                                    ].map((criterion) => (
                                        <Box key={criterion} sx={{ mb: 3 }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                {criterion}
                                            </Typography>
                                            <Rating
                                                value={ImageDescription[criterion]?.Rating || 0}
                                                readOnly
                                                max={5}
                                                sx={{ mt: 1 }}
                                            />
                                            <Typography
                                                variant="body2"
                                                sx={{ color: 'text.secondary', mt: 1 }}
                                            >
                                                {ImageDescription[criterion]?.Explanation ||
                                                    'No explanation provided.'}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>

                                <Divider sx={{ my: 3 }} />

                                {/* Strengths Section */}
                                <Box>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{ display: 'flex', alignItems: 'center', color: 'success.main' }}
                                    >
                                        <ThumbUpIcon sx={{ mr: 1 }} /> Strengths
                                    </Typography>
                                    <ul style={{ paddingLeft: '1.5rem', marginBottom: '16px' }}>
                                        {ImageDescription.Strengths.map((strength, index) => (
                                            <li key={index} style={{ marginBottom: '8px' }}>
                                                <Typography variant="body2">{strength}</Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </Box>

                                <Divider sx={{ my: 3 }} />

                                {/* Areas to Refine Section */}
                                <Box>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{ display: 'flex', alignItems: 'center', color: 'info.main' }}
                                    >
                                        <LightbulbOutlinedIcon sx={{ mr: 1 }} /> Areas to Refine
                                    </Typography>
                                    {ImageDescription['Areas for Improvement']?.length > 0 ? (
                                        <ul style={{ paddingLeft: '1.5rem', marginBottom: '16px' }}>
                                            {ImageDescription['Areas for Improvement'].map((area, index) => (
                                                <li key={index} style={{ marginBottom: '8px' }}>
                                                    <Typography variant="body2">{area}</Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            No significant areas to refine.
                                        </Typography>
                                    )}
                                </Box>

                                <Divider sx={{ my: 3 }} />

                                {/* Overall Effectiveness Section */}
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        Overall Effectiveness
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                        {ImageDescription['Overall Effectiveness']}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

ImageDescriptionDialog.propTypes = {
    ImageDescription: PropTypes.shape({
        'Visual Appeal': PropTypes.shape({
            Rating: PropTypes.number,
            Explanation: PropTypes.string,
        }),
        'Clarity of Message': PropTypes.shape({
            Rating: PropTypes.number,
            Explanation: PropTypes.string,
        }),
        'Relevance to Target Audience': PropTypes.shape({
            Rating: PropTypes.number,
            Explanation: PropTypes.string,
        }),
        'Focus on Product or Service': PropTypes.shape({
            Rating: PropTypes.number,
            Explanation: PropTypes.string,
        }),
        'Effectiveness in Capturing Attention': PropTypes.shape({
            Rating: PropTypes.number,
            Explanation: PropTypes.string,
        }),
        Strengths: PropTypes.arrayOf(PropTypes.string),
        'Areas for Improvement': PropTypes.arrayOf(PropTypes.string),
        'Overall Effectiveness': PropTypes.string,
    }),
    originalImage: PropTypes.string,
    handleCloseImageDescriptionDialog: PropTypes.func.isRequired,
    openImageDescriptionDialog: PropTypes.bool.isRequired,
};

export default ImageDescriptionDialog;
