import { Box, Button, Card, CardContent, Drawer, Grid, Paper, Typography } from '@mui/material';
import { useRef, useState } from 'react';

import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import ImageGenerate from '../components/dashboard/ai_tools/ImageGenerate';
import ImageIcon from '@mui/icons-material/Image';
import ImageResize from '../components/dashboard/ai_tools/ImageResize';
import ImageSegment from '../components/dashboard/ai_tools/ImageSegment';
import ImageStyle from '../components/dashboard/ai_tools/ImageStyle';
import ImageVideoRunway from '../components/dashboard/ai_tools/ImageVideoRunway';
import ImageToVideo from '../components/dashboard/ai_tools/ImageToVideo';
import ImageUpscale from '../components/dashboard/ai_tools/ImageUpscale';
import TextEnhance from '../components/dashboard/ai_tools/TextEnhance';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextToAudio from '../components/dashboard/ai_tools/TextToAudio';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import config from '../config/config';

// const ImagetURL =
//   "https://jo28hkjks3.execute-api.ap-southeast-2.amazonaws.com/";
// const stage = "dev";

//const upgrade_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;
// const customerType = 2;

const AIPage = ({ toggleDrawer, open, setOpen }) => {
    const [selectedTool, setSelectedTool] = useState('');
    const contentRef = useRef(null);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    //console.log('AIPage => userDetails:', userDetails);
    const customerType = userDetails?.customer_type;

    const handleUpgradeClick = () => {
        window.location.href = '/dashboard/payment'; // Replace with your actual upgrade URL
    };

    const renderContent = () => {
        // // open to all customer for free now
        // if (customerType === 1) {

        //     return (
        //         <Box
        //             sx={{
        //                 textAlign: 'center',
        //                 padding: '20px',
        //                 backgroundColor: '#f5f5f5',
        //                 borderRadius: '8px',
        //                 boxShadow: 3,
        //             }}
        //         >
        //             <Typography
        //                 variant="h5"
        //                 sx={{
        //                     mb: 2,
        //                     color: 'primary.main',
        //                     justifyContent: 'center',
        //                     alignItems: 'center',
        //                 }}
        //             >
        //                 Please upgrade your plan to access these features
        //             </Typography>
        //             <Typography variant="body1">
        //                 You do not have the necessary permissions to access this page. Please contact support
        //                 for more information.
        //             </Typography>
        //             <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleUpgradeClick}>
        //                 Upgrade
        //             </Button>
        //         </Box>
        //     );
        // }

        switch (selectedTool) {
            case 'ai-imageupscale':
                return <ImageUpscale />;
            case 'ai-imagegenerate':
                return <ImageGenerate />;
            case 'ai-textenhance':
                return <TextEnhance />;
            case 'ai-texttoaudio':
                return <TextToAudio />;
            case 'ai-imagesegment':
                return <ImageSegment />;
            case 'ai-imageresize':
                return <ImageResize />;
            case 'ai-imagestyle':
                return <ImageStyle />;
            case 'ai-runwayvideo':
                return <ImageVideoRunway />;

            case 'ai-imagetovideo':
                return <ImageToVideo />;
            default:
                return (
                    <Box sx={{ p: 3, justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                            Select a tool from the left to get started
                        </Typography>
                    </Box>
                );
        }
    };

    const handleCardClick = (tool) => {
        if (customerType !== -1) {
            setSelectedTool(tool);
            if (contentRef.current) {
                contentRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const getCardStyle = (tool) => ({
        display: 'flex',
        alignItems: 'center',
        cursor: customerType === -1 ? 'default' : 'pointer',
        backgroundColor: selectedTool === tool ? 'primary.light' : 'background.paper',
        color: selectedTool === tool ? 'primary.contrastText' : 'text.primary',
        boxShadow: selectedTool === tool ? 6 : 1,
        opacity: customerType === -1 ? 0.5 : 1,
    });

    return (
        <div>
            <Drawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '75%',
                        margin: '0 auto',
                        height: '80vh',
                        overflow: 'auto',
                    },
                }}
            >
                <Box sx={{ width: 'auto', padding: 2 }} role="presentation">
                    <Box
                        sx={{
                            textAlign: 'center',
                            padding: '20px',
                            backgroundColor: '#f5f5f5',
                            borderRadius: '8px',
                            boxShadow: 3,
                        }}
                    >
                        <WorkspacePremiumIcon sx={{ fontSize: 40, mr: 2 }} />
                        <Typography variant="h4" sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
                            Generative AI Assistant
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ mt: 4, color: 'text.disabled', fontStyle: 'italic' }}
                        >
                            Powered by SignInspire AI
                        </Typography>
                    </Box>

                    <Grid container spacing={2} sx={{ height: '100%', paddingTop: 2 }}>
                        <Grid item xs={3} sx={{ paddingRight: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Card
                                    sx={getCardStyle('ai-imageupscale')}
                                    onClick={() => handleCardClick('ai-imageupscale')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FourKPlusIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">4K UpScale</Typography>
                                            <Typography variant="body2">
                                                Upscale your image to 4K resolution
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-imagegenerate')}
                                    onClick={() => handleCardClick('ai-imagegenerate')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ImageIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">Ad Inspiration</Typography>
                                            <Typography variant="body2">
                                                Generate AI images effortlessly
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-textenhance')}
                                    onClick={() => handleCardClick('ai-textenhance')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextSnippetIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">TextEnhancer</Typography>
                                            <Typography variant="body2">
                                                Enhance your writing with AI
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-imagestyle')}
                                    onClick={() => handleCardClick('ai-imagestyle')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ArtTrackIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">AI Re-Style</Typography>
                                            <Typography variant="body2">
                                                Change your image in another Style
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-imageresize')}
                                    onClick={() => handleCardClick('ai-imageresize')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <AspectRatioIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">Image Resize</Typography>
                                            <Typography variant="body2">
                                                Tailor your image to perfection
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-imagesegment')}
                                    onClick={() => handleCardClick('ai-imagesegment')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <AutoAwesomeMosaicIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">Image Segmentation</Typography>
                                            <Typography variant="body2">
                                                Segment your image into 2, 4, 8, or more parts
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-runwayvideo')}
                                    onClick={() => handleCardClick('ai-runwayvideo')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <VideoCameraBackIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">Image2Video</Typography>
                                            <Typography variant="body2">
                                                Create 5-10 Second Videos from a Single Image
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={getCardStyle('ai-imagetovideo')}
                                    onClick={() => handleCardClick('ai-imagetovideo')}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                        <VideoCameraBackIcon sx={{ fontSize: 40, mr: 2 }} />
                                        <Box>
                                            <Typography variant="h6">Animate Image</Typography>
                                            <Typography variant="body2">
                                                Enhance your image with a dynamic 4-second animation
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item xs={9} sx={{ overflowY: 'auto' }}>
                            <Paper elevation={3} sx={{ height: '100%', p: 2 }} ref={contentRef}>
                                {renderContent()}
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </div>
    );
};

export default AIPage;
