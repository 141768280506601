import { Accordion, AccordionDetails, AccordionSummary } from '../Accordion';
import { Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { FaEdit, FaLayerGroup, FaMapMarkerAlt } from 'react-icons/fa';
import { LocationCampaignList, LocationDeviceList, LocationDialog } from './';
import React, { useEffect, useState } from 'react';

import { DeleteDialog } from '../utils';
import { FiMonitor } from 'react-icons/fi';
import { FiTrash2 } from 'react-icons/fi';
import { GetLocationInfo } from '../../../api/api';

/**
 *
 * Location list
 * @param {currentUser, locationListDetail, triggerUpdate, setTriggerUpdate, locationList }
 * @returns
 *
 */

const Locations = ({ currentUser, triggerUpdate, setTriggerUpdate, locationList, switchUser }) => {
    const [expanded, setExpanded] = useState();
    const [groupId, setGroupId] = useState();

    const [locationDetail, setLocationDetail] = useState();
    const handleLocationInfo = async (group_id) => {
        const data = await GetLocationInfo(switchUser, group_id);
        if (data.code === 0) {
            setLocationDetail(data.data);
            // localStorage.setItem('locationDetail', JSON.stringify(data.data));
        }
    };

    const handleExpanded = (item) => {
        if (expanded === item.group_id) {
            setExpanded();
        } else {
            setExpanded(item.group_id);
            setLocationDetail({});
            item.device_count !== 0 && handleLocationInfo(item?.group_id);
        }
    };
    useEffect(() => {
        setExpanded(groupId ? groupId : 0);
        handleLocationInfo(groupId);
    }, [triggerUpdate]);

    //handle Open Location Dialog
    const [openLocation, setOpenLocation] = useState(false);
    // const [addedLocation, setAddedLocation] = useState(false);
    const handleOpenLocationDialog = (item) => {
        //console.log('item', item);
        setGroupId(item.group_id);
        handleLocationInfo(item.group_id);
        // localStorage.setItem('locationDetail', JSON.stringify(locationDetail));
        setOpenLocation(true);
    };
    const handleCloseLocationDialog = () => {
        setOpenLocation(false);
    };

    //delete location
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (item) => {
        //console.log('item', item);
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    return (
        <>
            {locationList?.map((item, index) => (
                <Accordion
                    key={index + item}
                    expanded={expanded === item.group_id}
                    onChange={(e) => {
                        e.stopPropagation();
                        handleExpanded(item);
                    }}
                >
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <div className="flex justify-between w-full">
                            <div className="flex flex-row">
                                <FaMapMarkerAlt className="mt-1 mr-1" />
                                <div className="w-[170px]">
                                    <Typography>{item.group_name}</Typography>
                                </div>
                                <Chip
                                    label={item.device_count}
                                    sx={{
                                        backgroundColor: '#E5ECF6',
                                        color: '#0444AB',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        marginRight: '10px',
                                    }}
                                    icon={<FiMonitor style={{ color: '#0444AB', marginLeft: '10px' }} />}
                                />
                                <Chip
                                    label={item.campaign.length}
                                    sx={{
                                        backgroundColor: '#E5ECF6',
                                        color: '#0444AB',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        marginRight: '10px',
                                    }}
                                    icon={<FaLayerGroup style={{ color: '#0444AB', marginLeft: '10px' }} />}
                                />
                            </div>
                            <div
                                className="flex flex-row divide-x divide-solid"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div>
                                    <Tooltip title="Edit" onClick={() => handleOpenLocationDialog(item)}>
                                        <IconButton size="small">
                                            <FaEdit label="Edit" size={15} color="black" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip title="Delete" onClick={() => handleOpenDeleteDialog(item)}>
                                        <IconButton size="small">
                                            <FiTrash2
                                                size={15}
                                                style={{ strokeWidth: '3px', color: 'black' }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-full flex flex-row">
                            <div className="w-1/2 p-4 h-max-[312px] overflow-auto">
                                {item.device_count > 0 ? (
                                    <Typography sx={{ paddingLeft: '2px' }}>Assigned Devices</Typography>
                                ) : (
                                    ''
                                )}
                                <LocationDeviceList
                                    locationDetail={locationDetail}
                                    currentUser={currentUser}
                                    triggerUpdate={triggerUpdate}
                                    setTriggerUpdate={setTriggerUpdate}
                                />
                            </div>
                            <div className="w-1/2 p-4  ">
                                {item.campaign.length > 0 ? (
                                    <>
                                        <Typography sx={{ paddingLeft: '2px' }}>
                                            Assigned Campaigns
                                        </Typography>
                                        <LocationCampaignList campaign={item.campaign} />
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            {openLocation && (
                <LocationDialog
                    openLocation={openLocation}
                    handleCloseLocationDialog={handleCloseLocationDialog}
                    locationDetail={locationDetail}
                    currentUser={currentUser}
                    setTriggerUpdate={setTriggerUpdate}
                    triggerUpdate={triggerUpdate}
                    setLocationDetail={setLocationDetail}
                    text={'Edit'}
                />
            )}
            {openDeleteDialog && (
                <DeleteDialog
                    openDialog={openDeleteDialog}
                    handleCloseDialog={handleCloseDeleteDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={'location'}
                    deleteItem={deleteItem}
                    triggerUpdate={triggerUpdate}
                />
            )}
        </>
    );
};

export default Locations;
