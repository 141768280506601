import { TbBolt, TbCloudUpload, TbFileCheck } from 'react-icons/tb';

import { BiErrorCircle } from 'react-icons/bi';
import { LinearProgress } from '@mui/material';
import React from 'react';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#0444AB' : '#0444AB',
    },
}));
const UploadedFilesTable = ({ imageURLs, uploadFailed, uploadFailedMsg }) => {
    // calculate file size
    function formatFileSize(fileSizeInBytes) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let size = fileSizeInBytes;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${size.toFixed(2)} ${units[unitIndex]}`;
    }

    const UploadIcon = ({ size, color, uploadStatus }) => {
        if (!uploadFailed) {
            if (uploadStatus === 'Ready to upload' || uploadStatus === 'Uploading') {
                return <TbCloudUpload size={size} color={color} style={{ marginTop: '4px' }} />;
            } else if (uploadStatus === 'Added to the folder') {
                return (
                    <TbFileCheck
                        size={size}
                        color="#0BDA51"
                        style={{ marginTop: '4px', paddingTop: '4px' }}
                    />
                );
            } else {
                return <TbBolt size={size} color={color} style={{ marginTop: '4px' }} />;
            }
        } else {
            return <BiErrorCircle size={size} color="red" style={{ marginTop: '4px' }} />;
        }
    };
    return (
        <table className="w-full ">
            <thead>
                <tr className="upload-table-header">
                    <th className="w-1/3 text-lg  px-2 pt-2">Uploaded files</th>
                    <th className="w-1/6 text-lg px-2 pt-2">Size</th>
                    <th className="w-1/2 text-lg px-2 pt-2">Status</th>
                </tr>
            </thead>
            <tbody>
                {imageURLs.map((imageSrc, index) => (
                    <tr key={imageSrc.file_name}>
                        <td className="flex align-middle px-2 py-1" style={{ maxWidth: '326px' }}>
                            <p
                                className="truncate mb-0 mt-2"
                                style={{
                                    maxWidth: '326px',
                                }}
                            >
                                {imageSrc.file_name}
                            </p>
                        </td>
                        <td className=" px-2" style={{ maxWidth: '100px' }}>
                            <p
                                className="truncate mb-0 mt-2"
                                style={{
                                    maxWidth: '100px',
                                }}
                            >
                                {formatFileSize(imageSrc.file_size)}
                            </p>
                        </td>
                        <td className="px-2" style={{ maxWidth: '426px' }}>
                            <div className="w-full flex flex-row">
                                <div className="w-[40px] mr-2">
                                    <UploadIcon
                                        size={30}
                                        color="#0444AB"
                                        uploadStatus={imageSrc.uploadStatus}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className={`m-0 ${uploadFailed && 'text-red-500'}`}>
                                        {!uploadFailed ? imageSrc.uploadStatus : uploadFailedMsg}
                                    </p>
                                    <BorderLinearProgress
                                        variant="determinate"
                                        value={imageSrc.progress}
                                        sx={{
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor:
                                                    imageSrc.progress === 100 &&
                                                    imageSrc.uploadStatus === 'Added to the folder'
                                                        ? '#00FF40'
                                                        : undefined,
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default UploadedFilesTable;
