import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
// import { FaList } from 'react-icons/fa';
import { FaList, FaSearch, FaThLarge } from 'react-icons/fa';
import { GetCampaignList, GetCampaignPlaybackGroupList, GetScheduleUseCampaignNum } from '../api/api';
// import { CopyDialog, DeleteDialog } from '../components/dashboard/utils';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '../components/dashboard/Tabs';

import { CampaignList } from '../components/dashboard/campaign';
import { CampaignPlaylist } from '../components/dashboard/campaign-playlist';
import { Header } from '../components/dashboard';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const CampaignManager = () => {
    const { currentUser, screenSize } = useStateContext();
    //console.log('CampaignManager => screenSize:', screenSize);
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    //console.log('CampaignManager => switchUser:', switchUser);
    const [active, setActive] = useState('list');
    const [activeTab, setActiveTab] = useState('');

    const handleClick = (iconName) => {
        setActive(iconName);
    };
    const navigate = useNavigate();
    const handleAddCampaign = () => {
        navigate('../dashboard/playlist/template');
    };

    const [campaignList, setCampaignList] = useState([]);
    //console.log('campaignList', campaignList);
    const FetchCampaignList = async () => {
        const { code, data } = await GetCampaignList(switchUser, undefined, 50);
        //console.log('data:', data);
        const list = data?.list;
        if (code === 0) {
            Array.isArray(list) ? setCampaignList(list) : setCampaignList([]);
        }
    };
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    useEffect(() => {
        FetchCampaignList();
    }, [triggerUpdate]);

    // Handle Expand area
    const [expanded, setExpanded] = useState('');

    const handleExpand = (panel) => (newExpanded) => {
        if (expanded !== panel) {
            setExpanded(newExpanded ? panel : false);
            //console.log('panel', panel);
            setLocationList([]);
            getLocationList(panel);
        } else setExpanded();

        // getLocationDetails(panel.group_id);
    };

    // Get list of Locations under campaign
    const [locationList, setLocationList] = useState([]);
    //console.log('locationList', locationList);

    // From API get data
    const getLocationList = async (panel) => {
        const data = await GetCampaignPlaybackGroupList(switchUser, panel.campaign_id);
        //console.log('data', data);
        setLocationList(data.data.list);
    };

    /**
     * Handle search function
     * This function processes the search input to filter the campaign list.
     * It ignores the case of the input, making the search case-insensitive.
     *
     * @param {ChangeEvent<HTMLInputElement>} e - The change event from the search input
     */

    const [searchedCampList, setSearchedCampList] = useState([]);
    const handleSearchOnChange = (e) => {
        const value = e.target.value.toLowerCase().trim();
        //console.log('handleSearchOnChange => value:', value);
        //console.log('handleSearchOnChange => value:', typeof value);
        const tempList = [...campaignList];
        if (value === '') {
            setSearchedCampList(tempList);
        } else {
            const result = tempList.filter((camp) => camp.campaign_name.toLowerCase().includes(value));
            setSearchedCampList(result);
        }
    };

    const [popUpInfo, setPopUpInfo] = useState();
    const [type, setType] = useState('schedule');
    // const handleGetNumber = async (item) => {
    //     // var data;
    //     // const lowerCaseText = text?.toLowerCase();
    //     // if (lowerCaseText === 'media') {
    //     const data = await GetScheduleUseCampaignNum(item?.resource_id);
    //     // } else {
    //     //     data = await GetMediaFolderCampaignUseNum(item?.folder_id);
    //     // }

    //     //console.log('data:', data.data);
    //     const total = data.data.total;
    //     if (total !== 0) {
    //         setPopUpInfo(
    //             data.data.total + ' campaign are using media',
    //             // +
    //             // (lowerCaseText !== 'media'
    //             //     ? ' files in this folder.'
    //             //     : '. \n Delete this media file will also delete the related campaigns & schedules. Are you sure to continue?'),
    //         );
    //     } else setPopUpInfo(' ');
    // };

    return (
        <>
            <div className="m-10 md:m-10 mt-8 p-2 md:p-10">
                <div className="w-full columns-2 flex justify-between">
                    <Header title="Campaign Manager" />
                    <div className="mr-4 p-3 pt-3.5">
                        <Button variant="contained" onClick={handleAddCampaign}>
                            Add Campaign
                        </Button>
                    </div>
                </div>
                <div className="pt-0 px-4">
                    <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
                        {/* TODO： */}
                        {/* <Tab label="Playlist">
                            <div className="mt-4 h-[650px]">
                                <CampaignPlaylist campaignList={campaignList} />
                            </div>
                        </Tab> */}
                        <Tab label="Campaigns">
                            <div className="flex flex-row justify-between">
                                <div className="p-4 pt-0">
                                    <TextField
                                        id="outlined-start-adornment"
                                        sx={{ width: '25ch' }}
                                        placeholder="Search"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FaSearch />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => handleSearchOnChange(e)}
                                    />
                                </div>
                                <div className="flex flex-row mr-4 p-3 pt-3.5">
                                    <div className="m-2">
                                        <IconButton
                                            aria-label="list"
                                            size="small"
                                            onClick={() => handleClick('list')}
                                            // 如果当前激活的是list，则改变颜色
                                            color={active === 'list' ? 'primary' : 'black'}
                                        >
                                            <FaList />
                                        </IconButton>
                                    </div>
                                    <div className="m-2">
                                        <IconButton
                                            aria-label="card"
                                            size="small"
                                            onClick={() => handleClick('card')}
                                            // 如果当前激活的是list，则改变颜色
                                            color={active === 'card' ? 'primary' : 'black'}
                                        >
                                            <FaThLarge />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            {/* {screenSize < 1512 ? (screenSize < 970 ? (screenSize < 700 ? 1 : 2) : 3) : 4} */}
                            <div
                                className={`p-4 pt-0 w-full ${
                                    active !== 'list'
                                        ? screenSize < 1512
                                            ? screenSize < 970
                                                ? screenSize < 700
                                                    ? 'grid grid-cols-1 gap-2'
                                                    : 'grid grid-cols-2 gap-2'
                                                : 'grid grid-cols-3 gap-2'
                                            : 'grid grid-cols-4 gap-2'
                                        : ''
                                }`}
                            >
                                {(searchedCampList.length > 0 ? searchedCampList : campaignList).map(
                                    (item, index) => (
                                        <CampaignList
                                            key={item.campaign_id}
                                            item={item}
                                            locationList={locationList}
                                            expanded={expanded}
                                            handleExpand={handleExpand}
                                            currentUser={currentUser}
                                            switchUser={switchUser}
                                            // handleCopyCampaign={handleCopyCampaign}
                                            // handleDeleteCampaign={handleDeleteCampaign}
                                            triggerUpdate={triggerUpdate}
                                            setTriggerUpdate={setTriggerUpdate}
                                            type={active}
                                        />
                                    ),
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default CampaignManager;
