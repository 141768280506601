import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import {
    MdAppSettingsAlt,
    MdAutorenew,
    MdLogoDev,
    MdLogout,
    MdOutlineAccountCircle,
    MdOutlineAssignment,
    MdOutlinePayments,
    MdOutlineSubscriptions,
} from 'react-icons/md';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';

const MenuItemStyle = {
    display: 'flex',
    justifyContent: 'left',
    padding: 1.5,
    '&:hover': {
        color: 'primary.main', // 或使用你的自定义颜色
    },
};
const UserMenu = ({ anchorEl, open, handleClose }) => {
    const { setCurrentUser, currentUser } = useStateContext();
    const navigate = useNavigate();

    // Switch User name
    const username = JSON.parse(localStorage.getItem('switchUserName'));
    /**
     *
     * dropdown menu
     *
     */
    const handleAccountSettings = () => navigate('/dashboard/account-settings');
    const handleContract = () => navigate('/dashboard/contract');
    const handlePayment = () => navigate('/dashboard/payment');
    const handleSubscription = () => navigate('/dashboard/subscription');
    const handleLog = () => navigate('/dashboard/log');
    const handleAdmin = () => navigate('/dashboard/admin');

    // Handle logout
    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        setCurrentUser('');
        navigate('/login', { replace: true });
    };

    const menuItems = [
        {
            text: 'Account Settings',
            icon: <MdOutlineAccountCircle fontSize="large" />,
            action: handleAccountSettings,
        },
        // {
        //     text: 'Contract',
        //     icon: <MdOutlineAssignment fontSize="large" />,
        //     action: handleContract,
        // },
        {
            text: 'Payment',
            icon: <MdAutorenew fontSize="large" />,
            action: handlePayment,
        },
        {
            text: 'Subscription',
            icon: <MdOutlineSubscriptions fontSize="large" />,
            action: handleSubscription,
        },
        // 判断条件可以直接在这里处理，若currentUser.user_level === 0则包含Log菜单项
        currentUser.user_level === 0 && {
            text: 'Log',
            icon: <MdLogoDev fontSize="large" />,
            action: handleLog,
        },
        currentUser.user_level === 0 && {
            text: 'Admin',
            icon: <MdAppSettingsAlt fontSize="large" />,
            action: handleAdmin,
        },
        {
            text: 'Logout',
            icon: <MdLogout fontSize="large" />,
            action: handleLogout,
        },
    ].filter(Boolean); // 这里使用.filter(Boolean)来过滤掉因条件判断而可能产生的false值

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    mt: 1.5,
                    ml: 1.5,
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '15px',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        zIndex: 999,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        // transform: 'translateY(-50%) rotate(45deg)',
                        // zIndex: 0,
                    },
                },
            }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {menuItems.map(({ text, icon, action }) => (
                <MenuItem key={text} sx={MenuItemStyle} onClick={action}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    {text}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default UserMenu;
