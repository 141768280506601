import React from 'react';

import { Header } from '../components/dashboard';

const PublishCampaign = () => {
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            <Header title="Publish Campaign" />
        </div>
    );
};

export default PublishCampaign;
