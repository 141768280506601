import axios from 'axios';
import config from '../config/config';
import { setLastApiCallTime } from './TokenManager';

const navigateToLogin = () => {
    //console.log('Redirecting to login...');
    // Replace the line below with the correct path to your login route
    window.location.href = '/login';
};

const api = axios.create({
    baseURL: config[process.env.REACT_APP_ENV]?.REACT_APP_API_URL,
    headers: {
        'Content-Type': ' application/json',
        Accept: 'application/json',
    },
});

// Request interceptors
api.interceptors.request.use(
    (config) => {
        // const { setLastApiCall } = useStateContext();
        // Add token to headers before sending request
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        setLastApiCallTime(Date.now());
        // Check if rhe token is required
        if (token && needsToken(config.url)) {
            config.headers['token'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Response interceptors
api.interceptors.response.use(
    (response) => {
        // check response code, like 801
        if (response.data && response.data.code === 801) {
            // invalid token
            navigateToLogin();
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Helper function to check if token is required
function needsToken(url) {
    // Combine multiple conditions into a single string
    const excludedEndpoints = [
        'login/login',
        'login/register',
        'login/retrieve_password',
        '/tools/send_email',
        'tools/country_code_list',
        'login/retrieve_password',
    ];

    const shouldExclude = excludedEndpoints.some((endpoint) => url.includes(endpoint));

    // Return the result
    return !shouldExclude;
}

/**
 *
 * Home pages
 * Contact US
 *
 */
// POST tools/send_email
export var ContactUs = async (name, email, subject, content) => {
    const json = {
        name: name,
        email: email,
        subject: subject,
        content: content,
    };
    // await api.post('device/add', json,{ requires false });
    let result = await api
        .post('tools/send_email', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 * Upload file
 *
 */

//Upload file
export var SimpleUpload = async (switch_user_id, upload_file_list) => {
    //console.log('switch_user_id:', switch_user_id);
    let formData = new FormData();
    formData.append('upload_scene', 'media_resource');
    formData.append('storage_location', 1);
    switch_user_id && formData.append('switch_user_id', switch_user_id);
    upload_file_list.forEach((file) => {
        formData.append('upload_file_list[]', file);
    });
    // Display the key/value pairs
    for (var pair of formData.entries()) {
        //console.log(pair[0] + ', ' + pair[1]);
    }

    let result = await api
        .post('tools/simple_upload', formData)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getSegmentUploadBatchSn
// tools/segment_upload_batch_sn
export var GetBatchNumber = async (switch_user_id) => {
    const json = { ...(switch_user_id && { switch_user_id: switch_user_id }) };
    //console.log('file: Api.js:68 => GetBatchNumber => switch_user_id:', switch_user_id);

    let result = await api
        .get('tools/segment_upload_batch_sn', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// segmentUpload
// POST tools/segment_upload
export var SegmentUpload = async (
    switch_user_id,
    upload_scene,
    storage_location,
    upload_batch_sn,
    upload_file_md5,
    upload_file_name,
    upload_chunk_file,
    upload_chunk_index,
    upload_chunk_count,
) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        upload_scene: upload_scene,
        storage_location: storage_location,
        upload_batch_sn: upload_batch_sn,
        upload_file_md5: upload_file_md5,
        upload_file_name: upload_file_name,
        upload_chunk_file: upload_chunk_file,
        upload_chunk_index: upload_chunk_index,
        upload_chunk_count: upload_chunk_count,
    };
    //console.log('file: Api.js:109 => json:', json);

    let result = await api
        .post('tools/segment_upload', json, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
// cancelSegmentUpload
// POST tools/cancel_segment_upload
export var CancelSegmentUpload = async (switch_user_id, upload_batch_sn) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        upload_batch_sn: upload_batch_sn, //required
    };
    //console.log('file: Api.js:109 => json:', json);

    let result = await api
        .post('tools/cancel_segment_upload', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
/**
 *
 *
 * Login
 *
 *
 */

//Login
export var RequestLogin = async (username, password) => {
    const json = { username: username, password: password };

    console.log('RequestLogin => json:', json);
    let result = await api
        .post('login/login', json)
        .then(function (response) {
            console.log('response:', response);
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//Register
export var RequestRegister = async (username, password, confirm_password, email, country_code, phone) => {
    const json = {
        username: username,
        password: password,
        confirm_password: confirm_password,
        email: email,
        country_code: country_code,
        phone_number: phone,
    };
    //console.log('file: Api.js:170 => RequestRegister => json:', json);

    let result = await api
        .post('login/register', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// retrieve_password
// POST login/retrieve_password
export var RetrievePassword = async (email) => {
    const json = {
        email: email,
    };
    //console.log('RetrievePassword => json:', json);

    let result = await api
        .post('login/retrieve_password', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// log out
// POST login/login_out
export var Logout = async () => {
    let result = await api
        .post('login/login_out')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getCountryCodeList
// GET tools/country_code_list
export var GetCountryCodeList = async () => {
    let result = await api
        .get('tools/country_code_list')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// doubleLoginValidate -用户登录----双重登录验证
export var doubleLoginValidate = async (verify_code) => {
    const json = { verify_code: verify_code };

    let result = await api
        .post('login/double_login_validate', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 * POST backstage/login/update_token_expire
 * @returns  ok
 *
 */
export var UpdateTokenExpire = async () => {
    //console.log('\n============= update token ================\n');
    let result = await api
        .post('login/update_token_expire')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 * Device Section
 *
 *
 **/

//Add Device
export var AddDevice = async (pin, expire_month, model, switch_user_id) => {
    const json = {
        pin: pin,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(expire_month && { expire_month: expire_month }),
        ...(model && { model: model }),
    };

    //console.log('AddDevice:', json);

    let result = await api
        .post('device/add', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//edit Device
export var EditDevice = async (
    device_id,
    device_name,
    mac_address,
    group_id,
    time_zone,
    rotation,
    tv_channel,
    lock_campaign,
    is_auto_trun,
    is_auto_off,
    auto_trun_hour,
    auto_trun_min,
    auto_off_hour,
    auto_off_min,
    auto_start_cybercast,
    enable_pocket_edition,
    use_external_storage,
    enable_4K_video,
    capture_audience,
    soniq_campaign,
    display_watermark,
    watermark_position,
    restart_device,
    settings,
    note,
) => {
    const json = {
        device_id: device_id,
        device_name: device_name,
        group_id: group_id,
        time_zone: time_zone,
        rotation: rotation,
        tv_channel: tv_channel,
        lock_campaign: lock_campaign,
        is_auto_trun: is_auto_trun,
        is_auto_off: is_auto_off,
        auto_trun_hour: auto_trun_hour,
        auto_trun_min: auto_trun_min,
        auto_off_hour: auto_off_hour,
        auto_off_min: auto_off_min,
        auto_start_cybercast: auto_start_cybercast,
        enable_pocket_edition: enable_pocket_edition,
        use_external_storage: use_external_storage,
        enable_4K_video: enable_4K_video,
        capture_audience: capture_audience,
        soniq_campaign: soniq_campaign,
        display_watermark: display_watermark,
        watermark_position: watermark_position,
        restart_device: restart_device,
        ...(mac_address && { mac_address: mac_address }),
        ...(settings && { settings: settings }),
        ...(note && { note: note }),
    };

    //console.log('EditDevice:', json);

    let result = await api
        .post('device/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//Get Device information
export var GetDeviceInfo = async (deivce_id) => {
    const json = {
        device_id: deivce_id,
    };
    //console.log('file: Api.js:406 => GetDeviceInfo => json:', json);

    let result = await api
        .post('device/get_device', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//Add Location
// POST device/add_group
export var AddLocation = async (group_id, group_name, target_layout, device_id_list, switch_user_id) => {
    const json = {
        group_name: group_name,
        target_layout: target_layout,
        device_id_list: device_id_list,
        ...(group_id && { group_id: group_id }),
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('AddLocation:', json);

    let result = await api
        .post('device/add_group', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//edit Location
export var EditLocation = async (group_id, group_name, target_layout, device_id_list) => {
    const json = {
        group_id: group_id,
        group_name: group_name,
        target_layout: target_layout,
        device_id_list: device_id_list,
    };
    //console.log('EditLocation:', json);

    let result = await api
        .post('device/edit_group', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//delete Location
export var DeleteLocation = async (group_id) => {
    const json = {
        group_id: group_id,
    };

    let result = await api
        .post('device/del_group', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// get Location Info
export var GetLocationInfo = async (switch_user_id, group_id) => {
    const params = {
        group_id: group_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('file: api.js:457 => GetLocationInfo => params:', params);

    let result = await api
        .get('device/get_group', { params: params })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//removeDeviceAssign
export var RemoveDeviceAssigned = async (group_id, device_id) => {
    const json = {
        group_id: group_id,
        device_id: device_id,
    };

    let result = await api
        .post('device/remove_assign', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getCount
export var GetCount = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    let result = await api
        .post('device/get_count', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// /getDeviceGroupList/
export var GetLocationList = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('GetLocationList:', json);

    let result = await api
        .post('device/get_group_list', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// /getDeviceList
export var GetDeviceList = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('GetDeviceList:', json);

    let result = await api
        .post('device/get_device_list', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// deleteDevice
export var DeleteDevice = async (device_id) => {
    const json = {
        device_id: device_id,
    };

    let result = await api
        .post('device/del_device', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//deleteGroupLocation
export var DeleteGroupLocation = async (location_id) => {
    const json = {
        location_id: location_id,
    };

    let result = await api
        .post('device/delete_location', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getContractList
// GET device/get_contract_list
export var GetContractList = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('GetContractList:', json);

    let result = await api
        .get('device/get_contract_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getDeviceDetail
// GET device/get_schedule_json
export var GetDeviceDetail = async (device_id) => {
    const json = { device_id: device_id };

    //console.log('DeviceDetail:', json);

    let result = await api
        .get('device/get_schedule_json', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getContractListByDeviceId
export var GetContractListByDeviceId = async (device_id) => {
    const json = { device_id: device_id };

    //console.log('getContractListByDeviceId:', json);

    let result = await api
        .get('device/getContractListByDeviceId', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            //console.log('GetContractListByDeviceId => response:', response);
        });

    return result;
};
/**
 *
 *
 * Template Section
 *
 *
 **/
//getTemplateGroupList
export var GetTemplateGroupList = async () => {
    const json = {};

    let result = await api
        .get('template/group_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getTemplateList
export var GetTemplateList = async (switch_user_id, group_type, page, size) => {
    const json = {
        group_type: group_type,
        ...(page && { page: page }),
        ...(size && { size: size }),
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('GetTemplateList:', json);

    let result = await api
        .get('template/list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getTemplateElementTypeList
export var GetTemplateElementTypeList = async () => {
    let result = await api
        .get('template/element_type_list')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// Check if the template is in using
// template/campaign_name_list
export var isTemplateInUse = async (template_id) => {
    const json = {
        template_id: template_id,
    };
    //console.log('\n======== json ==========\n', json);
    let result = await api
        .get(
            'template/campaign_name_list',

            { params: json },
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//addTemplate
export var AddTemplate = async (
    template_name,
    template_width,
    template_height,
    groupType,
    element_layout,
    element_list,
    // element_tag_event_list,
) => {
    const json = {
        template_name: template_name,
        template_width: template_width,
        template_height: template_height,
        template_type: groupType,
        element_layout: element_layout,
        element_list: element_list,
        // element_tag_event_list: element_tag_event_list,
    };
    //console.log('AddTemplate', json);

    let result = await api
        .post('template/add', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//updateTemplate
export var UpdateTemplate = async (
    switch_user_id,
    template_id,
    element_layout,
    template_name,
    template_width,
    template_height,
    element_list,
    // element_tag_event_list,
) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        template_id: template_id,
        element_layout: element_layout,
        template_name: template_name,
        template_width: template_width,
        template_height: template_height,
        element_list: element_list,
        // element_tag_event_list: element_tag_event_list,
    };
    //console.log('UpdateTemplate:', json);

    let result = await api
        .post('template/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//deleteTemplate
export var DeleteTemplate = async (switch_user_id, template_id) => {
    const json = {
        template_id: template_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    let result = await api
        .post('template/delete', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});
    //console.log('result', result);

    return result;
};

//copyTemplate
export var CopyTemplate = async (switch_user_id, template_id) => {
    const json = {
        template_id: template_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('CopyTemplate:', json);

    let result = await api
        .post('template/copy', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//favouriteTemplate
export var FavouriteTemplate = async (switch_user_id, template_id, template_favourite) => {
    const json = {
        template_id: template_id,
        template_favourite: template_favourite, //操作标识、此参数必传、0为取消模板收藏、1为添加模板收藏
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    let result = await api
        .post('template/favourite', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getTemplateElementTagEffectList
// GET backstage/template/element_tag_effect_list
export var GetTemplateTagList = async () => {
    let result = await api
        .get('template/element_tag_effect_list')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 * Campaign Section
 *
 *
 **/
//getCampaignList
export var GetCampaignList = async (switch_user_id, page, size) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(page && { page: page }),
        ...(size && { size: size }),
    };
    //console.log('GetCampaignList:', json);
    let result = await api
        .get('campaign/list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getCampaignPlaybackGroupList
export var GetCampaignPlaybackGroupList = async (switch_user_id, campaign_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        campaign_id: campaign_id,
    };

    let result = await api
        .get('campaign/playback_group_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getCampaignTransitionEffectList
export var GetCampaignTransitionEffectList = async () => {
    const json = {
        lang: 'en',
    };
    //console.log('GetCampaignTransitionEffectList', json);

    let result = await api
        .get('campaign/transition_effect_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getCampaignResizeModeList
// GET campaign/resize_mode_list
export var GetCampaignResizeModeList = async () => {
    let result = await api
        .get('campaign/resize_mode_list')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//addCampaign
export var AddCampaign = async (
    switch_user_id,
    template_id,
    campaign_name,
    free_campaign,
    payment_price,
    detail_list,
) => {
    const json = {
        template_id: template_id,
        campaign_name: campaign_name,
        free_campaign: free_campaign,
        payment_price: payment_price,
        detail_list: detail_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('AddCampaign:', json);
    let result = await api
        .post('campaign/add', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getCampaignDetail
export var getCampaignDetail = async (switch_user_id, campaign_id) => {
    const json = {
        campaign_id: campaign_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('getCampaignDetail', json);

    let result = await api
        .get('campaign/detail', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//updateCampaign
export var UpdateCampaign = async (switch_user_id, campaign_id, campaign_name, detail_list) => {
    const json = {
        campaign_name: campaign_name,
        detail_list: detail_list,
        campaign_id: campaign_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('updateCampaign:', json);
    let result = await api
        .post('campaign/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//deleteCampaign
export var deleteCampaign = async (switch_user_id, campaign_id) => {
    const json = {
        campaign_id: campaign_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('jsdeleteCampaignon', json);

    let result = await api
        .post('campaign/delete', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//CopyCampaign
export var CopyCampaign = async (switch_user_id, campaign_id) => {
    const json = {
        campaign_id: campaign_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('CopyCampaign:', json);

    let result = await api
        .post('campaign/copy', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 *
 * Media Manager section
 *
 *
 */
//getMediaFolderList
export var GetMediaFolderList = async (switch_user_id, page, size) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(page && { page: page }),
        ...(size && { size: size }),
    };

    let result = await api
        .get('media/folder_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//addMediaFolder
export var AddMediaFolder = async (folder_name) => {
    const json = {
        folder_name: folder_name,
    };

    let result = await api
        .post('media/add_folder', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});
    return result;
};

//updateMediaFolder
export var UpdateMediaFolder = async (switch_user_id, folder_id, folder_name) => {
    const json = {
        folder_id: folder_id,
        folder_name: folder_name,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('UpdateMediaFolder:', json);

    let result = await api
        .post('media/update_folder', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//deleteMediaFolder
export var DeleteMediaFolder = async (switch_user_id, folder_id) => {
    const json = {
        folder_id: folder_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('deleteMediaFolder:', json);

    let result = await api
        .post('media/delete_folder', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getMediaResourceList
export var GetMediaResourceList = async (switch_user_id, folder_id, resource_type, page, size) => {
    const json = {
        folder_id: folder_id,
        resource_type: resource_type,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(page && { page: page }),
        ...(size && { size: size }),
    };
    //console.log('GetMediaResourceList:', json);

    let result = await api
        .get('media/resource_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//addMediaResource
export var AddMediaResource = async (switch_user_id, folder_id, resource_source, resource_list) => {
    const json = {
        folder_id: folder_id,
        resource_source: resource_source,
        resource_list: resource_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('AddMediaResource:', json);

    let result = await api
        .post('media/add_resource', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//deleteMediaResource
export var DeleteMediaResource = async (switch_user_id, folder_id, resource_id) => {
    const json = {
        folder_id: folder_id,
        resource_id: resource_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('DeleteMediaResource:', json);

    let result = await api
        .post('media/delete_resource', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getMediaCampaignUseNum
export var GetMediaCampaignUseNum = async (resource_id) => {
    const json = {
        resource_id: resource_id,
    };
    //console.log('getMediaCampaignUseNum:', json);

    let result = await api
        .post('media/media_use_num', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//getMediaFolderCampaignUseNum
export var GetMediaFolderCampaignUseNum = async (folder_id) => {
    const json = {
        folder_id: folder_id,
    };
    //console.log('GetMediaFolderCampaignUseNum:', json);

    let result = await api
        .post('media/media_folder_use_num', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 * Schedule
 *
 *
 */

//getScheduleList
export var GetScheduleList = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    let result = await api
        .get('schedule/list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getScheduleFrequencyList;
export var GetScheduleFrequencyList = async () => {
    let result = await api
        .get('schedule/schedule_frequency_list')
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//addSchedule
export var AddSchedule = async (
    switch_user_id,
    schedule_type,
    group_id,
    schedule_name,
    start_date,
    end_date,
    until_date,
    schedule_frequency,
    detail_list,
) => {
    const json = {
        schedule_type: schedule_type,
        group_id: group_id,
        schedule_name: schedule_name,
        start_date: start_date,
        end_date: end_date,
        until_date: until_date,
        schedule_frequency: schedule_frequency,
        detail_list: detail_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('AddSchedule', json);

    let result = await api
        .post('schedule/add', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getScheduleDetail
// schedule/detail
export var GetScheduleDetail = async (switch_user_id, schedule_id) => {
    const json = {
        schedule_id: schedule_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('GetScheduleDetail:', json);

    let result = await api
        .get('schedule/detail', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// updateSchedule
// POST schedule/update
export var UpdateSchedule = async (
    switch_user_id,
    schedule_id,
    schedule_name,
    start_date,
    end_date,
    until_date,
    schedule_frequency,
    detail_list,
) => {
    const json = {
        schedule_id: schedule_id,
        schedule_name: schedule_name,
        start_date: start_date,
        end_date: end_date,
        until_date: until_date,
        schedule_frequency: schedule_frequency,
        detail_list: detail_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('UpdateSchedule', json);

    let result = await api
        .post('schedule/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// deleteSchedule
// POST schedule/delete
export var DeleteSchedule = async (switch_user_id, schedule_id) => {
    const json = {
        schedule_id: schedule_id,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('DeleteSchedule', json);
    let result = await api
        .post('schedule/delete', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// copySchedule
// POST schedule/copy
export var CopySchedule = async (switch_user_id, schedule_id, group_id_list) => {
    const json = {
        schedule_id: schedule_id,
        group_id_list: group_id_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('CopySchedule', json);

    let result = await api
        .post('schedule/copy', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// editScheduleSort;
// POST schedule/edit_schedule_sort
export var EditScheduleSort = async (switch_user_id, group_id, schedule_id_list) => {
    const json = {
        group_id: group_id,
        schedule_id_list: schedule_id_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    let result = await api
        .post('schedule/edit_schedule_sort', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
/**
 *
 *
 * User
 *
 *
 */

// getUserInfo
// GET user/info
export var GetUserInfo = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };
    //console.log('file: api.js:1827 => GetUserInfo => json:', json);
    let result = await api
        .get('user/info', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// updateUserInfo-编辑登录用户信息【兼容切换用户】
/**
 *
 * @param {* integer} switch_user_id
 * @param {* string} username
 * @param {* string} email
 * @param {* string} country_code
 * @param {* string} phone_number
 * @returns
 */
export var UpdateUserProfile = async (switch_user_id, username, email, country_code, phone_number) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(username && { username: username }),
        ...(email && { email: email }),
        ...(country_code !== '' && { country_code: country_code }),
        ...(phone_number !== '' && { phone_number: phone_number }),
    };
    //console.log('UpdateUserProfile', json);

    let result = await api
        .get('user/update_info', { params: json })
        .then(function (response) {
            //console.log('response:', response);
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
// getMenuList
// GET user/menu_list
export var GetMenuList = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    let result = await api
        .get('user/menu_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getUserList
// GET user/list
// scene=> 1: list 2: dropdown box
export var GetUserList = async (switch_user_id, filter_user, scene, page, size) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(filter_user && { filter_user: filter_user }),
        ...(page && { page: page }),
        ...(size && { size: size }),
        ...(scene && { scene: scene }),
    };

    //console.log('GetUserList', json);

    let result = await api
        .get('user/list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getUserDetail
// GET user/detail
export var GetUserDetail = async (user_id) => {
    const json = {
        user_id: user_id,
    };

    //console.log('json', json);

    let result = await api
        .get('user/detail', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// addUser
// POST user/add
export var AddUser = async (
    switch_user_id,
    name,
    username,
    password,
    confirm_password,
    email,
    status,
    customer_type,
    permission_id_list,
) => {
    const json = {
        name: name,
        username: username,
        password: password,
        confirm_password: confirm_password,
        email: email,
        status: status,
        customer_type: customer_type,
        permission_id_list: permission_id_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('AddUser', json);

    let result = await api
        .post('user/add', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// updateUser
// POST user/update
export var UpdateUser = async (
    switch_user_id,
    user_id,
    name,
    username,
    password,
    confirm_password,
    email,
    status,
    permission_id_list,
) => {
    const json = {
        user_id: user_id,
        name: name,
        username: username,
        // password: password,
        // confirm_password: confirm_password,
        email: email,
        status: status,
        permission_id_list: permission_id_list,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        ...(password !== '' && { password: password }),
        ...(confirm_password !== '' && { confirm_password: confirm_password }),
    };
    //console.log('UpdateUser', json);

    let result = await api
        .post('user/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// using current password to modify Password
//POST user/modify_password
export var ChangePassword = async (current_password, new_password, confirm_new_password) => {
    const json = {
        current_password: current_password,
        new_password: new_password,
        confirm_new_password: confirm_new_password,
    };

    //console.log('json', json);

    let result = await api
        .post('user/modify_password', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// Reset the Password
//POST login/change_password
export var ResetPassword = async (code, password) => {
    const json = {
        code: code,
        password: password,
        // confirm_new_password: confirm_new_password,
    };

    //console.log('json', json);

    let result = await api
        .post('login/change_password', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 * Profile section
 *
 *
 */
// getCompanyDetail
// GET company/detail
export var GetCompanyDetail = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('GetCompanyDetail', json);

    let result = await api
        .get('company/detail', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// updateCompany
// POST company/update
export var UpdateCompany = async (
    switch_user_id,
    company_id,
    acc_num,
    business_name,
    business_phone,
    business_email,
    business_web,
    address1,
    address2,
    suburb,
    state,
    country,
    postcode,
    contact_name,
    contact_position,
    contact_mobile,
    contact_email,
    web_background,
) => {
    const json = {
        company_id: company_id,
        company_account: acc_num,
        company_name: business_name,
        company_phone: business_phone,
        company_email: business_email,
        company_website: business_web,
        company_address_one: address1,
        company_address_two: address2,
        company_region: suburb,
        company_province_city: state,
        company_country: country,
        postal_code: postcode,
        contact_name: contact_name,
        contact_position: contact_position,
        contact_mobile: contact_mobile,
        contact_email: contact_email,
        web_background: web_background,
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('UpdateCompany', json);

    let result = await api
        .post('company/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getPermissionGroupList
// GET permission/group_list
export var GetPermissionGroupList = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('json', json);

    let result = await api
        .get('permission/group_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 * AWS APIs
 *
 *
 */

// const newBaseUrl = 'https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/ttt';
const newApi = axios.create({
    baseURL: 'https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/ttt',
});
//get User device
export var GetUserDevice = async (user_id) => {
    const json = {
        userId: user_id,
    };
    let result = await newApi
        .post('/device', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

//get device contract
export var GetDeviceContract = async (deviceId) => {
    const json = {
        deviceId: deviceId,
    };
    let result = await newApi
        .post('/device', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 * Log section
 *
 */
// getOperationLogList
// GET tools/operation_log_list
export var GetOperationLogList = async (page, size) => {
    const json = {
        page: page,
        size: size,
    };

    //console.log('json', json);

    let result = await api
        .get('tools/operation_log_list', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 *  Map function
 *
 *
 */
// getDeviceLocation-获取当前用户所有设备的位置经纬度信息列表
// GET device/get_device_location

export var GetDeviceLocation = async (switch_user_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
    };

    //console.log('json', json);

    let result = await api
        .get('device/get_device_location', { params: json })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/***
 *
 *
 * Two-factor authentication
 *
 *
 */

/**
 *
 * operateDoubleLoginValidate - 操作用户双重登录验证;
 * POST backstage/user/operate_double_login_validate
 * @param {* string required} password
 * @param {* integer requried} double_switch 1为开启双重登录验证、0为关闭双重登录验证
 * @param {* string optional} verify_code
 * @returns
 */
export var SwitchTwoFactorAuthentication = async (password, double_switch, verify_code) => {
    const json = {
        password: password,
        double_switch: double_switch,
        ...(verify_code && { verify_code: verify_code }),
    };

    //console.log('json', json);

    let result = await api
        .post('/user/operate_double_login_validate', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 * sendVerifyCode-发送短信验证码
 * POST backstage/tools/send_verify_code


 * @param {*} code_scene
 * @returns
 */
export var SendVerifyCode = async (code_scene) => {
    const json = {
        code_scene: code_scene,
    };

    //console.log('json', json);

    let result = await api
        .post('/tools/send_verify_code', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 *  Configurations
 *
 *
 */
// POST backstage/user/operate_device_online_status_notification
/**
 *
 * @param {* Integer } notification_switch
 * 用户设备在线和离线状态通知开关、此参数必传，1为开启用户设备在线和离线状态通知、0为关闭用户设备在线和离线状态通知
 * @returns
 */
export var SwitchDeviceNotification = async (
    notification_switch,
    notification_option,
    notification_start_hour,
    notification_end_hour,
) => {
    const json = {
        notification_switch: notification_switch,
        notification_option: notification_option,
        notification_start_hour: notification_start_hour,
        notification_end_hour: notification_end_hour,
    };

    //console.log('json', json);

    let result = await api
        .post('/user/operate_device_online_status_notification', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

/**
 *
 *
 *  Playlist Section
 *
 *
 */
// getCampaignPlayList
/**
 *
 * @param {* Integer } switch_user_id
 * @param {* Integer } scene
 * @param {* String } play_name
 * @param {* Integer } page
 * @param {* Integer } size
 *
 * @returns
 */
export var GetCampaignPlaylist = async (switch_user_id, scene, play_name, page, size) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        scene: scene,
        play_name: play_name,
        page: page,
        size: size,
    };

    //console.log('json', json);

    let result = await api
        .post('/campaign/play/list', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// addCampaignPlay
/**
 *
 * @param {* Integer } switch_user_id
 * @param {* Integer } scene {*1. pagination; 2. no pagination}
 * @param {* String } play_name
 * @param {* Array } detail_list
 * detail_list: [{
 *      @param {* Integer } campaign_id
 *      @param {* Integer } second
 * }]
 *
 * @returns
 */
export var AddCampaignPlaylist = async (switch_user_id, scene, play_name, detail_list) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        scene: scene,
        play_name: play_name,
        detail_list: detail_list,
    };

    //console.log('json', json);

    let result = await api
        .get('/campaign/play/add', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// getCampaignPlayDetail
/**
 *
 * @param {* Integer } switch_user_id
 * @param {* Integer } play_id
 *
 * @returns
 */
export var GetCampaignPlaylistDetail = async (switch_user_id, play_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        play_id: play_id,
    };

    //console.log('json', json);

    let result = await api
        .get('/campaign/play/detail', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// updateCampaignPlay
/**
 *
 * @param {* Integer } switch_user_id
 * @param {* Integer } play_id
 * @param {* String } play_name
 * @param {* Integer } detail_list
 * detail_list: [{
 *      @param {* Integer } detail_id
 *      @param {* Integer } campaign_id
 *      @param {* Integer } second
 * }]
 *
 * @returns
 */
export var UpdateCampaignPlaylist = async (switch_user_id, play_id, play_name, detail_list) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        play_id: play_id,
        play_name: play_name,
        detail_list: detail_list,
    };

    //console.log('json', json);

    let result = await api
        .post('/campaign/play/update', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// copyCampaignPlay
/**
 *
 * @param {* Integer } switch_user_id
 * @param {* Integer } play_id
 *
 * @returns
 */
export var CopyCampaignPlaylist = async (switch_user_id, play_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        play_id: play_id,
    };

    //console.log('json', json);

    let result = await api
        .post('/campaign/play/copy', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// deleteCampaignPlay
/**
 *
 * @param {* Integer } switch_user_id
 * @param {* Integer } play_id
 * @param {* String } play_name
 * @param {* Integer } page
 * @param {* Integer } size
 *
 * @returns
 */
export var DeleteCampaignPlaylist = async (switch_user_id, play_id) => {
    const json = {
        ...(switch_user_id && { switch_user_id: switch_user_id }),
        play_id: play_id,
    };

    //console.log('json', json);

    let result = await api
        .post('/campaign/play/delete', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};

// // getCampaignPlayList
// /**
//  *
//  * @param {* Integer } switch_user_id
//  * @param {* Integer } scene
//  * @param {* String } play_name
//  * @param {* Integer } page
//  * @param {* Integer } size
//  *
//  * @returns
//  */
// export var GetCampaignPlaylist = async (switch_user_id, scene, play_name, page, size) => {
//     const json = {
//         ...(switch_user_id && { switch_user_id: switch_user_id }),
//         scene: scene,
//         play_name: play_name,
//         page: page,
//         size: size,
//     };

//      //console.log('json', json);

//     let result = await api
//         .post('/campaign/play/list', json)
//         .then(function (response) {
//             return response.data;
//         })
//         .catch(function (response) {});

//     return result;
// };

export var GetScheduleUseCampaignNum = async (campaign_id) => {
    const json = {
        // ...(switch_user_id && { switch_user_id: switch_user_id }),
        campaign_id: campaign_id,
    };

    //console.log('json', json);

    let result = await api
        .post('/campaign/campaign_use_num', json)
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {});

    return result;
};
