export const FormStyles = {
    labelText: {
        minWidth: '200px',
        display: 'flex',
        // textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
    label: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
        color: 'black',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    formControlStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: '54px',
    },
};
