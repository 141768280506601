import { BottomSignUp, ContactForm1, ContactForm2 } from '../components/website';
import React, { useState } from 'react';

import BG from '../images/contactusBg.png';
import Location from '../images/location.png';
import LocationBg from '../images/contactusBg.png';

function ContactUs() {
    const [activeTab, setActiveTab] = useState(2);

    return (
        <div className="flex flex-col items-center w-full ">
            <div
                className="relative w-full bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${BG})`, height: '295px' }}
            >
                {/* First Row */}
                <div className="relative max-w-3xl pt-25 text-center mx-auto px-2 ">
                    <h6 className="font-display font-bold text-4xl mb-3 md:text-5xl">
                        Contact Us{' '}
                    </h6>
                    <span>
                        Any questions or feedback? Just write us a message nd the appropriate team
                        will get back to you as soon as possible.
                    </span>
                </div>
                {/* Tabs */}
                <div className="relative max-w-6xl mt-8 mx-auto flex justify-center items-center z-30">
                    {/* Tab 1 */}
                    {/* <div
                        onClick={() => setActiveTab(1)}
                        className={`cursor-pointer rounded-r-xl w-1/2 text-center py-4 shadow  ${activeTab === 1 ? 'border-b-4 border-customPrimary z-10 bg-white' : 'bg-white'}`}
                    >
                        <h3 className='font-display font-bold text-2xl mb-2'>Sales Inquiries</h3>
                        <span>Contact sales about large deployments</span>
                    </div> */}

                    {/* Tab 2 */}
                    {/* <div
                        onClick={() => setActiveTab(2)}
                        className={`cursor-pointer rounded-t-xl w-2/3 text-center py-4 shadow ${activeTab === 2 ? 'border-b-4 border-customPrimary z-10 bg-white' : 'bg-white'}`}
                    >
                        <h3 className='font-display font-bold text-2xl mb-2'>Need Support?</h3>
                        <span>Open a support ticket and get help, fast</span>
                    </div> */}
                </div>
            </div>
            <div className="bg-white w-full pt-20">
                <div className="max-w-3xl  mx-auto ">
                    {/* Tab content */}
                    {activeTab === 1 && <ContactForm1 />}
                    {activeTab === 2 && <ContactForm1 />}
                </div>
            </div>
            {/* location */}
            <div className="w-full bg-white py-20 h-200">
                <div className="max-w-6xl mx-auto overflow-hidden">
                    <h6 className="font-display font-bold text-4xl md:text-5xl text-center mx-auto justify-center items-center mb-16">
                        Our Locations{' '}
                    </h6>
                    <div class="google-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.554478723573!2d145.11226997516832!3d-37.98752534398761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66ce7838f0ea9%3A0xbe4f98c37910d764!2s414%20Lower%20Dandenong%20Rd%2C%20Braeside%20VIC%203195!5e0!3m2!1sen!2sau!4v1696310221932!5m2!1sen!2sau"
                            width="1200"
                            height="600"
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>

            {/* <div className="w-full bg-cover bg-center bg-no-repeat py-20"
                style={{ backgroundImage: `url(${LocationBg})`, height: '730px' }}
            >
                <div className="max-w-4xl mx-auto">
                    <h6 className='font-display font-bold text-4xl md:text-5xl text-center mx-auto justify-center items-center'>
                        Our Locations </h6>
                        <div className="flex flex-col md:flex-row gap-10 my-20">
                            <div className="shadow-lg bg-white flex flex-col space-y-4 justify-between items-center text-center w-full mx-auto p-16 rounded-2xl" >
                                <img src={Location} alt="" className='w-16 h-16' />
                                <h3 className="font-display text-2xl font-bold">Australia</h3>
                                <p> 414 Lower Dandenong, Braeside Victoria 3195 </p>
                                <button className="font-display font-bold text-customPrimary bg-customBlue rounded-xl px-12 py-3">Read More →</button>
                            </div>
                            <div className="shadow-lg bg-white flex flex-col space-y-4 justify-between items-center text-center w-full mx-auto p-16 rounded-2xl" >
                                <img src={Location} alt="" className='w-16 h-16' />
                                <h3 className="font-display text-2xl font-bold">New zealand</h3>
                                <p>4/113 Pavilion Drive, Airport Oaks, Auckland 2022 </p>
                                <button className="font-display font-bold text-customPrimary bg-customBlue rounded-xl px-12 py-3">Read More →</button>
                            </div>
                    </div>
                </div>
            </div> */}

            {/*  Get started*/}
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
}
export default ContactUs;
