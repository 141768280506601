import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

const TextToAudio = () => {
    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4">AI Generate</Typography>
            <Box sx={{ textAlign: 'center' }}>
                <img src="/path/to/your/image.jpg" alt="Generated" style={{ maxWidth: '100%' }} />
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="A butterfly on the flower"
                    sx={{ my: 2 }}
                />
                <Button variant="contained" color="primary">
                    Generate111111111111111
                </Button>
            </Box>
        </Box>
    );
};

export default TextToAudio;
