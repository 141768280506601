/**
 * Add array of selected media
 *
 * @param {Array} media
 * @returns
 */

export const addMedia = (media) => {
	return {
		type: 'ADD_MEDIA',
		payload: media,
	};
};
export const updateMedia = (media) => {
	return {
		type: 'UPDATE_MEDIA',
		payload: media,
	};
};

export const resetMedia = () => ({
	type: 'RESET_MEDIA',
});
export const removeWeb = (media) => ({
	type: 'REMOVE_WEB',
	payload: media,
});
export const updateWeb = (media) => ({
	type: 'UPDATE_WEB',
	payload: media,
});
