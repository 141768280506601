import {
    Autocomplete,
    Button,
    ButtonGroup,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Slider,
    TextField,
} from '@mui/material';
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaBold, FaFont, FaItalic } from 'react-icons/fa';
import React, { useState } from 'react';

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { addMedia } from '../../../redux/action';
import { useDispatch } from 'react-redux';

const style = {
    labelText: {
        minWidth: '100px',
        display: 'flex',
        textAlign: 'start',
        paddingRight: '12px',
        // marginTop: '6px',
        fontSize: '14px',
        color: 'black',
    },
};
const fontSizeOptions = [
    {
        value: '30',
        label: '30',
    },
    {
        value: '36',
        label: '36',
    },
    {
        value: '48',
        label: '48',
    },
    {
        value: '56',
        label: '56',
    },
];

const CustomButton = ({ selected, ...props }) => (
    <Button
        {...props}
        sx={{
            backgroundColor: selected ? 'primary.main' : '',
            color: selected ? 'common.white' : 'primary.main',
            '&:hover': {
                // backgroundColor: selected ? 'common.white' : 'primary.main',
                color: selected ? 'primary.main' : 'common.white',
            },
        }}
    />
);

const AddTextDialog = ({ currentTextJSON, currentElement, openTextDialog, handleCloseTextDialog }) => {
    //console.log('file: AddTextDialog.jsx:133 => AddTextDialog => currentElement:', currentElement);
    // const notify = useNotification();
    const dispatch = useDispatch();

    const textData = currentTextJSON?.resource_text
        ? currentTextJSON?.resource_text
        : currentElement?.resource_list
        ? currentElement?.resource_list[0]?.resource_text
        : null;
    //console.log('file: AddTextDialog.jsx:111 => AddTextDialog => textData:', textData);
    // Action to ADD/DEL item to/from cart list
    const addItem = (item) => {
        //console.log('addItem', item);
        dispatch(addMedia(item));
    };
    const [fontColor, setFontColor] = useState(textData?.font_colour ? textData?.font_colour : '#000000');
    //console.log('file: AddTextDialog.jsx:119 => AddTextDialog => fontColor:', fontColor);
    const [enableBackgroundColor, setEnableBackgroundColor] = useState(
        textData?.no_background !== undefined ? textData?.no_background : 1,
    );
    //console.log(
    //     'file: AddTextDialog.jsx:149 => AddTextDialog => enableBackgroundColor:',
    //     enableBackgroundColor,
    // );

    const [backgroundColor, setBackgroundColor] = useState(
        textData?.background_color ? textData?.background_color : '#000000',
    );
    //console.log('file: AddTextDialog.jsx:121 => AddTextDialog => backgroundColor:', backgroundColor);
    const [selectedBold, setSelectedBold] = useState(textData?.font_style.includes('B') ? true : false);
    const [selectedItalic, setSelectedItalic] = useState(textData?.font_style.includes('I') ? true : false);
    const [selectedTextShadow, setSelectedTextShadow] = useState(
        textData?.font_style.includes('A') ? true : false,
    );
    const [selectedAlignLeft, setSelectedAlignLeft] = useState(
        textData?.text_layout === 'left' ? true : false,
    );
    const [selectedAlignCenter, setSelectedAlignCenter] = useState(
        textData?.text_layout === 'center' ? true : false,
    );
    const [selectedAlignRight, setSelectedAlignRight] = useState(
        textData?.text_layout === 'right' ? true : false,
    );
    const [speedValue, setSpeedValue] = useState(
        textData?.scrolling_speed ? parseInt(textData?.scrolling_speed) : 30,
    );
    const [enableScrolling, setEnableScrolling] = useState(
        textData?.enable_scrolling !== undefined ? textData?.enable_scrolling : 1,
    );
    //console.log('file: AddTextDialog.jsx:141 => AddTextDialog => enableScrolling:', enableScrolling);
    const [selectedFontSize, setSelectedFontSize] = useState(
        textData?.font_size ? textData?.font_size : '30',
    );
    const [directionValue, setDirectionValue] = useState(
        textData?.scrolling_direction ? textData?.scrolling_direction : 'left',
    );
    const [textValue, setTextValue] = useState(textData?.text_content ? textData?.text_content : '');

    const handleEnableBackgroundColor = () => {
        if (enableBackgroundColor === 1) {
            setEnableBackgroundColor(0);
        } else {
            setEnableBackgroundColor(1);
        }
    };
    const handleChangeFontColor = (event) => {
        //console.log('onChange=', event.target.value);
        setFontColor(event.target.value);
    };
    const handleChangebackground = (event) => {
        //console.log('onChange=', event.target.value);
        setBackgroundColor(event.target.value);
    };

    const handleClickBold = () => {
        setSelectedBold(!selectedBold);
    };
    const handleClickItalic = () => {
        setSelectedItalic(!selectedItalic);
    };
    const handleClickTextShadow = () => {
        setSelectedTextShadow(!selectedTextShadow);
    };
    const handleClickAlignLeft = () => {
        setSelectedAlignLeft(!selectedAlignLeft);
        setSelectedAlignCenter(false);
        setSelectedAlignRight(false);
    };
    const handleClickAlignCenter = () => {
        setSelectedAlignCenter(!selectedAlignCenter);
        setSelectedAlignLeft(false);
        setSelectedAlignRight(false);
    };
    const handleClickAlignRight = () => {
        setSelectedAlignRight(!selectedAlignRight);
        setSelectedAlignCenter(false);
        setSelectedAlignLeft(false);
    };

    const handleEnableScrolling = () => {
        if (enableScrolling === 1) {
            setEnableScrolling(0);
        } else {
            setEnableScrolling(1);
        }
    };
    //console.log('file: AddTextDialog.jsx:161 => AddTextDialog => value:', speedValue);

    const handleChangeSpeedValue = (event, newValue) => {
        setSpeedValue(newValue);
    };

    //console.log('file: AddTextDialog.jsx:181 => AddTextDialog => selectedFontSize:', selectedFontSize);
    const handleFontSizeChange = (event, value) => {
        setSelectedFontSize(value);
        // Handle the font size change
        // ...
    };
    //console.log('file: AddTextDialog.jsx:186 => AddTextDialog => directionValue:', directionValue);
    const handleChangeDirection = (event) => {
        setDirectionValue(event.target.value);
    };

    //console.log('file: AddTextDialog.jsx:195 => AddTextDialog => textValue:', textValue);

    const handleChangeText = (event) => {
        const { value } = event.target;
        setTextValue(value);
    };

    const handleApply = async () => {
        // const data = await AddCampaign( switchUser, showCampaignDesign.template_id, name, final_list);
        let fontString = '';
        if (selectedBold) {
            fontString += 'B';
        }

        if (selectedItalic) {
            fontString += 'I';
        }

        if (selectedTextShadow) {
            fontString += 'A';
        }

        const TextData = {
            text_content: textValue,
            font_size: selectedFontSize,
            font_colour: fontColor,
            font_style: fontString,
            text_layout: selectedAlignLeft
                ? 'left'
                : selectedAlignCenter
                ? 'center'
                : selectedAlignRight
                ? 'right'
                : '',
            no_background: enableBackgroundColor,
            background_color: enableBackgroundColor === 0 ? backgroundColor : '',
            enable_scrolling: enableScrolling,
            scrolling_speed: enableScrolling === 1 ? speedValue.toString() : '',
            scrolling_direction: directionValue,
        };
        // const currentTextElement = currentElement?.resource_list
        // 	? currentElement?.resource_list[0]?.resource_text
        // 	: null;
        //  //console.log('file: AddTextDialog.jsx:251 => handleApply => currentTextElement:', currentTextElement);
        if (currentElement?.resource_list) {
            // Replace resource_text with textData
            const dataWithElement = {
                ...currentElement,
                resource_list: [
                    {
                        ...currentElement?.resource_list[0],
                        resource_text: TextData,
                        resource_type: 'text',
                    },
                ],
            };
            //console.log(dataWithElement);
            addItem(dataWithElement);
            handleCloseTextDialog();
        } else {
            const dataWithElement = {
                ...currentElement,
                resource_list: [{ resource_text: TextData, resource_type: 'text' }],
            };
            //console.log(dataWithElement);
            addItem(dataWithElement);
            handleCloseTextDialog();
        }

        // Use the updated dataWithElement object as needed

        // } else {
        // 	// Handle the case when resource_text doesn't exist
        // 	const dataWithElement = { ...currentElement, resource_text: TextData };
        // 	 //console.log(dataWithElement);
        // 	// const dataWithElement = { ...currentElement, textData: TextData };
        // 	addItem(dataWithElement);
        // 	handleCloseTextDialog();
        // 	 //console.log('resource_text does not exist');
        // }
    };
    return (
        <div>
            <Dialog fullWidth={true} maxWidth="md" open={openTextDialog} onClose={handleCloseTextDialog}>
                <DialogTitle sx={{ paddingTop: 0, paddingLeft: 0 }}>Set Text</DialogTitle>
                <DialogContent className="flex flex-col p-2 ">
                    <TextField
                        id="multiline-text"
                        multiline
                        rows={8}
                        placeholder="Text"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: selectedFontSize + 'px !important',
                                color: fontColor,
                                backgroundColor: enableBackgroundColor === 1 ? 'none' : backgroundColor,
                            },
                            '& .MuiInputBase-input': {
                                fontWeight: selectedBold ? 'bold !important' : 'normal !important',
                                textShadow: selectedTextShadow ? ' 1px 1px 2px black' : 'none',
                                fontStyle: selectedItalic ? 'italic !important' : 'normal !important',
                                textAlign: selectedAlignCenter
                                    ? 'center'
                                    : selectedAlignLeft
                                    ? 'left'
                                    : selectedAlignRight
                                    ? 'right'
                                    : 'none',
                            },
                        }}
                        value={textValue}
                        onChange={(e) => handleChangeText(e)}
                    />
                    <div className="grid gap-4 grid-cols-2 grid-rows-3 mt-6 ml-1">
                        <div className="">
                            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormLabel id="fontSize" sx={style.labelText}>
                                    Font size
                                </FormLabel>
                                <Autocomplete
                                    fullWidth
                                    id="fontSizeOptions"
                                    freeSolo
                                    value={selectedFontSize}
                                    onChange={handleFontSizeChange}
                                    options={fontSizeOptions?.map((option) => option.label.toString())}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </div>
                        <div className="flex w-full flex-col">
                            <div>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel id="fontSize" sx={style.labelText}>
                                        Font color
                                    </FormLabel>
                                    <TextField
                                        type="color"
                                        fullWidth
                                        // sx={{ minWidth: '100%' }}
                                        value={fontColor}
                                        onChange={handleChangeFontColor}
                                    />
                                </FormControl>
                            </div>
                            {/* <div>
								<FormControlLabel control={<Checkbox defaultChecked />} label='Label' />
							</div> */}
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="flex w-full h-full mt-2.5">
                                <ButtonGroup
                                    sx={{ display: 'flex', width: '80%' }}
                                    variant="outlined"
                                    aria-label="outlined button group"
                                >
                                    <CustomButton
                                        variant="outlined"
                                        selected={selectedBold}
                                        onClick={handleClickBold}
                                    >
                                        <FaBold />
                                    </CustomButton>
                                    <CustomButton
                                        variant="outlined"
                                        selected={selectedItalic}
                                        onClick={handleClickItalic}
                                    >
                                        <FaItalic />
                                    </CustomButton>
                                    <CustomButton
                                        variant="outlined"
                                        selected={selectedTextShadow}
                                        onClick={handleClickTextShadow}
                                    >
                                        <FaFont />
                                    </CustomButton>
                                </ButtonGroup>
                            </div>
                            <div className="flex w-full h-full mt-2.5 justify-end">
                                <ButtonGroup
                                    sx={{ display: 'flex', width: '80%' }}
                                    variant="outlined"
                                    aria-label="outlined button group"
                                >
                                    <CustomButton selected={selectedAlignLeft} onClick={handleClickAlignLeft}>
                                        <FaAlignLeft />
                                    </CustomButton>
                                    <CustomButton
                                        selected={selectedAlignCenter}
                                        onClick={handleClickAlignCenter}
                                    >
                                        <FaAlignCenter />
                                    </CustomButton>
                                    <CustomButton
                                        selected={selectedAlignRight}
                                        onClick={handleClickAlignRight}
                                    >
                                        <FaAlignRight />
                                    </CustomButton>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="flex w-full flex-col">
                            <div>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel id="fontSize" sx={style.labelText}>
                                        Background
                                    </FormLabel>

                                    <TextField
                                        type="color"
                                        value={backgroundColor}
                                        onChange={handleChangebackground}
                                        fullWidth
                                        // sx={{ minWidth: '100%' }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableBackgroundColor === 1 ? true : false}
                                            onChange={() => handleEnableBackgroundColor()}
                                        />
                                    }
                                    label="No Background"
                                />
                            </div>
                        </div>
                        {/* <div className="flex flex-col">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enableScrolling === 1 ? true : false}
                                        onChange={handleEnableScrolling}
                                    />
                                }
                                label="Enable Text scrolling"
                            />

                            <div className="flex flex-row">
                                <DirectionsWalkIcon />
                                <Slider
                                    sx={{ marginLeft: '10px', marginRight: '10px' }}
                                    aria-label="scrolling"
                                    value={speedValue}
                                    onChange={handleChangeSpeedValue}
                                />
                                <DirectionsRunIcon />
                            </div>
                        </div>
                        <div className="">
                            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormLabel id="fontSize" sx={style.labelText}>
                                    Direction
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    id="direction"
                                    select
                                    value={directionValue}
                                    onChange={handleChangeDirection}
                                >
                                    <MenuItem key="Left" value="left">
                                        Left
                                    </MenuItem>
                                    <MenuItem key="Up" value="up">
                                        Up
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                        </div> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="grid grid-cols-2 gap-3">
                        <Button variant="outlined" sx={{ color: '#0444AB' }} onClick={handleCloseTextDialog}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={() => handleApply()}>
                            Add
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddTextDialog;
