import { AddMediaResource, CancelSegmentUpload, GetBatchNumber, SegmentUpload } from '../../../api/api';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UploadExternalSource, UploadLocalMedia } from './uploadSec';

import { PdfToImages } from './uploadSec';
import SparkMD5 from 'spark-md5';
import { TbCloudUpload } from 'react-icons/tb';
import axios from 'axios';
import { useNotification } from '../../../contexts/ContextProvider';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UploadDialog = ({
    currentUser,
    openUploadDialog,
    setOpenUploadDialog,
    folderId,
    chosenFolder,
    setTriggerUpdate,
    triggerUpdate,
    setTriggerUpdateMedia,
    triggerUpdateMedia,
    folderType,
}) => {
    const notify = useNotification();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    // Handle Change Tabs
    const [tabValue, setTabValue] = useState(0);
    //console.log('tabValue:', tabValue);

    const handleTabs = (event, newValue) => {
        setTabValue(newValue);
        setImageURLs([]);
        setImages([]);
    };

    // Handle upload File
    const [images, setImages] = useState([]);
    //console.log('images', images);
    const [imageURLs, setImageURLs] = useState([]);
    //console.log('file: UploadDialog.jsx:113 => imageURLs:', imageURLs);

    useEffect(() => {
        if (images.length < 1) return;
        //console.log('file: UploadDialog.jsx:179 => useEffect => images:', images);

        const fetchMetadata = (image) => {
            return new Promise((resolve, reject) => {
                const newImage = {
                    file_type:
                        image?.type?.split('/')[0] === 'application' ? 'file' : image?.type?.split('/')[0],
                    file_url: image,
                    file_name: image?.name,
                    file_size: image?.size,
                    file_width: 0,
                    file_height: 0,
                    file_duration: 0,
                    progress: 0,
                    uploadStatus: 'Ready to upload',
                };

                if (newImage.file_type === 'video' || newImage.file_type === 'audio') {
                    const url = URL.createObjectURL(image);
                    const media =
                        newImage.file_type === 'video' ? document.createElement('video') : new Audio(url);

                    media.addEventListener('loadedmetadata', () => {
                        newImage.file_duration = Math.floor(media.duration);
                        URL.revokeObjectURL(url); // Release the object URL after use
                        //console.log('Metadata loaded:', newImage);
                        resolve(newImage);
                    });

                    media.addEventListener('error', (e) => {
                        console.error('Error loading media:', e);
                        reject(e);
                    });

                    //console.log('Created media:', media);
                    media.src = url;
                    media.load();
                } else {
                    resolve(newImage);
                }
            });
        };

        const loadImageUrls = async () => {
            try {
                const newImageUrls = await Promise.all(images.map(fetchMetadata));
                //console.log('file: UploadDialog.jsx:146 => Promise.all => newImageUrls:', newImageUrls);
                setImageURLs(newImageUrls);
            } catch (error) {
                console.error('Error loading metadata:', error);
            }
        };

        loadImageUrls();
    }, [images]);

    /**
     *
     * Upolad progress
     *
     */

    const [uploadStatus, setUploadStatus] = useState('ready');
    const [uploadFailed, setUploadFailed] = useState(false);
    const [uploadFailedMsg, setUploadFailedMsg] = useState('');
    const [currentSN, setCurrentSN] = useState();
    //console.log('currentSN:', currentSN);
    const onImageChange = (e) => {
        //console.log('file: UploadDialog.jsx:224 => onImageChange => e:', e.target.files);
        if (e.target.files.length > 1) {
            let convertedImage = [];
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];

                if (file.type.includes('/pdf')) {
                    PdfToImages(file).then((pdfImages) => {
                        // Do something with the array of images
                        //console.log('PDF Images:', pdfImages);
                        convertedImage.push(...pdfImages);
                    });
                } else {
                    // Handle other file types
                    convertedImage.push(file);
                }
            }
            //console.log('onImageChange => convertedImage:', convertedImage);

            setImages([...images, ...convertedImage]);
        } else {
            const file = e.target.files[0];

            if (file.type.includes('/pdf')) {
                //console.log('file: UploadDialog.jsx:270 => onImageChange => file:', file);
                PdfToImages(file).then((pdfImages) => {
                    // Do something with the array of images
                    //console.log('PDF Images:', pdfImages);
                    setImages([...images, ...pdfImages]);
                });
            } else {
                // Handle other file types
                setImages([...images, file]);
            }
            // }
        }
    };

    const HandleAddFile = async () => {
        //console.log('tabValue', tabValue);
        // setUploading(true);
        for (let i = 0; i < imageURLs.length; i++) {
            //console.log('file: UploadDialog.jsx:214 => HandleAddFile => imageURLs:', imageURLs);
            const imageURL = imageURLs[i];
            // console.log('HandleAddFile => imageURL:', imageURL);

            setImageURLs((prevFileList) => {
                const updatedFileList = [...prevFileList];
                updatedFileList[i].uploadStatus = 'Uploading';
                updatedFileList[i].progress = 2;
                return updatedFileList;
            });
            const data = await uploadFile(imageURL, i);

            console.log('HandleAddFile ======= data', data);
            if (data) {
                setImageURLs((prevFileList) => {
                    const updatedFileList = [...prevFileList];
                    updatedFileList[i].uploadStatus = 'Optimising for display';
                    return updatedFileList;
                });
                const res = await AddMediaResource(
                    switchUser,
                    folderId ? folderId : chosenFolder.folder_id,
                    tabValue,
                    [{ ...imageURL, file_url: data.file_url }].map(
                        ({ progress, uploadStatus, ...rest }) => rest,
                    ),
                );
                //console.log('res', res);
                if (res.code === 0) {
                    setImageURLs((prevFileList) => {
                        const updatedFileList = [...prevFileList];
                        updatedFileList[i].uploadStatus = 'Added to the folder';
                        return updatedFileList;
                    });
                    notify.dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'success',
                            message: 'The media was successfully added!',
                        },
                    });
                } else {
                    notify.dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'error',
                            message: res.msg ? res.msg : 'The media failed to add.',
                        },
                    });
                }
            } else {
                notify.dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: 'No media was successfully added.',
                    },
                });
            }
        }
        handleCloseUploadDialog();
        setImageURLs([]);
        setTriggerUpdate(!triggerUpdate);
        setTriggerUpdateMedia(!triggerUpdateMedia);
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
        setImageURLs([]);
        setImages([]);
        setUploadStatus('ready');
        setUploadFailed(false);
        setUploadFailedMsg('');
        // 取消上传的请求
        // axios.CancelToken.source().cancel('Request canceled by user');
        handleCancelUpload();
        // setUploading(false);
    };

    //  Get batch Number
    const handleGetBatchNumber = async () => {
        const { code, data, msg } = await GetBatchNumber(switchUser);
        if (code === 0) {
            return data.upload_batch_sn;
        }
    };

    const CHUNK_SIZE = 4 * 1024 * 1024; // 4MB

    const computeMD5 = (file) => {
        return new Promise((resolve, reject) => {
            let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
                chunkSize = 1024 * 1024 * 5, // Read in chunks of 5MB
                chunks = Math.ceil(file.file_size / chunkSize),
                currentChunk = 0,
                spark = new SparkMD5.ArrayBuffer(),
                fileReader = new FileReader();
            fileReader.onload = (e) => {
                spark.append(e.target.result); // Append array buffer
                currentChunk++;

                if (currentChunk < chunks) {
                    loadNext();
                } else {
                    const md5 = spark.end();
                    resolve(md5);
                }
            };

            fileReader.onerror = () => {
                console.warn('oops, something went wrong.');
            };

            const loadNext = () => {
                var start = currentChunk * chunkSize,
                    end = start + chunkSize >= file.file_size ? file.file_size : start + chunkSize;

                fileReader.readAsArrayBuffer(blobSlice.call(file.file_url, start, end));
            };

            loadNext();
        });
    };

    const uploadFile = async (file, index) => {
        const totalChunks = Math.ceil(file.file_size / CHUNK_SIZE);
        //console.log('file: UploadDialog.jsx:306 => uploadFile => totalChunks:', totalChunks);
        const batchNumber = await handleGetBatchNumber();
        setCurrentSN(batchNumber);
        const computedMD5 = await computeMD5(file);
        //console.log('Computed MD5:', computedMD5);

        let uploadedChunks = 0;
        let retryChunks = [];
        let chunkList = [];
        let uploadedChunkSize = 0;
        const MAX_RETRY_COUNT = 5; // Maximum number of retries
        let retryCount = 0;
        for (let i = 0; i < totalChunks; i++) {
            //console.log('file: UploadDialog.jsx:313 => uploadFile => index:', i);
            try {
                const start = i * CHUNK_SIZE;
                const end = Math.min((i + 1) * CHUNK_SIZE, file.file_size);

                const chunk = file.file_url.slice(start, end);
                //console.log('uploadFile => chunk:', chunk);
                const data = await SegmentUpload(
                    switchUser,
                    folderType === 2 ? 'media_system_resource' : 'media_resource',
                    1,
                    batchNumber,
                    computedMD5,
                    file.file_name,
                    chunk,
                    i,
                    totalChunks,
                );

                uploadedChunkSize += chunk.size;

                setImageURLs((prevFileList) => {
                    const updatedFileList = [...prevFileList];
                    updatedFileList[index].progress = Math.round((uploadedChunkSize / file.file_size) * 100);
                    return updatedFileList;
                });

                //console.log('file: UploadDialog.jsx:352 => uploadFile => data:', data);
                if (data.code === 0) {
                    uploadedChunks++;
                    chunkList.push({ index: i, chunk });

                    if (i === totalChunks - 1) {
                        retryChunks = data.data.chunk_index_list;
                        if (retryChunks.length === 0) {
                            return data.data;
                        } else {
                            while (retryChunks.length > 0) {
                                const n = retryChunks[0];
                                //console.log(
                                //     'uploadFile => chunkList.find((x) => x.index === n).chunk,:',
                                //     chunkList.find((x) => x.index === n).chunk,
                                // );

                                try {
                                    if (retryCount <= MAX_RETRY_COUNT) {
                                        const retryData = await SegmentUpload(
                                            switchUser,
                                            // upload_scene
                                            folderType === 2 ? 'media_system_resource' : 'media_resource',
                                            // /storage_location
                                            1,
                                            // upload_batch_sn
                                            batchNumber,
                                            // /upload_file_md5
                                            computedMD5,
                                            // upload_file_name
                                            file.file_name,
                                            // upload_chunk_file
                                            chunkList.find((x) => x.index === n).chunk,
                                            // upload_chunk_index
                                            n,
                                            // upload_chunk_count
                                            totalChunks,
                                        );
                                        retryCount++;

                                        if (
                                            retryData.data.chunk_index_list.length === 0 &&
                                            retryData.data.file_url
                                        ) {
                                            //console.log('retryData is null:');
                                            return retryData.data;
                                        } else {
                                            retryChunks = retryData.data.chunk_index_list;
                                        }
                                    } else {
                                        console.error(
                                            `Maximum retry count reached for chunk. Upload failed.`,
                                        );
                                        break;
                                    }
                                } catch (error) {
                                    //console.log(`Failed to upload chunk ${n}`);
                                }
                            }
                        }
                    }
                    //console.log(
                    //     'file: UploadDialog.jsx:338 => uploadFile => uploadedChunkSize:',
                    //     uploadedChunkSize,
                    // );
                } else {
                    setUploadFailed(true);
                    setUploadFailedMsg(data.msg);
                }
            } catch (error) {
                //console.log('file: UploadDialog.jsx:378 => uploadFile => error:', error);
            }
        }
    };

    const handleCancelUpload = async () => {
        //console.log('cancel upload...');
        const data = await CancelSegmentUpload(switchUser, currentSN);
        //console.log('handleCancelUpload => data:', data);
    };

    return (
        <Dialog
            open={openUploadDialog}
            maxWidth="md"
            scroll={'paper'}
            fullWidth={true}
            // PaperProps={{ sx: { borderRadius: '30px', padding: '20px' } }}
        >
            <DialogTitle sx={{ display: 'flex', flexDirection: 'row', paddingTop: '0' }}>
                <TbCloudUpload size={24} className="mt-1.5 mr-2" />
                <p className="mt-1 mb-0">Upload</p>
            </DialogTitle>
            <DialogContent className="flex flex-col p-6">
                <Tabs
                    value={tabValue}
                    onChange={handleTabs}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Upload File" sx={{ textTransform: 'none' }} {...a11yProps(0)} />
                    {/* <Tab label="Upload URL" sx={{ textTransform: 'none' }} {...a11yProps(1)} /> */}
                    {/* <Tab label='Import from YouTube' {...a11yProps(2)} /> */}
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <UploadLocalMedia
                        onImageChange={onImageChange}
                        imageURLs={imageURLs}
                        uploadFailed={uploadFailed}
                        uploadFailedMsg={uploadFailedMsg}
                    />
                </TabPanel>
                {/* <TabPanel value={tabValue} index={1}>
                    <UploadExternalSource
                        imageURLs={imageURLs}
                        setImageURLs={setImageURLs}
                        uploadFailed={uploadFailed}
                        uploadFailedMsg={uploadFailedMsg}
                    />
                </TabPanel> */}
            </DialogContent>
            <DialogActions>
                <Button style={{ minWidth: '92px' }} variant="contained" onClick={handleCloseUploadDialog}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{ minWidth: '92px' }}
                    disabled={uploadStatus !== 'ready'}
                    onClick={HandleAddFile}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadDialog;
