import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Box,
    Typography,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GrDownload } from 'react-icons/gr';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai'; // Import zoom icons
import { useState, useEffect, useRef } from 'react';
import { SaltUpscaleResult } from '../ai_tools/Api';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const UpscaleImageDialog = ({ originalImage, upscale, handleCloseUpscaleDialog, openUpscaleDialog }) => {
    const [progress, setProgress] = useState(0); // Progress state
    const [imageUrl, setImageUrl] = useState(false); // Upscaled image URL state
    const [statusMessage, setStatusMessage] = useState('Processing...'); // Status message
    const [linePosition, setLinePosition] = useState('50%'); // Vertical line position
    const [zoom, setZoom] = useState(1); // Default zoom level
    const dialogRef = useRef(null); // Reference to the dialog
    const imageRef = useRef(null); // Reference to the image

    const handleMouseMove = (e) => {
        const dialogWidth = e.currentTarget.offsetWidth;
        const mouseX = e.clientX - e.currentTarget.getBoundingClientRect().left;
        const newPosition = `${(mouseX / dialogWidth) * 100}%`;
        setLinePosition(newPosition);
    };

    const handleZoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level: 3
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level: 1 (fit-to-dialog)
    };

    const handleWheel = (e) => {
        e.preventDefault();
        setZoom((prevZoom) => {
            const newZoom = prevZoom + (e.deltaY > 0 ? -0.1 : 0.1);
            return Math.min(Math.max(newZoom, 1), 3); // Clamp between 1 and 3
        });
    };

    const handleDownload = async () => {
        if (imageUrl) {
            try {
                const response = await fetch(imageUrl); // Fetch the image
                const blob = await response.blob(); // Convert the response to a Blob
                const blobUrl = window.URL.createObjectURL(blob); // Create a temporary Blob URL

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = 'upscaled_image.png'; // Set the file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Revoke the Blob URL after the download
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Failed to download image:', error);
            }
        }
    };

    const calculateDefaultZoom = () => {
        if (dialogRef.current && imageRef.current) {
            const dialogWidth = dialogRef.current.offsetWidth;
            const dialogHeight = dialogRef.current.offsetHeight;
            const imageWidth = imageRef.current.naturalWidth;
            const imageHeight = imageRef.current.naturalHeight;

            // Calculate zoom to fit the image within the dialog
            const zoomWidth = dialogWidth / imageWidth;
            const zoomHeight = dialogHeight / imageHeight;
            const fitZoom = Math.min(zoomWidth, zoomHeight);

            setZoom(fitZoom); // Set the zoom to fit the entire image
        }
    };

    useEffect(() => {
        if (openUpscaleDialog) {
            calculateDefaultZoom(); // Calculate zoom when dialog opens
        }
    }, [openUpscaleDialog]);

    useEffect(() => {
        if (!upscale || (upscale.statusCode !== 201 && upscale.statusCode !== 200)) return;

        const fetchUpscaleResult = async () => {
            let success = false; // Track if the upscale process succeeds

            for (let attempt = 1; attempt <= 10; attempt++) {
                try {
                    const upscaleResult = await SaltUpscaleResult({
                        execution_id: upscale.message,
                    });

                    if (upscaleResult.message.status === 'completed') {
                        setImageUrl(upscaleResult.message.output_url[0]);
                        setStatusMessage('Upscale completed!');
                        setProgress(100); // Progress complete
                        success = true; // Mark as successful
                        break;
                    }

                    if (upscaleResult.message.status === 'failed') {
                        success = false; // Mark as successful
                        setProgress(0); // Progress complete
                        break;
                    }

                    setProgress((attempt / 10) * 100);
                    setStatusMessage(
                        `Processing...Task in queue. It will take 5-10 minutes. You can close this window and come back later to check the result (${attempt}/10)`,
                    );

                    // Wait for 30 seconds before the next attempt
                    await new Promise((resolve) => setTimeout(resolve, 60000));
                } catch (error) {
                    console.error(`Attempt ${attempt} failed:`, error);
                }
            }

            // If not successful after 10 attempts, update the status message
            if (!success) {
                setStatusMessage('Failed to complete the upscale process. Please try again.');
            }
        };

        fetchUpscaleResult();
    }, [upscale]);

    return (
        <Dialog
            open={openUpscaleDialog}
            onClose={handleCloseUpscaleDialog}
            PaperProps={{
                sx: {
                    backgroundColor: 'white',
                    width: '100%',
                    maxWidth: '1600px',
                    height: '100%',
                },
            }}
            ref={dialogRef} // Reference for dialog size
        >
            <DialogTitle>
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: 'center', // Center the text
                        width: '100%',
                    }}
                >
                    Original Image <ArrowForwardIcon sx={{ mx: 1 }} /> Upscaled Image
                </Typography>
                {/* Zoom Controls */}
                <Tooltip title="Zoom In">
                    <IconButton
                        aria-label="zoom-in"
                        onClick={handleZoomIn}
                        sx={{
                            position: 'absolute',
                            right: 104,
                            top: 8,
                            color: 'grey',
                        }}
                    >
                        <AiOutlineZoomIn size={20} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Zoom Out">
                    <IconButton
                        aria-label="zoom-out"
                        onClick={handleZoomOut}
                        sx={{
                            position: 'absolute',
                            right: 72,
                            top: 8,
                            color: 'grey',
                        }}
                    >
                        <AiOutlineZoomOut size={20} />
                    </IconButton>
                </Tooltip>
                {/* Download Button */}
                <Tooltip title="Download Upscaled Image">
                    <IconButton
                        aria-label="download"
                        onClick={handleDownload}
                        sx={{
                            position: 'absolute',
                            right: 40,
                            top: 8,
                            color: 'grey',
                        }}
                    >
                        <GrDownload size={20} />
                    </IconButton>
                </Tooltip>
                {/* Close Button */}
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        handleCloseUpscaleDialog();
                        setImageUrl(); // Reset the image URL properly
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'relative',
                }}
                onWheel={handleWheel} // Add mouse wheel zoom functionality
            >
                <div className="flex h-full w-full justify-center items-center">
                    {!imageUrl ? (
                        <div className="flex flex-col justify-center items-center w-full">
                            {progress !== 0 && <CircularProgress />}
                            <p className="mt-6">{statusMessage}</p>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={{ width: '100%', marginTop: '20px' }}
                            />
                        </div>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                            onMouseMove={handleMouseMove}
                            style={{
                                transform: `scale(${zoom})`,
                                transformOrigin: 'center',
                            }}
                        >
                            {/* Original Image */}
                            <Box
                                component="img"
                                ref={imageRef} // Reference for the image
                                src={originalImage}
                                alt="Original"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain', // Ensure the image respects aspect ratio
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            />
                            {/* Overlay Image */}
                            <Box
                                component="img"
                                src={imageUrl}
                                alt="Overlay"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    clipPath: `polygon(0 0, ${linePosition} 0, ${linePosition} 100%, 0 100%)`,
                                }}
                            />
                            {/* Vertical Line */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: linePosition,
                                    width: '2px',
                                    height: '100%',
                                    backgroundColor: 'red',
                                    pointerEvents: 'none',
                                }}
                            />
                        </Box>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UpscaleImageDialog;
