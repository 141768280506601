import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

// import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GrDownload } from 'react-icons/gr';
import LazyLoad from 'react-lazyload';
import React from 'react';

const RecommendImageDialog = ({ recommend, handleCloseRecommendDialog, openRecommendDialog }) => {
    // GetDescribeImage
    return (
        <Dialog
            open={openRecommendDialog}
            // fullScreen
            // fullWidth={true}
            // maxWidth="md"
            // onClick={handleCloseRecommendDialog}
            PaperProps={{ sx: { backgroundColor: 'white', width: '600px', height: '792px' } }}
        >
            {' '}
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseRecommendDialog}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '0px', overflow: 'hidden' }}>
                <div className="flex h-full w-full justify-center items-center">
                    {!recommend ? (
                        <div className="flex flex-col justify-center  items-center w-full">
                            <CircularProgress />
                            <p className="mt-6">Generating...</p>
                        </div>
                    ) : typeof recommend === 'string' ? (
                        <div className="flex flex-col justify-center items-center">
                            <p className="mt-6">{recommend}</p>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <div className="w-[560px] h-[560px] flex relative p-4 pb-0">
                                <LazyLoad>
                                    <img src={recommend.generated_image_url} alt="AI image" />
                                    <div
                                        className="absolute right-6 bottom-6 w-10 h-10 bg-slate-200 bg-opacity-60 flex justify-center items-center cursor-pointer"
                                        onClick={() => {
                                            fetch(recommend.generated_image_url)
                                                .then((response) => {
                                                    if (!response.ok) {
                                                        throw new Error(
                                                            `HTTP error! status: ${response.status}`,
                                                        );
                                                    }
                                                    return response.blob();
                                                })
                                                .then((blob) => {
                                                    const url = window.URL.createObjectURL(blob);
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.download = 'image.jpg';
                                                    link.click();
                                                    window.URL.revokeObjectURL(url);
                                                })
                                                .catch((e) => {
                                                    console.error(
                                                        'There was a problem with the fetch operation:',
                                                        e,
                                                    );
                                                });
                                        }}
                                    >
                                        <GrDownload />
                                    </div>
                                </LazyLoad>
                            </div>
                            <div className="overflow-y-scroll p-4 pt-0" style={{ height: '250px' }}>
                                <p className="min-h-full mb-0">{recommend?.message}</p>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default RecommendImageDialog;
