import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { Field, Formik, useFormik } from 'formik';
import {
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    InputAdornment,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { RiCloseCircleLine, RiTimeLine } from 'react-icons/ri';

import { DenseInput } from '../utils';
import { PreviewMediaDialog } from '../media';
import { styled } from '@mui/material/styles';

const ImageListItemBarCenter = styled(ImageListItemBar)(({ theme }) => ({
    ' .MuiImageListItemBar-titleWrap': {
        padding: '0',
    },
    '& .MuiImageListItemBar-title': {
        textAlign: 'center',
        fontSize: '12px',
        textOverflow: 'clip',
        width: '250px',
    },
}));

const RightSideMediaList = ({ chosenMedia, setChosenMedia, handleDelete, handleChangeDuration }) => {
    //console.log('RightSideMediaList => chosenMedia:', chosenMedia);
    chosenMedia?.resource_list?.forEach((resource) => {
        if (resource.resource_duration === 0) {
            resource.resource_duration = 5;
        }
    });
    const [openPreviewMediaDialog, setOpenPreviewMediaDialog] = useState(false);
    const [previewMedia, setPreviewMedia] = useState({});

    const handleOpenPreviewMediaDialog = (item) => {
        //console.log('handleOpenPreviewMediaDialog => item:', item);
        setPreviewMedia(item);
        setOpenPreviewMediaDialog(true);
    };
    const handleClosePreviewMediaDialog = () => {
        setOpenPreviewMediaDialog(false);
    };

    /**
     * Handle Draggable media
     */

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newDivs = [...chosenMedia?.resource_list];
        const [removed] = newDivs.splice(result.source.index, 1);
        newDivs.splice(result.destination.index, 0, removed);
        setChosenMedia({
            ...chosenMedia,
            resource_list: newDivs,
        });
    };
    return (
        <ImageList
            className="w-full mr-0 ml-20 bg-white"
            cols={1}
            // gap={8}
            sx={{
                padding: '4px',
                maxHeight: '520px',
                minHeight: '180px',
                width: '280px',
            }}
        >
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="chosenMedia">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {chosenMedia?.resource_list?.length >= 1 &&
                                chosenMedia?.resource_list?.map(
                                    (item, index) =>
                                        item?.resource_id && (
                                            <Draggable
                                                key={`${item?.resource_id}-${item.count}`}
                                                draggableId={`div-${item?.resource_id}-${item.count}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ImageListItem
                                                            key={`${item?.resource_id} - ${item.count}`}
                                                            style={{
                                                                border: `1px dotted #0444AB`,
                                                                borderRadius: '10px',
                                                                height: '202px',
                                                                width: '250px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                boxShadow:
                                                                    '0 4px 8px 0 rgba(18, 188, 215, 0.2), 0 6px 20px 0 rgba(18, 188, 215, 0.19)',
                                                            }}
                                                            className=" shadow-xl mb-4 cursor-pointer hover:bg-gray-200"
                                                            onClick={() => handleOpenPreviewMediaDialog(item)}
                                                        >
                                                            <div className="h-full">
                                                                {item.resource_type === 'image' ? (
                                                                    <div className="flex w-full justify-center items-center pt-3">
                                                                        <img
                                                                            className="max-h-full max-w-full items-center"
                                                                            src={item?.resource_url}
                                                                            loading="lazy"
                                                                            style={{
                                                                                height: '130px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex justify-center items-center max-h-[150px]">
                                                                        <video
                                                                            style={{
                                                                                height: '150px',
                                                                            }}
                                                                            controls
                                                                        >
                                                                            <source
                                                                                src={item.resource_url}
                                                                                type="video/mp4"
                                                                            />
                                                                        </video>
                                                                    </div>
                                                                )}
                                                                <ImageListItemBar
                                                                    sx={{
                                                                        background: 'transparent',
                                                                        zIndex: 1000,
                                                                    }}
                                                                    position="top"
                                                                    actionIcon={
                                                                        <IconButton
                                                                            sx={{ color: 'black' }}
                                                                            onClick={(e) =>
                                                                                handleDelete(e, item)
                                                                            }
                                                                        >
                                                                            <RiCloseCircleLine />
                                                                        </IconButton>
                                                                    }
                                                                    actionPosition="right"
                                                                />
                                                                <ImageListItemBarCenter
                                                                    title={
                                                                        <div>
                                                                            <Typography>
                                                                                {item.resource_name}
                                                                            </Typography>
                                                                            {item.resource_type === 'image' &&
                                                                                chosenMedia.resource_list
                                                                                    .length >= 2 && (
                                                                                    <DenseInput
                                                                                        sx={{
                                                                                            marginTop: '0',
                                                                                            backgroundColor:
                                                                                                '#ced4da',
                                                                                            marginBottom: '0',
                                                                                            borderBottomLeftRadius:
                                                                                                '10px',
                                                                                            borderBottomRightRadius:
                                                                                                '10px',
                                                                                        }}
                                                                                        margin="normal"
                                                                                        aria-labelledby="resource_duration"
                                                                                        name="resource_duration"
                                                                                        variant="outlined"
                                                                                        type="number"
                                                                                        defaultValue={
                                                                                            item.resource_duration >
                                                                                            0
                                                                                                ? item.resource_duration
                                                                                                : 5
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleChangeDuration(
                                                                                                item,
                                                                                                e,
                                                                                            )
                                                                                        }
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment
                                                                                                    position="start"
                                                                                                    component="div"
                                                                                                    disablePointerEvents
                                                                                                >
                                                                                                    <RiTimeLine />{' '}
                                                                                                    Time
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                            endAdornment: (
                                                                                                <InputAdornment
                                                                                                    position="end"
                                                                                                    component="div"
                                                                                                    disablePointerEvents
                                                                                                >
                                                                                                    sec
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    }
                                                                    position="below"
                                                                ></ImageListItemBarCenter>
                                                            </div>
                                                        </ImageListItem>
                                                        {/* ), )} */}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ),
                                )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <PreviewMediaDialog
                openPreviewMediaDialog={openPreviewMediaDialog}
                handleClosePreviewMediaDialog={handleClosePreviewMediaDialog}
                previewMedia={previewMedia}
            />
        </ImageList>
    );
};

export default RightSideMediaList;
