import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Box,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GrDownload } from 'react-icons/gr';
import LazyLoad from 'react-lazyload';

const HeatmapDialog = ({ originalImage, Heatmap, handleCloseHeatmapDialog, openHeatmapDialog }) => {
    return (
        <Dialog
            open={openHeatmapDialog}
            onClose={handleCloseHeatmapDialog}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    boxShadow: 3,
                    paddingBottom: '16px',
                },
            }}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseHeatmapDialog}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                    Heatmap Analysis
                </Typography>
                {/* Heatmap Color Bar under the title */}
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Box
                        sx={{
                            height: '10px',
                            background: 'linear-gradient(to right, blue, green, yellow, red)',
                            borderRadius: '5px',
                            mt: 1,
                        }}
                    />
                    <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                        <Typography variant="caption">Low Attention</Typography>
                        <Typography variant="caption">High Attention</Typography>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: '16px', overflow: 'hidden', height: '80vh' }}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    gap={2}
                    sx={{ height: '100%' }}
                >
                    {/* Original Image */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ width: '50%', height: '100%' }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Original Image
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%', // Set height to fill the parent container
                                position: 'relative', // Allow positioning of child elements
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={originalImage}
                                alt="Original"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain', // Ensures the image scales proportionally to fit within the box
                                    position: 'absolute', // Positions the image inside the container
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        </Box>
                    </Box>

                    {/* Heatmap */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ width: '50%', height: '100%' }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Heatmap
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                                height: '0',
                                paddingTop: '56.25%', // 16:9 aspect ratio
                                position: 'relative',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            {!Heatmap ? (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Generating...
                                    </Typography>
                                </Box>
                            ) : Heatmap.statusCode === 200 ? (
                                <LazyLoad>
                                    <img
                                        src={Heatmap.message.s3_url}
                                        alt="Heatmap"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </LazyLoad>
                            ) : (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    System Busy Please Try Again Later
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default HeatmapDialog;
