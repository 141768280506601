import { Box, IconButton, List, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import { HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi';
import React, { useState } from 'react';

import { FiMonitor } from 'react-icons/fi';
import { Pulse } from '../utils';
import { UnassignDeviceDialog } from './';
import { styled } from '@mui/material/styles';
// import { RiDeleteBin6Fill, RiFolder3Fill, RiSettings2Fill } from 'react-icons/ri';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const LocationDeviceList = ({ locationDetail, currentUser, triggerUpdate, setTriggerUpdate }) => {
    const [isHovered, setIsHovered] = useState();
    const toggleHover = (item) => {
        setIsHovered(item);
    };
    const toggleLeave = () => {
        setIsHovered('');
    };
    // Remove device Alert
    const [openUnassignDeviceDialog, setOpenUnassignDeviceDialog] = useState(false);
    const [clickedItem, setClickedItem] = useState(undefined);
    const handleClickOpen = (item) => {
        setOpenUnassignDeviceDialog(true);
        setClickedItem(item);
    };

    const handleClose = () => {
        setOpenUnassignDeviceDialog(false);
    };
    return (
        <Box>
            <List dense={true} sx={{ maxHeight: '240px', overflowY: 'auto' }}>
                {locationDetail ? (
                    locationDetail.assign_device_list?.map((item, index) => (
                        <ListItem
                            className="hover:bg-blue-100/50 rounded-lg"
                            key={item + index}
                            secondaryAction={
                                <div className="flex flex-row">
                                    <Tooltip
                                        title="Remove assigned Device"
                                        onMouseEnter={() => toggleHover(item)}
                                        onMouseLeave={toggleLeave}
                                        onClick={() => handleClickOpen(item)}
                                    >
                                        <div>
                                            <IconButton size="small">
                                                {isHovered === item ? (
                                                    <HiOutlineLockOpen color="black" />
                                                ) : (
                                                    <HiOutlineLockClosed color="black" />
                                                )}
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                        >
                            <ListItemIcon>
                                <FiMonitor
                                    style={{
                                        color: item?.device_status === 'OFFLINE' ? 'red' : 'green',
                                        fontSize: '20px',
                                    }}
                                />
                            </ListItemIcon>
                            <div className="flex flex-col">
                                <div className="w-[200px]">
                                    <p className="m-0  text-ellipsis overflow-hidden">{item?.device_name} </p>
                                </div>
                                <span className="text-[10px] text-black-500  rounded pb-1 ">
                                    <span
                                        className={`${
                                            item?.device_status === 'OFFLINE'
                                                ? 'text-red-500'
                                                : 'text-green-500'
                                        } text-sx font-bold`}
                                    >
                                        {item?.device_status}
                                    </span>
                                    <span>
                                        {item?.device_status === 'OFFLINE' &&
                                            ` Last online: ${item?.last_online}`}
                                    </span>
                                </span>
                            </div>
                        </ListItem>
                    ))
                ) : (
                    <Pulse />
                )}
            </List>
            {openUnassignDeviceDialog && (
                <UnassignDeviceDialog
                    openUnassignDeviceDialog={openUnassignDeviceDialog}
                    handleClose={handleClose}
                    clickedItem={clickedItem}
                    currentUser={currentUser}
                    locationDetail={locationDetail}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                />
            )}
        </Box>
    );
};

export default LocationDeviceList;
