import { Button, FormControl, FormLabel, Typography } from '@mui/material';
import { DenseInput, Pulse } from '../utils';

import { FormStyles } from './';
import { Header } from '../';
import React from 'react';
import { UpdateUserProfile } from '../../../api/api';
import { useFormik } from 'formik';
import { useNotification } from '../../../contexts/ContextProvider';

const style = {
    labelText: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
    label: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
        color: 'black',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    formControlStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
};
const ProfileDetails = ({ currentUser, profileDetail, setUpdateProfile, updateProfile }) => {
    // const { currentUser } = useStateContext();
    const notify = useNotification();
    //console.log('ProfileDetails => profileDetail:', profileDetail);
    // const [updateProfile, setUpdateProfile] = useState(true);

    //console.log('currentUser:', currentUser);
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const formik = useFormik({
        initialValues: {
            name: profileDetail?.name ? profileDetail?.name : '',
            username: profileDetail?.username ? profileDetail?.username : '',
            email: profileDetail?.email ? profileDetail?.email : '',
            status: profileDetail?.status ? profileDetail?.status : '',
            // user_level: profileDetail?.user_level ? profileDetail?.user_level : '',
            phone_number: profileDetail?.phone_number ? profileDetail?.phone_number : '',
            verify_sms_ident: profileDetail?.verify_sms_ident ? profileDetail?.verify_sms_ident : '',
            double_login_validate: profileDetail?.double_login_validate
                ? profileDetail?.double_login_validate
                : '',
            customer_type: profileDetail?.customer_type ? profileDetail?.customer_type : '1',
            cyberpoint: profileDetail?.cyberpoint ? profileDetail?.cyberpoint : '0',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const {
                name,
                username,
                email,
                status,
                phone_number,
                verify_sms_ident,
                double_login_validate,
                customer_type,
                cyberpoint,
            } = values;

            handleUpdateProfileDetail(username, email, phone_number);
        },
    });

    /**
     *
     * Handle update profile detail
     *
     *
     */
    const handleUpdateProfileDetail = async (
        username,
        email,
        // status,
        phone_number,
    ) => {
        const { data, code, msg } = await UpdateUserProfile(switchUser, username, email, '+61', phone_number);
        //console.log('code:', code);

        if (code === 0) {
            setUpdateProfile(!updateProfile);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Profile was updated successfully!!',
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Profile was updated unsuccessfully!',
                },
            });
        }
    };

    return (
        <div className="md:m-10 mt-24 p-2 md:p-10">
            {profileDetail ? (
                <>
                    <Header title="Profile" />
                    <div className="mx-7">
                        <form onSubmit={formik.handleSubmit}>
                            <FormControl sx={FormStyles.formControlStyle}>
                                <FormLabel id="username" sx={FormStyles.labelText}>
                                    Name
                                </FormLabel>
                                <DenseInput
                                    error={Boolean(formik.touched.username && formik.errors.username)}
                                    fullWidth
                                    helperText={formik.touched.username && formik.errors.username}
                                    margin="normal"
                                    aria-labelledby="username"
                                    name="username"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.username}
                                    variant="outlined"
                                />
                            </FormControl>

                            <FormControl sx={FormStyles.formControlStyle}>
                                <FormLabel id="phone_number" sx={FormStyles.labelText}>
                                    Phone
                                </FormLabel>
                                <DenseInput
                                    error={Boolean(formik.touched.phone_number && formik.errors.phone_number)}
                                    fullWidth
                                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                                    margin="normal"
                                    aria-labelledby="phone_number"
                                    name="phone_number"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.phone_number}
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl sx={FormStyles.formControlStyle}>
                                <FormLabel id="email" sx={FormStyles.labelText}>
                                    Email
                                </FormLabel>
                                <DenseInput
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email && formik.errors.email}
                                    margin="normal"
                                    aria-labelledby="email"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl sx={FormStyles.formControlStyle}>
                                <FormLabel id="email" sx={FormStyles.labelText}>
                                    Customer Type
                                </FormLabel>

                                <Typography sx={{ fontSize: '14px', marginTop: '8px', color: 'grey' }}>
                                    {formik.values.customer_type === 1
                                        ? 'Creator'
                                        : formik.values.customer_type === 2
                                        ? 'Inspirer'
                                        : formik.values.customer_type === 3
                                        ? 'Designer'
                                        : ''}
                                </Typography>
                            </FormControl>

                            <div className="flex w-full justify-center mt-3">
                                <Button variant="contained" type="submit" disabled={!formik.dirty}>
                                    save
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <Pulse />
            )}
        </div>
    );
};

export default ProfileDetails;
