import React from 'react';

const TextWidget = ({ media }) => {
    const getTextStyle = (resourceText) => {
        return {
            color: resourceText.font_colour,
            backgroundColor: resourceText.background_color,
            textAlign: resourceText.text_layout,
            fontSize: `${resourceText.font_size}px !important`,
            fontWeight: resourceText.font_style.includes('B') ? 'bold !important' : 'normal',
            fontStyle: resourceText.font_style.includes('I') ? 'italic' : 'normal',
            textShadow: resourceText.font_style.includes('A') ? '1px 1px 2px black' : 'none',
        };
    };

    const renderTextElement = (m) => {
        const resourceText = m.resource_list[0]?.resource_text;

        if (!resourceText) return null;

        const textContent = resourceText.text_content;

        if (resourceText.enable_scrolling === 1) {
            return (
                <marquee
                    key={m.element_id}
                    className="w-full h-full"
                    behavior="scroll"
                    direction={resourceText.scrolling_direction}
                    scrollamount={resourceText.scrolling_speed}
                    style={getTextStyle(resourceText)}
                >
                    {textContent}
                </marquee>
            );
        }

        return (
            <p key={m.element_id} className="w-full h-full" style={getTextStyle(resourceText)}>
                {textContent}
            </p>
        );
    };

    return <div className="w-full h-full">{media?.map((m) => renderTextElement(m))}</div>;
};

export default TextWidget;
