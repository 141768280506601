import { Box, Button, Container, FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { FaRegUser } from 'react-icons/fa';
import { GetUserList } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const SwitchUser = () => {
    const { setCurrentUser, setAllUsers, currentColor } = useStateContext();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('currentUser'));

    const [userList, setUserList] = useState([]);

    const [switchUser, setSwitchUser] = useState(user.user_id);

    useEffect(() => {
        handleGetUserList();
        setSwitchUser(user.user_id);
    }, []);

    //console.log('switchUser:', switchUser);
    //console.log('user:', user);
    const handleChange = (event) => {
        const { value } = event.target;
        //console.log('event:', event);
        setSwitchUser(value);
        const name = userList.find((x) => x.user_id === value).name;

        //console.log('file: Sidebar.jsx:88 ===> handleChange ===> name:', name);

        localStorage.setItem('switchUserName', JSON.stringify(name));
    };

    /***
     *
     * Handle get user list
     *
     */
    const handleGetUserList = async () => {
        const { code, data, msg } = await GetUserList(switchUser, 0, 2);
        if (code === 0) {
            localStorage.setItem('allUsers', JSON.stringify(data.list));
            setAllUsers(data.list);
            setUserList(data.list);
        }
        // else {
        //     alert(msg);
        // }
    };

    const handleNavigate = () => {
        //console.log('handleNavigate:', handleNavigate);
        user.user_id !== switchUser && localStorage.setItem('switchUser', JSON.stringify(switchUser));

        //console.log('file: SwitchUser.jsx:81 ===> handleNavigate ===> switchUser.user_id:', switchUser);

        //console.log('file: SwitchUser.jsx:81 ===> handleNavigate ===> user.user_id:', user.user_id);

        localStorage.setItem('currentUser', JSON.stringify(user));
        //console.log('currentUser:', user);
        setCurrentUser(user);
        navigate('/dashboard/home');
    };

    return (
        <div className="h-screen">
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    minHeight: '100%',
                }}
            >
                <Container maxWidth="sm">
                    <div className="p-16 rounded-3xl shadow-lg">
                        <div className="w-full flex justify-center items-center mb-6">
                            <FaRegUser size={32} color={currentColor} />
                            <Typography
                                className="pl-3 text-gray-500 text-14 text-center"
                                sx={{ fontWeight: 600 }}
                            >
                                Select Account
                            </Typography>
                        </div>
                        <div className="w-full flex justify-center items-center mb-6">
                            <Typography variant="subtitle1">
                                You have logged in as a Super User. You have privileges to administer &
                                maintain the following Sign Inspire accounts.
                            </Typography>
                        </div>
                        <div className="w-full flex justify-center items-center mb-6">
                            <FormControl fullWidth>
                                <Select
                                    value={switchUser || ''}
                                    onChange={handleChange}
                                    // displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {userList.map((user, index) => (
                                        <MenuItem key={user.username} value={user.user_id}>
                                            {user.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="w-full flex justify-center items-center mb-6">
                            <Button variant="contained" fullWidth onClick={() => handleNavigate()}>
                                Go
                            </Button>
                        </div>
                    </div>
                </Container>
            </Box>
        </div>
    );
};

export default SwitchUser;
