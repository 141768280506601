import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { Header, Navbar } from '../components/dashboard';
import React, { useEffect } from 'react';

import { DenseInput } from '../utils';
import { UpdateUser } from '../../../api/api';
// import { links } from '../data/dummy';
import { useFormik } from 'formik';
import { useNotification } from '../../../contexts/ContextProvider';

// const Items = styled(ListItem)(({ theme }) => ({}));

const style = {
    labelText: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
    label: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
        color: 'black',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    formControlStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
};
const UpdateUserSec = ({
    menuList,
    selectedItems,
    handleCheckboxChange,
    handleCreateUser,
    currentUser,
    switchUser,
    userDetail,
    setSelectedItems,
    // handleUpdateUser,
}) => {
    const notify = useNotification();

    // const [updateUser, setUpdateUser] = useState(false);
    //console.log('userDetail:', userDetail);
    /**
     *
     * Update an account
     *
     */
    const formik = useFormik({
        initialValues: {
            name: userDetail.name ? userDetail.name : '',
            username: userDetail.username ? userDetail.username : '',
            password: userDetail.password ? userDetail.password : '',
            confirm_password: userDetail.confirm_password ? userDetail.confirm_password : '',
            email: userDetail.email ? userDetail.email : '',
            customer_type: userDetail.customer_type ? userDetail.customer_type.toString() : '0',
            block_account: userDetail.status ? (userDetail.status === 1 ? ['on'] : []) : '',
        },
        enableReinitialize: true,

        onSubmit: (values) => {
            const { name, username, password, confirm_password, email, block_account } = values;
            //console.log(name, username, password, confirm_password, email, block_account.length > 0 ? 1 : 0);
            handleUpdateUser(
                userDetail.user_id,
                name,
                username,
                password,
                confirm_password,
                email,
                block_account.length > 0 ? 1 : 0,
                selectedItems,
            );
        },
    });

    useEffect(() => {
        setSelectedItems(userDetail?.permission_id_list);
    }, [userDetail]);
    //console.log('formik.values', formik.values);
    //console.log('formik.values', formik.values.customer_type);

    /**
     *
     *
     * Handle update User
     *
     */
    const handleUpdateUser = async (
        user_id,
        name,
        username,
        password,
        confirm_password,
        email,
        status,
        permission_id_list,
    ) => {
        const { code, msg, data } = await UpdateUser(
            switchUser,
            user_id,
            name,
            username,
            password,
            confirm_password,
            email,
            status,
            permission_id_list,
        );
        //console.log('data:', data);
        //console.log('code:', code);
        if (code === 0) {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'User was updated successfully!!',
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'User was added unsuccessfully!',
                },
            });
        }
    };

    return (
        <div className="col-span-1 md:col-span-2 h-full p-4 shadow-lg rounded-3xl">
            <div className="pl-3 font-semibold text-lg">Account Details</div>
            <form onSubmit={formik.handleSubmit}>
                <FormControl sx={style.formControlStyle}>
                    <FormLabel required id="name" sx={style.labelText}>
                        Name
                    </FormLabel>
                    <DenseInput
                        fullWidth
                        margin="normal"
                        aria-labelledby="name"
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl sx={style.formControlStyle}>
                    <FormLabel required id="username" sx={style.labelText}>
                        Username
                    </FormLabel>
                    <DenseInput
                        fullWidth
                        margin="normal"
                        aria-labelledby="username"
                        name="username"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl sx={style.formControlStyle}>
                    <FormLabel required id="password" sx={style.labelText}>
                        Password
                    </FormLabel>
                    <DenseInput
                        fullWidth
                        margin="normal"
                        aria-labelledby="password"
                        name="password"
                        type="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl sx={style.formControlStyle}>
                    <FormLabel required id="confirm_password" sx={style.labelText}>
                        Confirm password
                    </FormLabel>
                    <DenseInput
                        fullWidth
                        margin="normal"
                        aria-labelledby="confirm_password"
                        name="confirm_password"
                        type="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.confirm_password}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl sx={style.formControlStyle}>
                    <FormLabel required id="email" sx={style.labelText}>
                        Email
                    </FormLabel>
                    <DenseInput
                        fullWidth
                        margin="normal"
                        aria-labelledby="email"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        variant="outlined"
                    />
                </FormControl>
                {formik.values.customer_type && (
                    <FormControl sx={style.formControlStyle}>
                        <FormLabel required id="customer_type" sx={style.labelText}>
                            Customer type
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="customer_type-radio-buttons-group"
                            name="customer_type"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.customer_type}
                            // defaultValue={formik.values.customer_type}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Creator" />
                            <FormControlLabel value="2" control={<Radio />} label="Inspirer" />
                        </RadioGroup>
                    </FormControl>
                )}
                <FormControl sx={style.formControlStyle}>
                    <FormLabel id="block_account" sx={style.label}>
                        Block account
                    </FormLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="block_account"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                checked={formik.values.block_account.length > 0 ? true : false}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                </FormControl>
                <div>
                    <div className="pl-3 font-semibold text-lg mb-2">Access Group</div>
                    <ul className="grid grid-cols-2 gap-2">
                        {menuList.map((item) => (
                            <li key={item.permission_id} className="p-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedItems.includes(item.permission_id)}
                                            onChange={handleCheckboxChange}
                                            value={item.permission_id}
                                        />
                                    }
                                    label={item.permission_name}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="grid grid-cols-2 gap-4 w-full justify-center">
                    <Button variant="contained" type="submit">
                        update
                    </Button>
                    <Button variant="contained" onClick={() => handleCreateUser()}>
                        create user
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default UpdateUserSec;
