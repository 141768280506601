import React ,{useState } from 'react';
import RedditTextField from './RedditTextField';
import Button from '@mui/material/Button';

function ContactForm1() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
        hearFrom: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            //implentation 

        } catch (error) {
            console.error( error);
        }
    };

    return (
        <form className="grid gap-6 w-full mx-auto">
            {/* First Row */}
            <div className="grid grid-cols-2 gap-6">
                <RedditTextField
                    label="First Name"
                    variant="filled"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}

                />
                <RedditTextField
                    label="Last Name"
                    variant="filled"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}

                />
            </div>
            {/* Second Row */}
            <RedditTextField
                label="Email"
                type="email"
                variant="filled"
                name="email"
                value={formData.email}
                onChange={handleInputChange}

            />
            {/* Third Row */}
            <RedditTextField
                label="Subject"
                variant="filled"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}

            />
            {/* Fourth Row */}
            <RedditTextField
                label="Message"
                multiline
                variant="filled"
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
            />

            <RedditTextField
                label="Where did you here about us?"
                variant="filled"
                className='mt-4'
                name="hearFrom"
                value={formData.hearFrom}
                onChange={handleInputChange}
            />
            <Button className='w-60 text-white mt-4 mx-auto' variant='contained' sx={{ textTransform: 'none' }} type='submit'>
                Submit
            </Button>
        </form>
    );
}

export default ContactForm1;