import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Paper,
    Typography,
    Switch, FormControlLabel
} from '@mui/material';

import ForwardIcon from '@mui/icons-material/Forward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import config from '../config/config';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;

const contact_url = '/contact';
const login_url = '/login';
const signup_url = '/signup';
const ad_attention_test_url = '/ad-attention-test';

// Create a styled component for highlighting
const Highlight = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main, // Customize the color as needed
    fontWeight: 'bold', // Make the highlighted text bold
}));

const Home = () => {
    const itemData = [
        'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A4.png',
        'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A5.png',
        'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A6.png',
        'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A7.png',
        'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A8.png',
        // Add more image URLs as needed
    ];

    const features = [
        {
            title: '1. Assign your Screen by Activation code',
            description:
                'AI-powered digital signage streamlines operations and automates processes, saving you time and resources.',
            icon: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png', // Replace with your actual icon URL
        },
        {
            title: '2. Manage Your Content & Assets',
            description:
                'Leverage AI to deliver targeted content and tailor experiences to each individual viewer, increasing engagement and satisfaction.',
            icon: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png', // Replace with your actual icon URL
        },
        {
            title: '3. Create Your Playlist with Custom Template',
            description:
                'Unlock valuable insights from data collected by AI-powered digital signage, enabling data-driven decision-making and optimization.',
            icon: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png', // Replace with your actual icon URL
        },
        {
            title: '4. Add schedule and Push to Your Screen',
            description:
                'Our AI-powered digital signage seamlessly integrates with your existing systems and workflows, ensuring a smooth transition and maximum compatibility.',
            icon: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png', // Replace with your actual icon URL
        },
    ];

    const images = [
        {
            original: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h1.png',
            heatmap: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h1_heatmap.png'
        },
        {
            original: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h2.png',
            heatmap: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h2_heatmap.png'
        },
        {
            original: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h3.png',
            heatmap: 'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h3_heatmap.png'
        }
    ];

    // State to manage current image index and heatmap toggle
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showHeatmap, setShowHeatmap] = useState(true);

    // Toggle heatmap mode
    const toggleHeatmap = (event) => {
        setShowHeatmap(event.target.checked);
    };

    // Handle next and previous buttons
    const handleNextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div>
            <Container style={{ padding: 0 }}>
                <Paper
                    elevation={3}
                    style={{
                        height: '60vh',
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        position="relative"
                    >
                        {/* Text Section */}
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            height="100%"
                            width="50%"
                            padding="20px"
                            zIndex="10"
                            style={{
                                backgroundImage:
                                    'url(https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A0.png)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        ></Box>

                        {/* Background Image Section */}
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            width="50%"
                            height="100%"
                            padding="20px"
                            zIndex="10"
                            style={{
                                backgroundImage:
                                    'url(https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A1.png)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                color: 'white',
                            }}
                        ></Box>

                        {/* Paper Section */}
                        <Paper
                            elevation={3}
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '15px',
                                position: 'absolute',
                                padding: '20px',
                                zIndex: 1000,
                            }}
                        >
                            <Typography
                                variant="h3"
                                component="h1"
                                gutterBottom
                                style={{
                                    color: 'white', fontWeight: 'bold',
                                }}
                                sx={{
                                    fontSize: {
                                        xs: 'h5',
                                        sm: 'h4',
                                        md: 'h3',
                                    }
                                }}
                            >
                                Inspiring
                            </Typography>
                            <Typography variant="h4" component="h1" gutterBottom style={{
                                color: 'white',
                            }}
                                sx={{
                                    fontSize: {
                                        xs: 'h6',
                                        sm: 'h5',
                                        md: 'h4',
                                    }
                                }}>
                                Digital
                            </Typography>
                            <Typography variant="h4" component="h1" gutterBottom style={{
                                color: 'white',
                            }} sx={{
                                fontSize: {
                                    xs: 'h6',
                                    sm: 'h5',
                                    md: 'h4',
                                }
                            }}>
                                Signage
                            </Typography>
                            <Typography variant="h4" component="h1" gutterBottom style={{
                                color: 'white',
                            }} sx={{
                                fontSize: {
                                    xs: 'h6',
                                    sm: 'h5',
                                    md: 'h4',
                                }
                            }}>
                                Solution
                            </Typography>
                        </Paper>
                        {/* </Box> */}

                        {/* Video Component */}
                        <Paper
                            elevation={3}
                            style={{
                                position: 'absolute',
                                bottom: '-50px', // Adjust the vertical position
                                right: '40px', // Adjust the horizontal position
                                height: '180px', // Adjust the height
                                width: '320px', // Adjust the width
                                overflow: 'hidden', // Ensure the video does not overflow its container
                                zIndex: '10',
                            }}
                        >
                            <video
                                width="100%"
                                height="100%"
                                autoPlay
                                muted
                                loop
                                style={{ objectFit: 'cover' }}
                            >
                                <source
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/home.mov"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </Paper>
                    </Box>
                </Paper>
            </Container>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" padding={4} sx={{ mt: { xs: 8, sm: 0 } }} // Responsive margin-top and margin-bottom
            >
                <Container>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Transforming Digital Signage with <Highlight>AI Inspiration</Highlight>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                            <Highlight>
                                <span style={{ fontSize: '18px', textTransform: 'capitalize' }}>
                                    • 2-minute setup & easy screen deployment <br />
                                    • ai-powered features for instant inspiration <br />
                                    • pay-as-you-go & flexible monthly subscription <br />
                                    • free pre-designed templates <br />
                                    • compatible with all android versions from 5.0 onwards
                                </span>
                            </Highlight>
                        </Typography>



                            <Box>
                                <Button
                                    variant="contained"
                                    href={login_url}
                                    color="primary"
                                    style={{
                                        backgroundColor: '#0444AB',
                                        color: '#ffffff',
                                        marginRight: '10px',
                                    }}
                                >
                                    Free Sign Up
                                </Button>
                                <Button
                                    href={contact_url}
                                    style={{ borderColor: '#0444AB', color: '#0444AB' }}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Request Demo
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" padding={4} sx={{ mt: { xs: 8, sm: 0 } }}>
            <Container>
                <Grid container spacing={5} justifyContent="center" alignItems="center">
                    
                    
                    <Grid item xs={12} md={5}>
                        <Typography variant="h4" component="h1" gutterBottom>
                        Discover How to Make Your Posts <Highlight> Attract </Highlight> Audiences
                        </Typography>
                        <Typography variant="body1" paragraph>
                        In today's world of advertising, whether you're engaging in mass marketing or using highly targeted audience and interest-based profiling, a "spray and pray" approach can still creep in if your message isn’t perfectly aligned with the right users, devices, ad formats, and timing.
                        </Typography>
                        <Typography variant="body1" paragraph>
Yes, that’s a lot to get right. But when you're making significant investments in ad spend and campaigns, it’s essential to ensure your messages are reaching the right platforms and are strategically positioned for success.
                            </Typography>
                            <Button
                                    href={ad_attention_test_url}
                                    style={{ borderColor: '#0444AB', color: '#0444AB' }}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Free Test
                                </Button>
                        
                        
                    </Grid>

                    
                    <Grid item xs={12} md={7} position="relative">
                        
                        <Box display="flex" justifyContent="right" mb={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showHeatmap}
                                        onChange={toggleHeatmap}
                                        color="primary"
                                    />
                                }
                                label={showHeatmap ? 'Heatmap' : 'Original'}
                                sx={{
                                    //backgroundColor: '#fff',
                                    padding: '4px',
                                    borderRadius: '8px',
                                }}
                            />
                        </Box>

                        
                        <Box position="relative">
                           
                            <IconButton
                                onClick={handlePrevImage}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '-30px', // Adjust for symmetry
                                    transform: 'translateY(-50%)',
                                    zIndex: 10,
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional for better visibility
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                    }
                                }}
                            >
                                <ArrowBackIosIcon />
                            </IconButton>

                            <Box
                                component="img"
                                src={showHeatmap ? images[currentIndex].heatmap : images[currentIndex].original}
                                alt="Comparison"
                                sx={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    mt: 2,
                                }}
                            />

                            
                            <IconButton
                                onClick={handleNextImage}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '-30px', // Adjust for symmetry
                                    transform: 'translateY(-50%)',
                                    zIndex: 10,
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional for better visibility
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                    }
                                }}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            </Box> 

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh" padding={4}>
                <Container>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Typography variant="h4" component="h2" gutterBottom>
                                    Andorid Master Digital Signage Platform
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    A leader in Android platform digital signage solutions. Our mission is to
                                    revolutionize the way businesses communicate and engage with their
                                    audiences through cutting-edge technology and innovative display
                                    solutions.
                                </Typography>
                                <Box display="flex" justifyContent="center">
                                    <IconButton
                                        target="_blank"
                                        rel="noopener"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <img
                                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Google_play_icon.png"
                                            alt="Google Play"
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        target="_blank"
                                        rel="noopener"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <img
                                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/android.png"
                                            alt="Android"
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    </IconButton>
                                    <IconButton target="_blank" rel="noopener">
                                        <img
                                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png"
                                            alt="App Icon"
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src={
                                    'https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A3.png'
                                }
                                alt="AI"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    minHeight: '50vh',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" padding={4}>
                <Container>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Igniting Creativity in Signage
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" paragraph>
                                Unlock the power of AI in digital signage solutions for enhanced engagement
                                and dynamic content.
                            </Typography>
                            <Box>
                                <Button
                                    variant="contained"
                                    href={login_url}
                                    color="primary"
                                    style={{
                                        backgroundColor: '#0444AB',
                                        color: '#ffffff',
                                        marginRight: '10px',
                                    }}
                                >
                                    Sign Up Today
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: '16px' }}>
                        {' '}
                        {/* Adjust spacing as needed */}
                        {/* Content with space after Grid */}
                    </Grid>
                    <ImageList cols={3} gap={0}>
                        {/* First Row with 2 Images */}
                        <ImageListItem cols={2} style={{ padding: 0 }}>
                            <img
                                src={itemData[0]}
                                alt="Image 1"
                                style={{ width: '100%', height: 'auto', display: 'block' }}
                            />
                        </ImageListItem>
                        <ImageListItem style={{ padding: 0 }}>
                            <img
                                src={itemData[1]}
                                alt="Image 2"
                                style={{ width: '100%', height: 'auto', display: 'block' }}
                            />
                        </ImageListItem>
                        {/* Second Row with 3 Images */}
                        {itemData.slice(2).map((imgUrl, index) => (
                            <ImageListItem key={index} style={{ padding: 0 }}>
                                <img
                                    src={imgUrl}
                                    alt={`Image ${index + 3}`}
                                    style={{ width: '100%', height: 'auto', display: 'block' }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh" padding={4}>
                <Container>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="overline" display="block" gutterBottom>
                                <Highlight> Revolutionary</Highlight>
                            </Typography>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Unlock the Power of AI in Digital Signage
                            </Typography>

                            <Box component="ul" pl={2} mb={2}>
                                <li>
                                    <Typography variant="body2">Intelligent Content Creation</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">Inspire Your Audience</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">Design with Ease</Typography>
                                </li>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    href={signup_url}
                                    color="primary"
                                    style={{
                                        backgroundColor: '#0444AB',
                                        color: '#ffffff',
                                        marginRight: '10px',
                                    }}
                                >
                                    Get Your First Month Free
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A9.jpg"
                                alt="AI in Digital Signage"
                                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh" padding={4}>
                <Container>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="overline" display="block" gutterBottom>
                                <Highlight> Transform</Highlight>
                            </Typography>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Revolutionize the Potential of Digital Signage in Only 4 Steps in 1 Minute.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Discover how AI can revolutionize your industry with its advanced capabilities
                                and intelligent insights.
                            </Typography>
                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    href={login_url}
                                    color="primary"
                                    style={{
                                        backgroundColor: '#0444AB',
                                        color: '#ffffff',
                                        marginRight: '10px',
                                    }}
                                >
                                    Try Now
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={8}>
                                { }
                                {features.map((feature, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Paper
                                            elevation={1}
                                            style={{ padding: '16px', display: 'flex', alignItems: 'center' }}
                                        >
                                            <Box mr={2}>
                                                <ForwardIcon
                                                    sx={{ width: 40, height: 40, color: 'primary.main' }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6">{feature.title}</Typography>
                                                <Typography variant="body2">{feature.description}</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default Home;